import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO
} from "../../utils/const-estilos";
import { deshabilitarUsuarioMovil, habilitarUsuarioMovil, obtenerListaUsuariosMovil, } from "../../api/usuario/usuario";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_USUARIOS,
  INT_ID_SEGUNDO_NIVEL_USUARIOS,
  INT_ID_TERCER_NIVEL_USUARIO_MOVIL,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { Settings } from "feather-icons-react/build/IconComponents";
import { actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen, guardarConfiguracionScreen, obtenerConfiguracionScreen } from "../../api/configuracion-screen/configuracion-screen";
import { LISTAR_USUARIOS_MOVIL } from "../../utils/const-screen-name";
import { compareHeaders } from "../../share/shared-functions";
import { SELECT_HABILITADO_DESHABILITADO } from "../../utils/const-select-habilitado-deshabilitado";
import Select from "react-select";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
let accesoBotonNuevo = 'none';

export default function ListarUsuarioMovil() {
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "ACCIONES", visible: true, },
    { key: "id", title: "CODIGO", visible: true, },
    { key: "idtipousuario", title: "ID TIPO USUARIO", visible: false, },
    { key: "nombre", title: "NOMBRE", filter: true, visible: true, },
    { key: "apellidopaterno", title: "APELLIDO PATERNO", filter: true, visible: true, },
    { key: "apellidomaterno", title: "APELLIDO MATERNO", filter: true, visible: true, },
    { key: "numerolicenciaconducir", title: "NUM LIC CONDUCIR", filter: true, visible: true, },
    { key: "usuario", title: "USUARIO", filter: true, visible: true, },
    { key: "contrasena", title: "CONTRASENA", filter: true, visible: true, },
    { key: "nombreunidad", title: "UNIDAD", filter: true, visible: true, },
    { key: "unidad", title: "UNIDAD", filter: true, visible: false },
    { key: "telefono", title: "TELEFONO", filter: true, visible: true },
    { key: "nota", title: "NOTA", filter: true, visible: true },
    { key: "idGeocerca", title: "ID GEOCERCA", filter: true, visible: false },
    { key: "codigoGeocerca", title: "CODIGO GEOCERCA", filter: true, visible: true },
    { key: "nombreGeocerca", title: "NOMBRE GEOCERCA", filter: true, visible: true },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true, visible: true, },
  ];
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [modalConfirmarHabilitarVisible, setModalConfirmarHabilitarVisible] =
    useState(false);
  const [usuarioMovilSelected, setUsuarioMovilSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [estadoHabilitadoSelected, setEstadoHabilitadoSelected] = useState(null);

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);

  useEffect(() => {
    // console.log("entro a ListarUsuarioMovil");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_USUARIOS;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_USUARIOS;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_USUARIO_MOVIL;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_USUARIOS_MOVIL });
      console.log('configuracionScreenInfo');
      console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      objConfiguracionEnviar.habilitado = estadoHabilitadoSelected !== null ? estadoHabilitadoSelected.value : null;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        console.log('configuracionOrden');
        console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          console.log('configuracionHeaders');
          console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);

      let lista = [];

      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        setTieneAccesoAlModulo(true);
        lista = await obtenerListaUsuariosMovil(objConfiguracionEnviar);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
          lista = await obtenerListaUsuariosMovil(objConfiguracionEnviar);
        }
      }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaUsuarios(lista);
      }
      if (lista.length > 0) {
        const objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => editarUsuario(param),
        };
        const objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        const objHabilitar = {
          label: "Habilitar",
          color: "blue",
          onClick: (param) => cambiarEstadoHabilitar(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          console.log('element');
          console.log(element);
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            // acciones.push(objDeshabilitar);
            if (element.habilitado === 1) {
              acciones.push(objDeshabilitar);
            }
            if (element.habilitado === 0) {
              acciones.push(objHabilitar);
            }
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              // acciones.push(objDeshabilitar);
              if (element.habilitado === 1) {
                acciones.push(objDeshabilitar);
              }
              if (element.habilitado === 0) {
                acciones.push(objHabilitar);
              }
            }
          }
          lista[i].acciones = acciones;
          lista[i].nombreunidad = lista[i].nombreUnidad;
        }
        setListaUsuarios(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const editarUsuario = (data) => {
    try {
      console.log("editarUsuario");
      console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR USUARIO MOVIL";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      nextPage("usuario-movil", obj);
    } catch (err) {
      console.log("editarUsuario err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setUsuarioMovilSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaUsuarios.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    let obj = {};
    let data = {};
    data.id = 0;
    data.nombre = "";
    data.apellidopaterno = "";
    data.apellidomaterno = "";
    data.numerolicenciaconducir = "";
    data.usuario = "";
    data.contrasena = "";
    data.unidad = null;
    data.idtipousuario = null;
    data.nota = "";
    data.telefono = "";
    data.idGeocerca = null;
    data.nombreGeocerca = "";
    data.codigoGeocerca = "";
    obj.titulo = "CREAR USUARIO MOVIL";
    obj.params = data;
    nextPage("usuario-movil", obj);
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (usuarioMovilSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = usuarioMovilSelected.id;
    if (usuarioMovilSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarUsuarioMovil(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
    /*const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_USUARIOS_MOVIL });
    // console.log('configuracionScreenInfo');
    // console.log(configuracionScreenInfo);
    let objConfiguracionEnviar = {};
    objConfiguracionEnviar.descendente = true;
    objConfiguracionEnviar.ascendente = false;
    if (configuracionScreenInfo !== null) {
      const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      objConfiguracionEnviar.descendente = configuracionOrden.descendente;
      objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
      setAscendente(configuracionOrden.ascendente);
      setDescendente(configuracionOrden.descendente);
      let configuracionHeaders = null;
      // debugger;
      if (configuracionScreenInfo.headers !== null) {
        configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
        console.log('configuracionHeaders');
        console.log(configuracionHeaders);
        const headerAux = compareHeaders(headers, configuracionHeaders);
        setGridHeaders(headerAux);
      }
      setConfiguracionOrden(configuracionScreenInfo);
    }
    let lista = await obtenerListaUsuariosMovil(objConfiguracionEnviar);
    // console.log("listarCorreos");
    // console.log(listarCorreos);
    agregarAccionesALista(lista);
    setCargandoPage(false);*/
  }

  const cambiarEstadoHabilitar = async (data) => {
    try {
      setUsuarioMovilSelected(data);
      setModalConfirmarHabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstadoHabilitar err");
      console.log(err);
    }
  };

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_USUARIOS_MOVIL;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_USUARIOS_MOVIL;
      objConfiguracionEnviar.orden = objOrden;
      console.log('configuracionOrden');
      console.log(configuracionOrden);
      if (configuracionOrden === null) {
        objConfiguracionEnviar.headers = gridHeaders;
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        objConfiguracionEnviar.headers = gridHeaders;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const handleCancelModalHabilitar = () => {
    setModalConfirmarHabilitarVisible(false);
  };

  const handleConfirmModalHabilitar = async () => {
    try {
      // console.log("Modal confirmado!");
      // console.log('tipoCarroceriaSelected');
      // console.log(tipoCarroceriaSelected);
      setModalConfirmarHabilitarVisible(false);
      let obj = {};
      obj.id = usuarioMovilSelected.id;
      const habilitado = await habilitarUsuarioMovil(obj);
      // console.log('habilitado');
      // console.log(habilitado);
      if (habilitado === true) {
        alert("Se habilito correctamente");
        reset();
      }
      if (habilitado === false) {
        alert("No se pudo habilitar sub tipo carroceria");
      }
    } catch (err) {
      console.log("handleConfirmModalHabilitar err");
      console.log(err);
    }
  };

  const handleEstadoChecklist = (event) => {
    setEstadoHabilitadoSelected(event);
  }

  const handleBuscarClick = () => {
    reset();
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA USUARIO MOVIL
            </label>
            <div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div className="flex align-center" >
              <Select
                options={SELECT_HABILITADO_DESHABILITADO}
                isSearchable={true}
                isClearable={true}
                value={estadoHabilitadoSelected}
                onChange={(event) => handleEstadoChecklist(event)}
                placeholder="Seleccione Estado"
              />
              <div
                style={{ width: 10 }}
              ></div>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscarClick}
                style={{
                  display: accesoBotonNuevo,
                }}
              >
                BUSCAR
              </button>
            </div>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <Grid rows={listaUsuarios} headers={gridHeaders}
            exportar={exportar} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar usuario?"
          />
          <ModalConfirmar
            isOpen={modalConfirmarHabilitarVisible}
            onCancel={handleCancelModalHabilitar}
            onConfirm={handleConfirmModalHabilitar}
            message="¿Esta seguro de habilitar este usuario?"
          />
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
          />
        </div>
      );
    } catch (err) {
      console.log('index render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
