import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { obtenerGrupoGeocerca } from "../../api/gestion-dts/gestion";
import "../../style/style-cargando.css";
import {
  ESTILO_BOTON_GENERAL_ACEPTAR,
  ESTILO_BOTON_LISTAR_ATRAS
} from "../../utils/const-estilos";
import {
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD,
  INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB
} from "../../utils/const-int";
import {
  guardarGeocercasDeUnaCiudad,
  modificarGeocercasDeUnaCiudad,
  obtenerGeocercasDeUnaCiudad,
  obtenerGrupoGeocercasCiudad,
  obtenerListaEstadosCarroceria,
} from "../../api/disponibilidad";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));

export default function SeleccionarGeocercaDeUnaCiudad() {
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaGrupoSubgrupo, setListaGrupoSubgrupo] = useState([]);
  const [infoGrupoGeocercaCiudadEnBase, setinfoGrupoGeocercaCiudadEnBase] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [listaGeocercasParaCombo, setListaGeocercasParaCombo] = useState([]);
  const [geocercaComboSelected, setGeocercaComboSelected] = useState(null);
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [infoGeocercasDeCiudadSeleccionada, setInfoGeocercasDeCiudadSeleccionada] = useState(null);
  const [listaEstadoCarroceria, setListaEstadoCarroceria] = useState([]);
  const [estadoCarroceriaSelected, setEstadoCarroceriaSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);


  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CONFIGURACION_DISPONIBILIDAD_SELECCIONAR_GEOCERCA_DE_UNA_CIUDAD;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        procesarSiTienePermiso();
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          procesarSiTienePermiso();
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const procesarSiTienePermiso = async () => {
    try {
      let listaInfoGrupoSubgrupo = [];
      let infoGrupoGeocercaCiudad = null;
      infoGrupoGeocercaCiudad = await obtenerGrupoGeocercasCiudad();
      // console.log('infoGrupoGeocercaCiudad');
      // console.log(infoGrupoGeocercaCiudad);
      setinfoGrupoGeocercaCiudadEnBase(infoGrupoGeocercaCiudad);
      listaInfoGrupoSubgrupo = await obtenerGrupoGeocerca();
      // console.log('listaInfoGrupoSubgrupo');
      // console.log(listaInfoGrupoSubgrupo);
      let grupoGeocercasAUsar = JSON.parse(infoGrupoGeocercaCiudad.meta);
      let geocercaParaLaLista = listaInfoGrupoSubgrupo.filter((element) => !grupoGeocercasAUsar.includes(element.idGrupoGeoCerca));
      // console.log('geocercaParaLaLista');
      // console.log(geocercaParaLaLista);
      setListaGeocercas(geocercaParaLaLista);
      let geocercaParaElCombo = listaInfoGrupoSubgrupo.filter((element) => grupoGeocercasAUsar.includes(element.idGrupoGeoCerca));
      // console.log('geocercaParaElCombo');
      // console.log(geocercaParaElCombo);
      for (let i = 0; i < geocercaParaElCombo.length; i++) {
        const element = geocercaParaElCombo[i];
        geocercaParaElCombo[i].label = element.nombreGrupoGeocerca + " - " + element.nombreGeocerca;
        geocercaParaElCombo[i].value = element.idGeoCerca;
      }
      setListaGeocercasParaCombo(geocercaParaElCombo);
      const listaCarroceria = await obtenerListaEstadosCarroceria(true);
      setListaEstadoCarroceria(listaCarroceria);
      setTieneAccesoAlModulo(true);
    } catch (err) {
      console.log("procesarSiTienePermiso err");
      console.log(err);
    }
  }

  const procesarLista = (lista, info) => {
    try {
      let listaGeocercasDeCiudad = [];
      if (info !== null) {
        listaGeocercasDeCiudad = JSON.parse(info.meta);
      }
      let listaGrupo = [];
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        const existe = listaGrupo.find(e => e.idGrupoGeoCerca === element.idGrupoGeoCerca);
        const indexEnBase = listaGeocercasDeCiudad.find(e => e === element.idGeoCerca);
        if (existe === undefined) {
          let objGrupo = {};
          objGrupo.idGrupoGeoCerca = element.idGrupoGeoCerca;
          objGrupo.nombre = element.nombreGrupoGeocerca;
          objGrupo.geocercas = [];

          let objGeocerca = {};
          objGeocerca.nombre = element.nombreGeocerca;
          objGeocerca.codigo = element.codigoGeocerca;
          objGeocerca.id = element.idGeoCerca;
          objGeocerca.checked = indexEnBase === undefined ? false : true;
          objGrupo.geocercas.push(objGeocerca);
          listaGrupo.push(objGrupo);
        } else {
          const index = listaGrupo.findIndex(e => e.idGrupoGeoCerca === element.idGrupoGeoCerca);
          let objGeocerca = {};
          objGeocerca.nombre = element.nombreGeocerca;
          objGeocerca.codigo = element.codigoGeocerca;
          objGeocerca.id = element.idGeoCerca;
          objGeocerca.checked = indexEnBase === undefined ? false : true;
          listaGrupo[index].geocercas.push(objGeocerca);
        }
      }
      // console.log('listaGrupoGeocerca');
      // console.log(listaGrupo);
      return listaGrupo;
    } catch (err) {
      console.log('procesarLista err');
      console.log(err);
      return [];
    }
  }

  const nextPage = (page) => {
    try {

      history.push(`${page}`);

    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const renderGeocercas = (listaGeo, indexGrupo) => {
    // console.log('listaGeo');
    // console.log(listaGeo);
    return listaGeo.map((geocerca, indexGeocerca) => (
      <div key={geocerca.id}>
        <label className="font-normal ml-60">
          <input
            type="checkbox"
            checked={geocerca.checked}
            onChange={() => handleGrupoCheckboxChange(indexGrupo, indexGeocerca)}
          />
          {"  " + geocerca.codigo !== undefined && geocerca.codigo !== null ? geocerca.codigo + " - " : ""} {"  " + geocerca.nombre}
        </label>
      </div>
    ));
  };

  const handleGrupoCheckboxChange = (indexGrupo, indexGeocerca) => {
    try {
      // console.log('indexGrupo');
      // console.log(indexGrupo);
      // console.log('indexGeocerca');
      // console.log(indexGeocerca);
      let pivote = listaGrupoSubgrupo;
      // console.log('pivote');
      // console.log(pivote);
      const nuevoCheck = !pivote[indexGrupo].geocercas[indexGeocerca].checked;
      pivote[indexGrupo].geocercas[indexGeocerca].checked = nuevoCheck;
      setListaGrupoSubgrupo([
        ...pivote
      ]);
    } catch (err) {
      console.log('handleGrupoCheckboxChange err');
      console.log(err);
    }
  }

  const handleGuardarClick = async () => {
    try {
      console.log('handleGuardarClick');
      console.log('listaGrupoSubgrupo');
      console.log(listaGrupoSubgrupo);
      console.log('infoGeocercasDeCiudadSeleccionada');
      console.log(infoGeocercasDeCiudadSeleccionada);

      if (geocercaComboSelected === null) {
        alert('Debe seleccionar geocerca');
        return;
      }
      if (estadoCarroceriaSelected === null) {
        alert('Debe seleccionar estado carroceria');
        return;
      }
      let arr = [];
      for (let i = 0; i < listaGrupoSubgrupo.length; i++) {
        const grupo = listaGrupoSubgrupo[i];
        for (let j = 0; j < grupo.geocercas.length; j++) {
          const geocerca = grupo.geocercas[j];
          if (geocerca.checked === true) {
            arr.push(geocerca.id);
          }
        }
      }
      console.log('arr');
      console.log(arr);
      let objEnviar = {};
      objEnviar.idGeocerca = geocercaComboSelected.value;
      objEnviar.idEstadoCarroceria = estadoCarroceriaSelected.value;
      objEnviar.meta = arr;

      if (infoGeocercasDeCiudadSeleccionada === null) {
        const guardado = await guardarGeocercasDeUnaCiudad(objEnviar);
        // console.log('guardado');
        // console.log(guardado);
        if (guardado === true) {
          alert('Se guardo correctamente');
          // nextPage("disponibilidad");
          handleAtrasClick();
        } else {
          alert('Ocurrio un error, intente nuevamente');
        }
      }
      if (infoGeocercasDeCiudadSeleccionada !== null) {
        objEnviar.id = infoGeocercasDeCiudadSeleccionada.id;
        const modificado = await modificarGeocercasDeUnaCiudad(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se actualizo correctamente');
          // nextPage("disponibilidad");
          handleAtrasClick();
        } else {
          alert('Ocurrio un error, intente nuevamente');
        }
      }
    } catch (err) {
      console.log('handleGuardarClick err');
      console.log(err)
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleGeocercaCombo = async (event) => {
    try {
      console.log('handleGeocercaCombo');
      console.log(event);
      setGeocercaComboSelected(event);
      if (estadoCarroceriaSelected === null) {
        alert('Debe seleccionar un estado carroceria');
        return;
      }
      let info = null;
      if (event !== null) {
        let objEnviar = {};
        objEnviar.idGeocerca = event.idGeoCerca;
        objEnviar.idEstadoCarroceria = estadoCarroceriaSelected.value;
        let geocercasDeCiudadSeleccionada = await obtenerGeocercasDeUnaCiudad(objEnviar);
        console.log('geocercasDeCiudadSeleccionada');
        console.log(geocercasDeCiudadSeleccionada);
        setInfoGeocercasDeCiudadSeleccionada(geocercasDeCiudadSeleccionada);
        if (geocercasDeCiudadSeleccionada !== null) {
          info = geocercasDeCiudadSeleccionada;
        }
      }
      if (event === null) {
        setInfoGeocercasDeCiudadSeleccionada(null);
      }
      const procesado = procesarLista(listaGeocercas, info);
      setListaGrupoSubgrupo(procesado);
    } catch (err) {
      console.log('handleGeocercaCombo err');
      console.log(err);
    }
  }

  const handleEstadoCarroceriaCombo = async (event) => {
    try {
      console.log('handleEstadoCarroceriaCombo');
      console.log(event);
      setEstadoCarroceriaSelected(event);
      // if(event !== null){

      // }
      // if (event === null) {
      setGeocercaComboSelected(null);
      setListaGrupoSubgrupo([]);
      // }
    } catch (err) {
      console.log('handleEstadoCarroceriaCombo err');
      console.log(err);
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div
        >
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  ESTADO CARROCERIA
                </label>
                <Select options={listaEstadoCarroceria} isSearchable='true'
                  isClearable='true'
                  value={estadoCarroceriaSelected}
                  onChange={(event) => handleEstadoCarroceriaCombo(event)}
                  placeholder='SELECCIONE CARROCERIA'
                />
              </div>
              <div style={{ width: 100 }}></div>
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="usuario" className="mb-2 font-bold">
                  GEOCERCA CIUDAD
                </label>
                <Select options={listaGeocercasParaCombo} isSearchable='true'
                  isClearable='true'
                  value={geocercaComboSelected}
                  onChange={(event) => handleGeocercaCombo(event)}
                  placeholder='SELECCIONE GEOCERCA'
                />
              </div>
            </div>
            <button className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-wrap px-5 py-5 flex-col">
            {listaGrupoSubgrupo.map((grupo, indexGrupo) => (
              <div key={grupo.idGrupoGeoCerca}>
                <label className="font-bold ml-10">
                  {grupo.nombre}
                </label>
                {renderGeocercas(grupo.geocercas, indexGrupo)}
                <hr />
              </div>
            ))}
          </div>
          <div className="flex p-5 justify-end">
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleGuardarClick}
            >
              GUARDAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log("permisos render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}