import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import Select from "react-select";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
} from "../../utils/const-estilos";
import {
  Edit,
  Slash,
  Trash2,
  Send,
  PlusCircle,
  Eye,
} from "feather-icons-react/build/IconComponents";
import {
  obtenerDescargas,
  obtenerConfiguracion,
  guardarDescargas,
  guardarDescargasEnRegistroExistente,
  obtenerListadoImportaciones,
  modificarVariasDescargas,
  enviarSMSDescargas,
  enviarSMSDescarga,
  obtenerDescargasPlanilla,
  eliminarDescargaId,
  eliminarDescargaPlanilla,
  obtenerEnvioData,
  obtenerConfiguracionDescargaCarga,
  obtenerDescargasEnlazadas,
} from "../../api/descargas";
import { obtenerGeoruta, asignarGeorutaDescarga } from "../../api/georuta";
import moment from "moment";
import "moment-timezone";
import "../../style/style-cargando.css";

import {
  INT_ID_MODULO_DESCARGAS,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import AsignarGeocercaUsuario from "../configuraciones/asignar-geocerca-a-usuario";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
// let accesoBotonNuevo = 'none';

export default function Descargas() {
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [message, setMessage] = useState(null);
  const [nombreExcel, setNombreExcel] = useState("");
  const [showListadoImport, setShowListadoImport] = useState(true);
  const [fechaImport, setFechaImport] = useState("");
  const [listadoImport, setListadoImport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataModificar, setDataModificar] = useState(null);
  const [dataModificarNew, setDataModificarNew] = useState(null);
  const [excelValue, setExcelValue] = useState(null);
  const [descargasData, setDescargasData] = useState([]);
  const [descargasDataAgrupado, setDescargasDataAgrupado] = useState([]);
  const [verDetallado, setVerDetallado] = useState(false);
  const [isListImport, setIsListImport] = useState(false);
  const [enlazarDescargaCarga, setEnlazarDescargaCarga] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);
  const [dataConfiguracion, setDataConfiguracion] = useState({
    indexCodigoOrigen: null,
    indexDepositoOrigen: null,
    indexCodigoDestino: null,
    indexDepositoDestino: null,
    indexPlanilla: null,
    indexNombreFletero: null,
    indexCodigoUnidad: null,
    indexPlaca: null,
    indexFechaSalida: null,
    indexFechaEntrada: null,
    indexEstado: null,
    indexCodigoProducto: null,
    indexProductoNombre: null,
    indexBultos: null,
    indexTipoProducto: null,
    indexTipoViaje: null,
    indexReferencia: null,
    indexETA: null,
    indexObservacionETA: null,
    indexPlanDescarga: null,
    indexObservacionPlanDescarga: null,

    valorCodigoOrigen: null,
    valorDepositoOrigen: null,
    valorCodigoDestino: null,
    valorDepositoDestino: null,
    valorPlanilla: null,
    valorNombreFletero: null,
    valorCodigoUnidad: null,
    valorPlaca: null,
    valorFechaSalida: null,
    valorFechaEntrada: null,
    valorEstado: null,
    valorCodigoProducto: null,
    valorProductoNombre: null,
    valorBultos: null,
    valorTipoProducto: null,
    valorTipoViaje: null,
    valorReferencia: null,
    valorETA: null,
    valorObservacionETA: null,
    valorPlanDescarga: null,
    valorObservacionPlanDescarga: null,
  });
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [showAsignarItinerario, setShowAsignarItinerario] = useState(false);
  const [optionGeoruta, setOptionGeoruta] = useState([]);
  const [georutaSelected, setGeorutaSelected] = useState(null);
  const [descargaAsignar, setDescargaAsignar] = useState(null);
  const [exportar, setExportar] = useState(false);
  const location = useLocation();

  const formatearHoraBolivia = (val) => {
    return moment.utc(val).tz("America/La_Paz").format("YYYY-MM-DD HH:mm:ss");
  };

  const handleGeorutaSelected = (value) => {
    try {
      // console.log('handleGeocercaSelected');
      // console.log(value);
      setGeorutaSelected(value);
    } catch (err) {
      console.log("handleGeorutaSelected err");
      console.log(err);
    }
  };
  const obtenerListaImportaciones = async () => {
    setLoading(true);
    const resp = await obtenerListadoImportaciones();
    const respConfCargaDescarga = await obtenerConfiguracionDescargaCarga();
    if (respConfCargaDescarga.body.length > 0) {
      localStorage.setItem(
        "enlazarDescargaCarga",
        respConfCargaDescarga.body[0].enlazarCargaDescarga
      );
      setEnlazarDescargaCarga(
        respConfCargaDescarga.body[0].enlazarCargaDescarga
      );
    }
    const format = resp.body
      .map((val) => {
        return {
          ...val,
          fechaFormat: formatearHoraBolivia(val.fechaRegistro),
        };
      })
      .sort((a, b) => {
        const fechaA = new Date(a.fechaFormat);
        const fechaB = new Date(b.fechaFormat);
        return fechaB - fechaA;
      });

    setListadoImport(format);
    agregarAccionesVerALista(format);
    const respConf = await obtenerConfiguracion();

    const formatData = {
      ...respConf.body[0],
      valorCodigoOrigen: JSON.parse(respConf.body[0].valorCodigoOrigen),
      valorDepositoOrigen: JSON.parse(respConf.body[0].valorDepositoOrigen),
      valorCodigoDestino: JSON.parse(respConf.body[0].valorCodigoDestino),
      valorDepositoDestino: JSON.parse(respConf.body[0].valorDepositoDestino),
      valorPlanilla: JSON.parse(respConf.body[0].valorPlanilla),
      valorNombreFletero: JSON.parse(respConf.body[0].valorNombreFletero),
      valorCodigoUnidad: JSON.parse(respConf.body[0].valorCodigoUnidad),
      valorPlaca: JSON.parse(respConf.body[0].valorPlaca),
      valorFechaSalida: JSON.parse(respConf.body[0].valorFechaSalida),
      valorFechaEntrada: JSON.parse(respConf.body[0].valorFechaEntrada),
      valorEstado: JSON.parse(respConf.body[0].valorEstado),
      valorCodigoProducto: JSON.parse(respConf.body[0].valorCodigoProducto),
      valorProductoNombre: JSON.parse(respConf.body[0].valorProductoNombre),
      valorBultos: JSON.parse(respConf.body[0].valorBultos),
      valorTipoProducto: JSON.parse(respConf.body[0].valorTipoProducto),
      valorTipoViaje: JSON.parse(respConf.body[0].valorTipoViaje),
      valorReferencia: JSON.parse(respConf.body[0].valorReferencia),
      valorETA: JSON.parse(respConf.body[0].valorETA),
      valorObservacionETA: JSON.parse(respConf.body[0].valorObservacionETA),
      valorPlanDescarga: JSON.parse(respConf.body[0].valorPlanDescarga),
      valorObservacionPlanDescarga: JSON.parse(
        respConf.body[0].valorObservacionPlanDescarga
      ),
      valorNroViajeCarga: JSON.parse(respConf.body[0].valorNroViajeCarga),

      valorCodigoOrigenJson: respConf.body[0].valorCodigoOrigen,
      valorDepositoOrigenJson: respConf.body[0].valorDepositoOrigen,
      valorCodigoDestinoJson: respConf.body[0].valorCodigoDestino,
      valorDepositoDestinoJson: respConf.body[0].valorDepositoDestino,
      valorPlanillaJson: respConf.body[0].valorPlanilla,
      valorNombreFleteroJson: respConf.body[0].valorNombreFletero,
      valorCodigoUnidadJson: respConf.body[0].valorCodigoUnidad,
      valorPlacaJson: respConf.body[0].valorPlaca,
      valorFechaSalidaJson: respConf.body[0].valorFechaSalida,
      valorFechaEntradaJson: respConf.body[0].valorFechaEntrada,
      valorEstadoJson: respConf.body[0].valorEstado,
      valorCodigoProductoJson: respConf.body[0].valorCodigoProducto,
      valorProductoNombreJson: respConf.body[0].valorProductoNombre,
      valorBultosJson: respConf.body[0].valorBultos,
      valorTipoProductoJson: respConf.body[0].valorTipoProducto,
      valorTipoViajeJson: respConf.body[0].valorTipoViaje,
      valorReferenciaJson: respConf.body[0].valorReferencia,
      valorETAJson: respConf.body[0].valorETA,
      valorObservacionETAJson: respConf.body[0].valorObservacionETA,
      valorPlanDescargaJson: respConf.body[0].valorPlanDescarga,
      valorObservacionPlanDescargaJson:
        respConf.body[0].valorObservacionPlanDescarga,
      valorNroViajeCargaJson: respConf.body[0].valorNroViajeCarga,
    };
    setDataConfiguracion(formatData);
    setLoading(false);
  };

  const obtener = async () => {
    setLoading(true);
    console.log(fechaImport);
    if (fechaImport !== "") {
      const fecha = moment
        .utc(fechaImport)
        .utcOffset(0)
        .format("YYYY-MM-DD HH:mm:ss");
      let respDescargas;
      console.log(fecha);
      if (enlazarDescargaCarga) {
        respDescargas = await obtenerDescargasEnlazadas({ fecha });
      } else {
        respDescargas = await obtenerDescargas({ fecha });
      }
      const descargasFormat = respDescargas.body.map((val) => {
        return {
          ...val,
          planDescarga:
            val.planDescarga !== ""
              ? moment
                .utc(val.planDescarga)
                .utcOffset(0)
                .format("YYYY-MM-DD HH:mm:ss")
              : "",
        };
      });
      console.log(respDescargas);
      console.log(descargasFormat);
      const datosAgrupados = {};
      descargasFormat.map((dato) => {
        // console.log("----");
        const planilla = dato.planilla;

        // Verificar si la planilla ya existe en los datos agrupados
        if (!datosAgrupados.hasOwnProperty(planilla)) {
          // Si no existe, crear un arreglo vacío para almacenar los datos de la planilla
          datosAgrupados[planilla] = [];
        }

        // Crear un objeto con los campos requeridos y agregarlo al arreglo de la planilla

        const datoAgrupado = {
          id: dato.id,
          codigoOrigen: dato.codigoOrigen,
          depositoOrigen: dato.depositoOrigen,
          codigoDestino: dato.codigoDestino,
          depositoDestino: dato.depositoDestino,
          planilla: dato.planilla,
          planDescarga: dato.planDescarga,
          tipoViaje: dato.tipoViaje,
          fechaSalida: moment
            .utc(dato.fechaSalida)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
          fechaRegistro: moment
            .utc(dato.fechaRegistro)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
          nombreFletero: dato.nombreFletero,
          codigoUnidad: dato.codigoUnidad,
          placa: dato.placa,
          eta: dato.eta,
          observacionEta: dato.observacionEta,
          observacionPlanDescarga: dato.observacionPlanDescarga,
          nroViajeCarga: dato.nroViaje,
          enviado: dato.enviado,
          fechaModificacion: dato.fechaModificacion,
        };
        datosAgrupados[planilla].push(datoAgrupado);
      });

      console.log(descargasFormat);
      let respdatoAgrupadoFormat = Object.keys(datosAgrupados).map((data) => {
        return {
          id: datosAgrupados[data][0].id,
          planilla: datosAgrupados[data][0].planilla,
          codigoOrigen: datosAgrupados[data][0].codigoOrigen,
          depositoOrigen: datosAgrupados[data][0].depositoOrigen,
          codigoDestino: datosAgrupados[data][0].codigoDestino,
          depositoDestino: datosAgrupados[data][0].depositoDestino,
          fechaRegistro: datosAgrupados[data][0].fechaRegistro,
          planDescarga: datosAgrupados[data][0].planDescarga,
          nombreFletero: datosAgrupados[data][0].nombreFletero,
          codigoUnidad: datosAgrupados[data][0].codigoUnidad,
          fechaSalida: datosAgrupados[data][0].fechaSalida,
          tipoViaje: datosAgrupados[data][0].tipoViaje,
          placa: datosAgrupados[data][0].placa,
          eta: datosAgrupados[data][0].eta,
          observacionEta: datosAgrupados[data][0].observacionEta,
          observacionPlanDescarga:
            datosAgrupados[data][0].observacionPlanDescarga,
          nroViaje: datosAgrupados[data][0].nroViajeCarga,
          enviado: datosAgrupados[data][0].enviado,
          fechaModificacion: moment(
            datosAgrupados[data][0].fechaModificacion
          ).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      //moment.utc(rows[i][dataConfiguracion.indexPlanDescarga]).format("YYYY-MM-DD HH:mm:ss")
      /*for (let i = 0; i < respdatoAgrupadoFormat.length; i++) {
        setLoading(true);
        const respSMSEnvio = await obtenerEnvioData({
          planilla: respdatoAgrupadoFormat[i].planilla,
        });
        console.log("oooooo");
        console.log(respdatoAgrupadoFormat[i].planilla);
        console.log(respSMSEnvio.body[0]);
        console.log("oooooo");
        respdatoAgrupadoFormat[i].enviado = respSMSEnvio.body[0]
          ? respSMSEnvio.body[0].enviado
          : 0;
        respdatoAgrupadoFormat[i].fechaModificacion = respSMSEnvio.body[0]
          ? moment(respSMSEnvio.body[0].fechaModificacion).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : respdatoAgrupadoFormat[i].fechaRegistro;
      }*/
      agregarAccionesAListaAgrupar(respdatoAgrupadoFormat);
      //setLoading(true);
      setDescargasDataAgrupado(respdatoAgrupadoFormat);
      agregarAccionesALista(descargasFormat);
      setDescargasData(descargasFormat);
      console.log(respdatoAgrupadoFormat);
      setLoading(false);
    }
    const resp = await obtenerConfiguracion();

    const formatData = {
      ...resp.body[0],
      valorCodigoOrigen: JSON.parse(resp.body[0].valorCodigoOrigen),
      valorDepositoOrigen: JSON.parse(resp.body[0].valorDepositoOrigen),
      valorCodigoDestino: JSON.parse(resp.body[0].valorCodigoDestino),
      valorDepositoDestino: JSON.parse(resp.body[0].valorDepositoDestino),
      valorPlanilla: JSON.parse(resp.body[0].valorPlanilla),
      valorNombreFletero: JSON.parse(resp.body[0].valorNombreFletero),
      valorCodigoUnidad: JSON.parse(resp.body[0].valorCodigoUnidad),
      valorPlaca: JSON.parse(resp.body[0].valorPlaca),
      valorFechaSalida: JSON.parse(resp.body[0].valorFechaSalida),
      valorFechaEntrada: JSON.parse(resp.body[0].valorFechaEntrada),
      valorEstado: JSON.parse(resp.body[0].valorEstado),
      valorCodigoProducto: JSON.parse(resp.body[0].valorCodigoProducto),
      valorProductoNombre: JSON.parse(resp.body[0].valorProductoNombre),
      valorBultos: JSON.parse(resp.body[0].valorBultos),
      valorTipoProducto: JSON.parse(resp.body[0].valorTipoProducto),
      valorTipoViaje: JSON.parse(resp.body[0].valorTipoViaje),
      valorReferencia: JSON.parse(resp.body[0].valorReferencia),
      valorETA: JSON.parse(resp.body[0].valorETA),
      valorObservacionETA: JSON.parse(resp.body[0].valorObservacionETA),
      valorPlanDescarga: JSON.parse(resp.body[0].valorPlanDescarga),
      valorObservacionPlanDescarga: JSON.parse(
        resp.body[0].valorObservacionPlanDescarga
      ),
      valorNroViajeCarga: JSON.parse(resp.body[0].valorNroViajeCarga),

      valorCodigoOrigenJson: resp.body[0].valorCodigoOrigen,
      valorDepositoOrigenJson: resp.body[0].valorDepositoOrigen,
      valorCodigoDestinoJson: resp.body[0].valorCodigoDestino,
      valorDepositoDestinoJson: resp.body[0].valorDepositoDestino,
      valorPlanillaJson: resp.body[0].valorPlanilla,
      valorNombreFleteroJson: resp.body[0].valorNombreFletero,
      valorCodigoUnidadJson: resp.body[0].valorCodigoUnidad,
      valorPlacaJson: resp.body[0].valorPlaca,
      valorFechaSalidaJson: resp.body[0].valorFechaSalida,
      valorFechaEntradaJson: resp.body[0].valorFechaEntrada,
      valorEstadoJson: resp.body[0].valorEstado,
      valorCodigoProductoJson: resp.body[0].valorCodigoProducto,
      valorProductoNombreJson: resp.body[0].valorProductoNombre,
      valorBultosJson: resp.body[0].valorBultos,
      valorTipoProductoJson: resp.body[0].valorTipoProducto,
      valorTipoViajeJson: resp.body[0].valorTipoViaje,
      valorReferenciaJson: resp.body[0].valorReferencia,
      valorETAJson: resp.body[0].valorETA,
      valorObservacionETAJson: resp.body[0].valorObservacionETA,
      valorPlanDescargaJson: resp.body[0].valorPlanDescarga,
      valorObservacionPlanDescargaJson:
        resp.body[0].valorObservacionPlanDescarga,
      valorNroViajeCargaJson: resp.body[0].valorNroViajeCarga,
    };
    setDataConfiguracion(formatData);
  };

  const reset = () => {
    try {
      setDataExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };
  const handleCheckboxChange = (event) => {
    setVerDetallado(event.target.checked);
  };
  const verificarCambios = async (fecha, excel) => {
    setDataModificarNew(null);
    setDataModificar(null);
    console.log(fecha);
    const resp = descargasData; //await obtenerDescargas({ fecha });
    console.log(descargasData);
    const listParaActualizar = [];
    for (let i = 0; i < resp /*.body*/.length; i++) {
      let campos = resp[i]; /*{
        ...resp.body[i],
        fechaRegistro: moment
          .utc(fecha)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss"),
        fechaProceso: moment
          .utc(fecha)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss"),
      };*/
      const values = resp /*.body*/[i];
      let seModifico = false;

      if (values.codigoOrigen !== excel[i].codigoOrigen) {
        //"entra al revisar");
        campos.codigoOrigen = excel[i].codigoOrigen;
        seModifico = true;
      }
      if (values.depositoOrigen !== excel[i].depositoOrigen) {
        //"entra al revisar 2");
        campos.depositoOrigen = excel[i].depositoOrigen;
        seModifico = true;
      }
      if (values.codigoDestino !== excel[i].codigoDestino) {
        //"entra al revisar 3");
        campos.codigoDestino = excel[i].codigoDestino;
        seModifico = true;
      }
      if (values.depositoDestino !== excel[i].depositoDestino) {
        //"entra al revisar 4");
        //`${values.depositoDestino} - ${excel[i].depositoDestino}`);
        campos.depositoDestino = excel[i].depositoDestino;
        seModifico = true;
      }
      if (values.planilla !== excel[i].planilla) {
        //"entra al revisar 5");
        campos.planilla = excel[i].planilla;
        seModifico = true;
      }
      if (values.nombreFletero !== excel[i].nombreFletero) {
        //"entra al revisar 6");
        campos.nombreFletero = excel[i].nombreFletero;
        seModifico = true;
      }
      if (values.codigoUnidad !== excel[i].codigoUnidad) {
        //"entra al revisar 7");
        campos.codigoUnidad = excel[i].codigoUnidad;
        seModifico = true;
      }
      if (values.placa !== excel[i].placa) {
        //"entra al revisar 8");
        campos.placa = excel[i].placa;
        seModifico = true;
      }
      if (values.fechaSalida !== excel[i].fechaSalida) {
        //"entra al revisar 9");
        campos.fechaSalida = excel[i].fechaSalida;
        seModifico = true;
      }
      if (values.fechaEntrada !== excel[i].fechaEntrada) {
        //"entra al revisar 10");
        campos.fechaEntrada = excel[i].fechaEntrada;
        seModifico = true;
      }
      if (values.estado !== excel[i].estado) {
        //"entra al revisar 11");
        campos.estado = excel[i].estado;
        seModifico = true;
      }
      if (values.codigoProducto !== excel[i].codigoProducto) {
        //"entra al revisar 12");
        campos.codigoProducto = excel[i].codigoProducto;
        seModifico = true;
      }
      if (values.productoNombre !== excel[i].productoNombre) {
        //"entra al revisar 13");
        campos.productoNombre = excel[i].productoNombre;
        seModifico = true;
      }
      if (values.bultos !== excel[i].bultos) {
        //"entra al revisar 14");
        campos.bultos = excel[i].bultos;
        seModifico = true;
      }
      if (values.tipoProducto !== excel[i].tipoProducto) {
        //"entra al revisar 1");
        campos.tipoProducto = excel[i].tipoProducto;
        seModifico = true;
      }
      if (values.tipoViaje !== excel[i].tipoViaje) {
        //"entra al revisar 15");
        campos.tipoViaje = excel[i].tipoViaje;
        seModifico = true;
      }
      if (values.referencia !== excel[i].referencia) {
        ////"entra al revisar 16");
        //campos.referencia + "--" + excel[i].referencia);
        campos.referencia = excel[i].referencia;
        seModifico = true;
      }
      if (values.eta !== excel[i].eta) {
        ////"entra al revisar 17");
        campos.eta = excel[i].eta;
        seModifico = true;
      }
      if (values.observacionEta !== excel[i].observacionEta) {
        ////"entra al revisar 18");
        campos.observacionEta = excel[i].observacionEta;
        seModifico = true;
      }
      if (values.planDescarga !== excel[i].planDescarga) {
        // //"entra al revisar 19");
        campos.planDescarga = excel[i].planDescarga;
        seModifico = true;
      }
      if (values.observacionPlanDescarga !== excel[i].observacionPlanDescarga) {
        ////"entra al revisar 20");
        campos.observacionPlanDescarga = excel[i].observacionPlanDescarga;
        seModifico = true;
      }
      if (seModifico) {
        campos.fila = i;
        listParaActualizar.push(campos);
      }
    }
    //console.log("--------");
    //console.log(listParaActualizar);
    //console.log("--------");
    if (resp /*.body*/.length < excel.length) {
      const listRegistrosNuevos = [];
      for (let i = resp /*.body*/.length; i < excel.length; i++) {
        listRegistrosNuevos.push({
          ...excel[i],
          fechaRegistro: moment
            .utc(fecha)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
          fechaProceso: moment
            .utc(fecha)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      if (listRegistrosNuevos.length > 0) {
        setDataModificarNew(listRegistrosNuevos);
      }
    }

    if (listParaActualizar.length > 0) {
      console.log(listParaActualizar);
      setDataModificar(listParaActualizar);
    }
  };
  const verificarCantidadColumnas = () => {
    let maxValor = null;
    //let propiedadMaxValor = null;

    // Iterar a través de las propiedades del objeto dataConfiguracion
    console.log(dataConfiguracion);
    for (const propiedad in dataConfiguracion) {
      // Verificar si la propiedad comienza con "valor" y si su valor es un número
      if (propiedad.startsWith("index")) {
        // Si maxValor es nulo o el valor actual es mayor que maxValor, actualiza maxValor y propiedadMaxValor
        if (maxValor === null || dataConfiguracion[propiedad] > maxValor) {
          maxValor = dataConfiguracion[propiedad];
          //propiedadMaxValor = propiedad;
        }
      }
    }

    // Verificar si se encontró una propiedad con valor máximo
    if (maxValor !== null) {
      return maxValor;
    } else {
      return null;
    }
  };
  const procesarExcel = (rows) => {
    try {
      if (rows.length == 0) {
        return;
      }
      let dataFormat = [];
      /*const ultimaFecha = moment(listadoImport[0].fechaFormat).format(
        "YYYY-MM-DD"
      );
      const fechaActual = moment(new Date()).format("YYYY-MM-DD");*/
      const val = verificarCantidadColumnas();
      const cantidadDePropiedades = Object.keys(rows[0]).length;
      console.log(val + "-" + cantidadDePropiedades);
      //if (val >= cantidadDePropiedades) {
      for (let i = 1; i < rows.length; i++) {
        let row = {
          codigoOrigen: rows[i][dataConfiguracion.indexCodigoOrigen]
            ? rows[i][dataConfiguracion.indexCodigoOrigen].toString()
            : "",
          depositoOrigen: rows[i][dataConfiguracion.indexDepositoOrigen]
            ? rows[i][dataConfiguracion.indexDepositoOrigen].toString()
            : "",
          codigoDestino: rows[i][dataConfiguracion.indexCodigoDestino]
            ? rows[i][dataConfiguracion.indexCodigoDestino].toString()
            : "",
          depositoDestino: rows[i][dataConfiguracion.indexDepositoDestino]
            ? rows[i][dataConfiguracion.indexDepositoDestino].toString()
            : "",
          planilla: rows[i][dataConfiguracion.indexPlanilla]
            ? rows[i][dataConfiguracion.indexPlanilla].toString()
            : "",
          nombreFletero: rows[i][dataConfiguracion.indexNombreFletero]
            ? rows[i][dataConfiguracion.indexNombreFletero].toString()
            : "",
          codigoUnidad: rows[i][dataConfiguracion.indexCodigoUnidad]
            ? rows[i][dataConfiguracion.indexCodigoUnidad].toString()
            : "",
          placa: rows[i][dataConfiguracion.indexPlaca]
            ? rows[i][dataConfiguracion.indexPlaca].toString()
            : "",
          fechaSalida: rows[i][dataConfiguracion.indexFechaSalida]
            ? moment
              .utc(rows[i][dataConfiguracion.indexFechaSalida])
              .format("YYYY-MM-DD HH:mm:ss")
            : "",
          fechaEntrada: rows[i][dataConfiguracion.indexFechaEntrada]
            ? moment
              .utc(rows[i][dataConfiguracion.indexFechaEntrada])
              .format("YYYY-MM-DD HH:mm:ss")
            : "",
          estado: rows[i][dataConfiguracion.indexEstado]
            ? rows[i][dataConfiguracion.indexEstado].toString()
            : "",
          codigoProducto: rows[i][dataConfiguracion.indexCodigoProducto]
            ? rows[i][dataConfiguracion.indexCodigoProducto].toString()
            : "",
          productoNombre: rows[i][dataConfiguracion.indexProductoNombre]
            ? rows[i][dataConfiguracion.indexProductoNombre].toString()
            : "",
          bultos: rows[i][dataConfiguracion.indexBultos]
            ? rows[i][dataConfiguracion.indexBultos].toString()
            : "",
          tipoProducto: rows[i][dataConfiguracion.indexTipoProducto]
            ? rows[i][dataConfiguracion.indexTipoProducto].toString()
            : "",
          tipoViaje: rows[i][dataConfiguracion.indexTipoViaje]
            ? rows[i][dataConfiguracion.indexTipoViaje]
            : "",
          referencia: rows[i][dataConfiguracion.indexReferencia]
            ? rows[i][dataConfiguracion.indexReferencia].toString()
            : "",
          eta: rows[i][dataConfiguracion.indexETA]
            ? rows[i][dataConfiguracion.indexETA] instanceof Date
              ? moment
                .utc(rows[i][dataConfiguracion.indexETA])
                .format("YYYY-MM-DD HH:mm:ss")
              : rows[i][dataConfiguracion.indexETA]
            : "",
          observacionEta: rows[i][dataConfiguracion.indexObservacionETA]
            ? rows[i][dataConfiguracion.indexObservacionETA].toString()
            : "",
          planDescarga: rows[i][dataConfiguracion.indexPlanDescarga]
            ? moment
              .utc(rows[i][dataConfiguracion.indexPlanDescarga])
              .format("YYYY-MM-DD HH:mm:ss")
            : "",
          observacionPlanDescarga: rows[i][
            dataConfiguracion.indexObservacionPlanDescarga
          ]
            ? rows[i][dataConfiguracion.indexObservacionPlanDescarga].toString()
            : "",
          nroViajeCarga: rows[i][dataConfiguracion.indexNroViajeCarga]
            ? rows[i][dataConfiguracion.indexNroViajeCarga].toString()
            : "",
        };

        dataFormat.push(row);
      }

      setDataExcel(dataFormat);

      if (dataFormat.length > 0) {
        if (!isListImport) {
          console.log("entra aca");
          console.log(dataFormat);
          verificarCambios(fechaImport, dataFormat);
        }
        alert("Se cargaron los datos correctamente");
      }
      /*} else {
        alert(
          "No se proceso el excel debido a que al parecer esta usando un excel diferente en cantidad de columnas al que esta configurado, vuelva a configurar las posiciones de los datos para este nuevo excel"
        );
      }*/
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };
  const obtenerGeorutas = async () => {
    const resp = await obtenerGeoruta();
    let georuta = [];
    for (let i = 0; i < resp.length; i++) {
      georuta.push({
        value: resp[i].id,
        label: resp[i].codigo + " - " + resp[i].nombre,
      });
    }
    setOptionGeoruta(georuta);
    console.log(resp);
  };
  const initPermisos = async () => {
    let obj = {};
    obj.idMenuPrimerNivel = INT_ID_MODULO_DESCARGAS;
    obj.idMenuSegundoNivel = 0;
    obj.idMenuTercerNivel = 0;
    let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);

    let permiso = obtenerPermisos[0];
    console.log(permiso);
    setPermisos(permiso);
    console.log(idTipoUsuario);
    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      setTieneAccesoAlModulo(true);
      setExportar(true);
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      if (permiso.accesoAlModulo === TRUE) {
        setTieneAccesoAlModulo(true);
        setExportar(permiso.exportar == 1);
      }
    }
  };

  useEffect(() => {
    if (excelValue !== null) {
      reset();
      console.log(excelValue);
      procesarExcel(excelValue);
    }
  }, [excelValue]);

  useEffect(() => {
    initPermisos();
  }, []);
  useEffect(() => {
    if (tieneAccesoAlModulo === true) {
      if (location.state) {
        setLoading(true);
        setFechaImport(location.state.fecha);
        setShowListadoImport(false);
      } else {
        setNombreExcel("");
        const fileUpload = document.getElementById("fileUpload");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);

          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtenerListaImportaciones();
        obtenerGeorutas();
      }
    }
  }, [tieneAccesoAlModulo]);
  useEffect(() => {
    if (tieneAccesoAlModulo === true) {
      if (!showListadoImport) {
        setLoading(true);
        setNombreExcel("");
        setMessage(null);
        const fileUpload = document.getElementById("fileUploadListado");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);
          setIsListImport(true);
          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtener();
        console.log("//**///");
        //setLoading(false);
      } else {
        setLoading(true);
        setNombreExcel("");
        setMessage(null);
        const fileUpload = document.getElementById("fileUpload");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);
          setIsListImport(false);
          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtenerListaImportaciones();
      }
    }
  }, [showListadoImport]);

  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const handleNextPage = () => {
    if (maxRows >= descargasData.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const headersDescargas = !enlazarDescargaCarga
    ? [
      { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
      { key: "codigoOrigen", title: "Cod. Origen", filter: true },
      { key: "depositoOrigen", title: "Deposito Origen", filter: true },
      { key: "codigoDestino", title: "Cod. Destino", filter: true },
      { key: "depositoDestino", title: "Deposito Destino", filter: true },
      { key: "planilla", title: "planilla", filter: true },

      { key: "nombreFletero", title: "Nombre Fletero", filter: true },
      { key: "codigoUnidad", title: "Cod. Unidad", filter: true },
      { key: "placa", title: "Placa", filter: true },
      { key: "fechaSalida", title: "Fecha Salida", filter: true },
      { key: "fechaEntrada", title: "Fecha Entrada", filter: true },

      { key: "estado", title: "Estado" },
      { key: "codigoProducto", title: "Cod. Producto", filter: true },
      { key: "productoNombre", title: "Nombre Producto", filter: true },
      { key: "bultos", title: "Bultos", filter: true },
      { key: "tipoProducto", title: "Tipo Producto", filter: true },

      { key: "tipoViaje", title: "Tipo Viaje" },
      { key: "referencia", title: "Referencia", filter: true },
      { key: "eta", title: "ETA", filter: true },
      { key: "observacion", title: "Observacion", filter: true },
      { key: "planDescarga", title: "Plan Descarga", filter: true },
      {
        key: "observacionPlanDescarga",
        title: "Obs. Plan Descarga",
        filter: true,
      },
    ]
    : [
      { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
      { key: "codigoOrigen", title: "Cod. Origen", filter: true },
      { key: "depositoOrigen", title: "Deposito Origen", filter: true },
      { key: "codigoDestino", title: "Cod. Destino", filter: true },
      { key: "depositoDestino", title: "Deposito Destino", filter: true },
      { key: "planilla", title: "planilla", filter: true },

      { key: "nombreFletero", title: "Nombre Fletero", filter: true },
      { key: "codigoUnidad", title: "Cod. Unidad", filter: true },
      { key: "placa", title: "Placa", filter: true },
      { key: "fechaSalida", title: "Fecha Salida", filter: true },
      { key: "fechaEntrada", title: "Fecha Entrada", filter: true },

      { key: "estado", title: "Estado" },
      { key: "codigoProducto", title: "Cod. Producto", filter: true },
      { key: "productoNombre", title: "Nombre Producto", filter: true },
      { key: "bultos", title: "Bultos", filter: true },
      { key: "tipoProducto", title: "Tipo Producto", filter: true },

      { key: "tipoViaje", title: "Tipo Viaje" },
      { key: "referencia", title: "Referencia", filter: true },
      { key: "eta", title: "ETA", filter: true },
      { key: "observacion", title: "Observacion", filter: true },
      { key: "planDescarga", title: "Plan Descarga", filter: true },
      {
        key: "observacionPlanDescarga",
        title: "Obs. Plan Descarga",
        filter: true,
      },
      { key: "nroViaje", title: "Nro Viaje Carga", filter: true },
    ];
  const headersDescargasAgrupado = !enlazarDescargaCarga
    ? [
      { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
      { key: "fechaModificacion", title: "Ult. Modificacion", filter: true },
      { key: "planilla", title: "Planilla", filter: true },
      { key: "placa", title: "Placa", filter: true },
      { key: "codigoOrigen", title: "Cod. Origen", filter: true },
      { key: "depositoOrigen", title: "Deposito Origen", filter: true },
      { key: "codigoDestino", title: "Cod. Destino", filter: true },
      { key: "depositoDestino", title: "Deposito Destino", filter: true },
      { key: "tipoViaje", title: "Tipo Viaje" },
      { key: "fechaSalida", title: "Fecha Salida", filter: true },
      { key: "planDescarga", title: "Plan Descarga", filter: true },
    ]
    : [
      { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
      { key: "fechaModificacion", title: "Ult. Modificacion", filter: true },
      { key: "planilla", title: "Planilla", filter: true },
      { key: "placa", title: "Placa", filter: true },
      { key: "codigoOrigen", title: "Cod. Origen", filter: true },
      { key: "depositoOrigen", title: "Deposito Origen", filter: true },
      { key: "codigoDestino", title: "Cod. Destino", filter: true },
      { key: "depositoDestino", title: "Deposito Destino", filter: true },
      { key: "tipoViaje", title: "Tipo Viaje" },
      { key: "fechaSalida", title: "Fecha Salida", filter: true },
      { key: "planDescarga", title: "Plan Descarga", filter: true },
      { key: "nroViaje", title: "Nro Viaje Carga", filter: true },
    ];
  const headersListado = [
    { key: "acciones", title: "Acciones", id: 1 },
    {
      key: "fechaFormat",
      title: "Fecha de datos",
      filter: true,
      filaClick: true,
    },
  ];

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };

  const guardar = async () => {
    setLoading(true);

    let resp;

    console.log("entra aca");
    resp = await guardarDescargas({ data: dataExcel, enlazarDescargaCarga });

    obtenerListaImportaciones();
    if (resp) {
      if (resp.code === "SUCCESS") {
        if (resp.body.sinEnviarSMS) {
          if (resp.body.sinEnviarSMS.length > 0) {
            let createMessage =
              "A las siguientes placas no se les envio sms cuando se guardaron los datos debido a que no tienen un usuario asignado: ";
            for (let i = 0; i < resp.body.sinEnviarSMS.length; i++) {
              createMessage = `${createMessage} ,  ${resp.body.sinEnviarSMS[i]}`;
            }
            setMessage(createMessage);
          }
        }
        setLoading(false);
        alert("Se guardo correctamente la informacion");
      } else {
        setLoading(false);
        alert("Sucedio un problema porfavor vuelva a intentar");
      }
    } else {
      alert("No existe ningun cambio en el excel");
    }
  };
  const enviarSMS = async () => {
    setLoading(true);
    const resp = await enviarSMSDescargas(descargasData);
    await obtener();
    setLoading(false);
  };
  const enviarUnSMS = async (value) => {
    setLoading(true);
    const resp = await enviarSMSDescarga(value);
    await obtener();
    setLoading(false);
    //console.log(value);
  };
  const modificarListado = async () => {
    setLoading(true);
    let resp;
    if (dataModificar) {
      resp = await modificarVariasDescargas(dataModificar);
    }
    if (dataModificarNew) {
      resp = await guardarDescargasEnRegistroExistente(dataModificarNew);
    }
    await obtener();
    if (resp) {
      if (resp.code === "SUCCESS") {
        if (resp.body.sinEnviarSMS) {
          if (resp.body.sinEnviarSMS.length > 0) {
            let createMessage =
              "A las siguientes placas no se les envio sms cuando se guardaron los datos debido a que no tienen un usuario asignado: ";
            for (let i = 0; i < resp.body.sinEnviarSMS.length; i++) {
              createMessage = `${createMessage} ,  ${resp.body.sinEnviarSMS[i]}`;
            }
            setMessage(createMessage);
          }
        }
        setLoading(false);

        alert("Se guardo correctamente la informacion");
      } else {
        setLoading(false);
        alert("Sucedio un problema porfavor vuelva a intentar");
      }
      setDataModificar(null);
      setDataModificarNew(null);
    } else {
      alert("No existe ningun cambio en el excel");
    }
  };
  const modificar = async (value) => {
    nextPage("descargas/administracion", value);
  };
  const eliminarId = async (value) => {
    console.log(value);
    console.log(value);
    const resp = await eliminarDescargaId({ id: value.id });
    await obtener();

    alert(`se elimino el registro`);
    setLoading(false);
  };
  const agregarAccionesALista = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Eliminar",
            icon: Trash2,
            color: "#00b894",
            onClick: (param) => eliminarId(param),
          };
          acciones.push(objModificar);
          lista[i].acciones = acciones;
        }
        setDataExcel(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };
  const eliminarPlanilla = async (value) => {
    console.log(value);
    const resp = await eliminarDescargaPlanilla({ planilla: value.planilla });
    await obtener();
    alert(`se elimino los registros con la planilla: ${value.planilla}`);
    setLoading(false);
  };
  const agregarAccionesAListaAgrupar = (lista) => {
    try {
      console.log(lista);
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            icon: Edit,
            color: "#00b894",
            onClick: (param) => modificar(param),
          };
          let objEnviarSMS = {
            label: "Enviar SMS",
            icon: Send,
            color: lista[i].enviado ? "#00b894" : "#EEDC82",
            onClick: (param) => enviarUnSMS(param),
          };
          let objEliminar = {
            label: "Eliminar",
            icon: Trash2,
            color: "#00b894",
            onClick: (param) => {
              eliminarPlanilla(param);
            },
          };
          let objAsignar = {
            label: "Asig. Itinerario",
            icon: PlusCircle,
            color: "#00b894",
            onClick: (param) => {
              setShowAsignarItinerario(true);
              setDescargaAsignar(param);
            },
          };

          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objEliminar);
            acciones.push(objAsignar);
            acciones.push(objEnviarSMS);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permisos.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permisos.eliminar === TRUE) {
              acciones.push(objEliminar);
            }
            if (permisos.enviarSms === TRUE) {
              acciones.push(objEnviarSMS);
            }
            if (permisos.itinerario === TRUE) {
              acciones.push(objAsignar);
            }
          }
          lista[i].acciones = acciones;
        }
        setDescargasDataAgrupado(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };
  const agregarAccionesVerALista = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objVer = {
            label: "Ver registros",
            color: "#00b894",
            //onClick: (param) => modificar(param),
          };
          acciones.push(objVer);
          lista[i].acciones = acciones;
        }
        setListadoImport(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const filaClick = async (row) => {
    setLoading(true);
    setFechaImport(row.fechaRegistro);
    await obtener();
    setShowListadoImport(false);
    setLoading(false);
  };

  const disabledButtom = (value) => {
    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      return false;
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB && value === 1) {
      return false;
    }
    return true;
  };
  if (tieneAccesoAlModulo === false) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <div style={{ marginTop: 10 }}>
          <p>Sin Acceso Al Modulo...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <input
        id="fileUpload"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />
      <input
        id="fileUploadListado"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />

      {tieneAccesoAlModulo && showListadoImport ? (
        <>
          <div className="flex px-8">
            <div className="w-2/4 ">
              <h1 className="font-bold text-gray-700 text-[24px]">Descargas</h1>
              <h3>Listado de importaciones</h3>
            </div>
            <div className="flex w-2/4 justify-end">
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  importar();
                }}
                //idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.cargar
                    : null
                )}
              >
                CARGAR
              </button>
              <a
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
                href="https://gestion.boltrack.net/assets/plantilla-descarga.xlsx"
                target="_blank"
              >
                PLANTILLA
              </a>
              <button
                className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px] disabled:bg-gray-300"
                onClick={() => {
                  guardar();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.guardar
                    : null
                )}
              >
                GUARDAR
              </button>
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div className="loader"></div>
              <div style={{ marginTop: 10 }}>
                <p>Cargando...</p>
              </div>
            </div>
          ) : (
            <>
              {nombreExcel !== "" && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`${nombreExcel} Esta listo para guardar`}</p>
                </div>
              )}

              <Grid
                rows={listadoImport}
                headers={headersListado}
                filaClick={filaClick}
                center={true}
              />

              <div>
                <button
                  className={ESTILO_BOTON_GRILLA_PREVIOUS}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <button
                  className={ESTILO_BOTON_GRILLA_NEXT}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {showAsignarItinerario && (
            <div className="bg-white h-[200px] w-1/3 absolute top-0 left-0 right-0 bottom-0 m-auto p-5 border-gray-300 border-2 rounded">
              <div>
                <p
                  className="text-right"
                  onClick={() => {
                    setShowAsignarItinerario(false);
                  }}
                >
                  X
                </p>
              </div>
              <div>
                <div>
                  <p>Georuta</p>
                  <div>
                    <Select
                      options={optionGeoruta}
                      isSearchable="true"
                      isClearable="true"
                      value={georutaSelected}
                      onChange={(event) => handleGeorutaSelected(event)}
                      placeholder="Seleccione una Georuta"
                    />
                  </div>
                </div>
                <div className="w-full flex my-[15px]">
                  <button
                    className="m-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
                    onClick={async () => {
                      console.log(georutaSelected);
                      const resp = await asignarGeorutaDescarga({
                        idDescarga: descargaAsignar.id,
                        idGeoruta: georutaSelected.value,
                      });
                      console.log(resp);
                      if (resp) {
                        alert(
                          "Se asigno la georuta con su itinerario exitosamente"
                        );
                      } else {
                        alert("Hubo un error, por favor vuelva a intentar");
                      }
                      setShowAsignarItinerario(false);
                      setGeorutaSelected(null);
                    }}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex px-8">
            <div className="w-2/3 ">
              <h1 className="font-bold text-gray-700 text-[24px]">Descargas</h1>
              <h3>Listado de descargas</h3>
            </div>
            <div className="flex w-1/3 justify-end">
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  importar();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.cargar
                    : null
                )}
              >
                CARGAR
              </button>
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  modificarListado();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.guardar
                    : null
                )}
              >
                GUARDAR
              </button>
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  enviarSMS();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.enviarSmsMasivo
                    : null
                )}
              >
                ENVIAR SMS
              </button>

              <button
                className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
                onClick={() => {
                  setShowListadoImport(true);
                  setDataModificar(null);
                }}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div className="flex px-8 mt-[25px]">
            <input
              type="checkbox"
              className="relative  inline-flex h-6 w-6 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              checked={verDetallado}
              onChange={handleCheckboxChange}
            />
            <p className="ml-[10px]">Ver detallado</p>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div className="loader"></div>
              <div style={{ marginTop: 10 }}>
                <p>Cargando...</p>
              </div>
            </div>
          ) : (
            <>
              {nombreExcel !== "" && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`${nombreExcel} Esta listo para guardar`}</p>
                </div>
              )}
              {dataModificar && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`las filas en la posicion ${dataModificar.map(
                    (data) => `${data.fila + 1}`
                  )} tienen modificaciones con respecto al registro actual`}</p>
                  {dataModificarNew && (
                    <p className="w-auto my-auto mr-[10px]">{`Hay ${dataModificarNew.length} filas nuevas con respecto al registro actual`}</p>
                  )}
                </div>
              )}
              {message && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  {message}
                </div>
              )}
              {verDetallado ? (
                <Grid
                  rows={descargasData}
                  headers={headersDescargas}
                  exportar={exportar}
                />
              ) : (
                <Grid
                  rows={descargasDataAgrupado}
                  headers={headersDescargasAgrupado}
                  exportar={exportar}
                />
              )}
              <div>
                <button
                  className={ESTILO_BOTON_GRILLA_PREVIOUS}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <button
                  className={ESTILO_BOTON_GRILLA_NEXT}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
