import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import {
  Edit,
  Slash,
  Trash2,
  Send,
  PlusCircle,
  Eye,
} from "feather-icons-react/build/IconComponents";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
} from "../../utils/const-estilos";
import {
  obtenerCargas,
  obtenerConfiguracion,
  guardarCargas,
  guardarCargasEnRegistroExistente,
  obtenerListadoImportaciones,
  modificarVariasCargas,
  enviarSMSCarga,
  enviarSMSCargas,
  obtenerCargasPlanilla,
  eliminarCargaId,
  eliminarCargaCodigoViaje,
  obtenerEnvioData,
} from "../../api/cargas";
import moment from "moment";
import "moment-timezone";
import "../../style/style-cargando.css";

import {
  INT_ID_MODULO_CARGAS,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
// let accesoBotonNuevo = 'none';

export default function Descargas() {
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [message, setMessage] = useState(null);
  const [nombreExcel, setNombreExcel] = useState("");
  const [showListadoImport, setShowListadoImport] = useState(true);
  const [fechaImport, setFechaImport] = useState("");
  const [listadoImport, setListadoImport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataModificar, setDataModificar] = useState(null);
  const [dataModificarNew, setDataModificarNew] = useState(null);
  const [excelValue, setExcelValue] = useState(null);
  const [descargasData, setDescargasData] = useState([]);
  const [descargasDataAgrupado, setDescargasDataAgrupado] = useState([]);
  const [verDetallado, setVerDetallado] = useState(false);
  const [isListImport, setIsListImport] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);
  const [exportar, setExportar] = useState(false);
  const [dataConfiguracion, setDataConfiguracion] = useState({
    indexFecha: null,
    indexCodigoPlanta: null,
    indexPlanta: null,
    indexNroViaje: null,
    indexCodigoFletero: null,
    indexCodigoUnidad: null,
    indexOperadorLogistico: null,
    indexChofer: null,
    indexPlaca: null,
    indexTipoCamion: null,
    indexEstadoCamion: null,
    indexPrioridad: null,
    indexCodigoOrigen: null,
    indexOrigen: null,
    indexCodigoProducto: null,
    indexProducto: null,
    indexTipoProducto: null,
    indexPallets: null,
    indexCodigoDestino: null,
    indexDestino: null,
    indexBultos: null,
    indexHl: null,
    indexPesoCarga: null,
    indexPesoPallets: null,
    indexPesoTotalCarga: null,
    indexComentarios: null,
    indexFechaHoraCarga: null,
    indexDockCarga: null,
    indexCodigoViaje: null,

    valorFecha: null,
    valorCodigoPlanta: null,
    valorPlanta: null,
    valorNroViaje: null,
    valorCodigoFleteros: null,
    valorCodigoUnidad: null,
    valorOperadorLogistico: null,
    valorChofer: null,
    valorPlaca: null,
    valorTipoCamion: null,
    valorEstadoCamion: null,
    valorPrioridad: null,
    valorCodigoOrigen: null,
    valorOrigen: null,
    valorCodigoProducto: null,
    valorProducto: null,
    valorTipoProducto: null,
    valorPallets: null,
    valorCodigoDestino: null,
    valorDestino: null,
    valorBultos: null,
    valorHl: null,
    valorPesoCarga: null,
    valorPesoPallets: null,
    valorPesoTotalCarga: null,
    valorComentarios: null,
    valorFechaHoraCarga: null,
    valorDockCarga: null,
    valorCodigoViaje: null,
  });
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [cargandoPage, setCargandoPage] = useState(false);

  const location = useLocation();

  const formatearHoraBolivia = (val) => {
    return moment.utc(val).tz("America/La_Paz").format("YYYY-MM-DD HH:mm:ss");
  };

  const obtenerListaImportaciones = async () => {
    setLoading(true);
    const resp = await obtenerListadoImportaciones();
    const format = resp.body
      .map((val) => {
        return {
          ...val,
          fechaFormat: formatearHoraBolivia(val.fechaRegistro),
        };
      })
      .sort((a, b) => {
        const fechaA = new Date(a.fechaFormat);
        const fechaB = new Date(b.fechaFormat);
        return fechaB - fechaA;
      });

    setListadoImport(format);
    agregarAccionesVerALista(format);
    const respConf = await obtenerConfiguracion();

    const formatData = {
      ...respConf.body[0],
      valorFecha: JSON.parse(respConf.body[0].valorFechaHoraCarga),
      valorCodigoPlanta: JSON.parse(respConf.body[0].valorCodigoPlanta),
      valorPlanta: JSON.parse(respConf.body[0].valorPlanta),
      valorNroViaje: JSON.parse(respConf.body[0].valorNroViaje),
      valorCodigoFletero: JSON.parse(respConf.body[0].valorCodigoFletero),
      valorCodigoUnidad: JSON.parse(respConf.body[0].valorCodigoUnidad),
      valorOperadorLogistico: JSON.parse(
        respConf.body[0].valorOperadorLogistico
      ),
      valorChofer: JSON.parse(respConf.body[0].valorChofer),
      valorPlaca: JSON.parse(respConf.body[0].valorPlaca),
      valorTipoCamion: JSON.parse(respConf.body[0].valorTipoCamion),
      valorEstadoCamion: JSON.parse(respConf.body[0].valorEstadoCamion),
      valorPrioridad: JSON.parse(respConf.body[0].valorPrioridad),
      valorCodigoOrigen: JSON.parse(respConf.body[0].valorCodigoOrigen),
      valorOrigen: JSON.parse(respConf.body[0].valorOrigen),
      valorCodigoProducto: JSON.parse(respConf.body[0].valorCodigoProducto),
      valorProducto: JSON.parse(respConf.body[0].valorProducto),
      valorTipoProducto: JSON.parse(respConf.body[0].valorTipoProducto),
      valorPallets: JSON.parse(respConf.body[0].valorPallets),
      valorCodigoDestino: JSON.parse(respConf.body[0].valorCodigoDestino),
      valorDestino: JSON.parse(respConf.body[0].valorDestino),
      valorBultos: JSON.parse(respConf.body[0].valorBultos),

      valorHl: JSON.parse(respConf.body[0].valorHl),
      valorPesoCarga: JSON.parse(respConf.body[0].valorPesoCarga),
      valorPesoPallets: JSON.parse(respConf.body[0].valorPesoPallets),
      valorPesoTotalCarga: JSON.parse(respConf.body[0].valorPesoTotalCarga),
      valorComentarios: JSON.parse(respConf.body[0].valorComentarios),
      valorFechaHoraCarga: JSON.parse(respConf.body[0].valorFechaHoraCarga),
      valorDockCarga: JSON.parse(respConf.body[0].valorDockCarga),
      valorCodigoViaje: JSON.parse(respConf.body[0].valorCodigoViaje),

      valorFechaJson: respConf.body[0].valorFecha,
      valorCodigoPlantaJson: respConf.body[0].valorCodigoPlanta,
      valorPlantaJson: respConf.body[0].valorPlanta,
      valorNroViajeJson: respConf.body[0].valorNroViaje,
      valorCodigoFleteroJson: respConf.body[0].valorCodigoFletero,
      valorCodigoUnidadJson: respConf.body[0].valorCodigoUnidad,
      valorOperadorLogisticoJson: respConf.body[0].valorOperadorLogistico,
      valorChoferJson: respConf.body[0].valorChofer,
      valorPlacaJson: respConf.body[0].valorPlaca,
      valorTipoCamionJson: respConf.body[0].valorTipoCamion,
      valorEstadoCamionJson: respConf.body[0].valorEstadoCamion,
      valorPrioridadJson: respConf.body[0].valorPrioridad,
      valorCodigoOrigenJson: respConf.body[0].valorCodigoOrigen,
      valorOrigenJson: respConf.body[0].valorOrigen,
      valorCodigoProductoJson: respConf.body[0].valorCodigoProducto,
      valorProductoJson: respConf.body[0].valorProducto,
      valorTipoProductoJson: respConf.body[0].valorTipoProducto,
      valorPalletsJson: respConf.body[0].valorPallets,
      valorCodigoDestinoJson: respConf.body[0].valorCodigoDestino,
      valorDestinoJson: respConf.body[0].valorDestino,
      valorBultosJson: respConf.body[0].valorBultos,

      valorHlJson: respConf.body[0].valorHl,
      valorPesoCargaJson: respConf.body[0].valorPesoCarga,
      valorPesoPalletsJson: respConf.body[0].valorPesoPallets,
      valorPesoTotalCargaJson: respConf.body[0].valorPesoTotalCarga,
      valorComentariosJson: respConf.body[0].valorComentarios,
      valorFechaHoraCargaJson: respConf.body[0].valorFechaHoraCarga,
      valorDockCargaJson: respConf.body[0].valorDockCarga,
      valorCodigoViajeJson: respConf.body[0].valorCodigoViaje,
    };
    setDataConfiguracion(formatData);
    setLoading(false);
  };

  const obtener = async () => {
    setLoading(true);
    if (fechaImport !== "") {
      const fecha = moment
        .utc(fechaImport)
        .utcOffset(0)
        .format("YYYY-MM-DD HH:mm:ss");
      const respDescargas = await obtenerCargas({ fecha });
      const descargasFormat = respDescargas.body.map((val) => {
        return {
          ...val,
          fechaHoraCarga:
            val.fechaHoraCarga !== ""
              ? moment
                .utc(val.fechaHoraCarga)
                .utcOffset(0)
                .format("YYYY-MM-DD HH:mm:ss")
              : "",
        };
      });
      const datosAgrupados = {};
      descargasFormat.forEach((dato) => {
        // console.log("----");
        const codigoViaje = dato.codigoViaje;

        // Verificar si la planilla ya existe en los datos agrupados
        if (!datosAgrupados.hasOwnProperty(codigoViaje)) {
          // Si no existe, crear un arreglo vacío para almacenar los datos de la planilla
          datosAgrupados[codigoViaje] = [];
        }

        // Crear un objeto con los campos requeridos y agregarlo al arreglo de la planilla
        const datoAgrupado = {
          id: dato.id,
          codigoOrigen: dato.codigoOrigen,
          origen: dato.origen,
          codigoPlanta: dato.codigoPlanta,
          planta: dato.planta,
          codigoDestino: dato.codigoDestino,
          destino: dato.destino,
          codigoViaje: dato.codigoViaje,
          fechaHoraCarga: dato.fechaHoraCarga,
          fechaRegistro: moment
            .utc(dato.fechaRegistro)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
          chofer: dato.chofer,
          codigoUnidad: dato.codigoUnidad,
          placa: dato.placa,
          prioridad: dato.prioridad,
          comentarios: dato.comentarios,
          dockCarga: dato.dockCarga,
          enviado: dato.enviado,
          fechaModificacion: dato.fechaModificacion,
        };
        datosAgrupados[codigoViaje].push(datoAgrupado);
      });

      const respdatoAgrupadoFormat = Object.keys(datosAgrupados).map((data) => {
        return {
          id: datosAgrupados[data][0].id,
          codigoViaje: datosAgrupados[data][0].codigoViaje,
          codigoPlanta: datosAgrupados[data][0].codigoPlanta,
          planta: datosAgrupados[data][0].planta,
          codigoOrigen: datosAgrupados[data][0].codigoOrigen,
          origen: datosAgrupados[data][0].origen,
          codigoDestino: datosAgrupados[data][0].codigoDestino,
          destino: datosAgrupados[data][0].destino,
          fechaRegistro: datosAgrupados[data][0].fechaRegistro,
          fechaHoraCarga: datosAgrupados[data][0].fechaHoraCarga,
          chofer: datosAgrupados[data][0].chofer,
          codigoUnidad: datosAgrupados[data][0].codigoUnidad,
          placa: datosAgrupados[data][0].placa,
          prioridad: datosAgrupados[data][0].prioridad,
          comentarios: datosAgrupados[data][0].comentarios,
          dockCarga: datosAgrupados[data][0].dockCarga,
          enviado: datosAgrupados[data][0].enviado,
          fechaModificacion: moment(
            datosAgrupados[data][0].fechaModificacion
          ).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      /*for (let i = 0; i < respdatoAgrupadoFormat.length; i++) {
        setLoading(true);
        const respSMSEnvio = await obtenerEnvioData({
          codigoViaje: respdatoAgrupadoFormat[i].codigoViaje,
        });
        respdatoAgrupadoFormat[i].enviado = respSMSEnvio.body[0]
          ? respSMSEnvio.body[0].enviado
          : 0;
        respdatoAgrupadoFormat[i].fechaModificacion = respSMSEnvio.body[0]
          ? moment(respSMSEnvio.body[0].fechaModificacion).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : respdatoAgrupadoFormat[i].fechaRegistro;
      }*/
      agregarAccionesAListaAgrupar(respdatoAgrupadoFormat);
      setDescargasDataAgrupado(respdatoAgrupadoFormat);
      agregarAccionesALista(descargasFormat);
      setDescargasData(descargasFormat);
      setLoading(false);
    }
    const resp = await obtenerConfiguracion();

    const formatData = {
      ...resp.body[0],
      valorFecha: JSON.parse(resp.body[0].valorFecha),
      valorCodigoPlanta: JSON.parse(resp.body[0].valorCodigoPlanta),
      valorPlanta: JSON.parse(resp.body[0].valorPlanta),
      valorNroViaje: JSON.parse(resp.body[0].valorNroViaje),
      valorCodigoFletero: JSON.parse(resp.body[0].valorCodigoFletero),
      valorCodigoUnidad: JSON.parse(resp.body[0].valorCodigoUnidad),
      valorOperadorLogistico: JSON.parse(resp.body[0].valorOperadorLogistico),
      valorChofer: JSON.parse(resp.body[0].valorChofer),
      valorPlaca: JSON.parse(resp.body[0].valorPlaca),
      valorTipoCamion: JSON.parse(resp.body[0].valorTipoCamion),
      valorEstadoCamion: JSON.parse(resp.body[0].valorEstadoCamion),
      valorPrioridad: JSON.parse(resp.body[0].valorPrioridad),
      valorCodigoOrigen: JSON.parse(resp.body[0].valorCodigoOrigen),
      valorOrigen: JSON.parse(resp.body[0].valorOrigen),
      valorCodigoProducto: JSON.parse(resp.body[0].valorCodigoProducto),
      valorProducto: JSON.parse(resp.body[0].valorProducto),
      valorTipoProducto: JSON.parse(resp.body[0].valorTipoProducto),
      valorPallets: JSON.parse(resp.body[0].valorPallets),
      valorCodigoDestino: JSON.parse(resp.body[0].valorCodigoDestino),
      valorDestino: JSON.parse(resp.body[0].valorDestino),
      valorBultos: JSON.parse(resp.body[0].valorBultos),

      valorHl: JSON.parse(resp.body[0].valorHl),
      valorPesoCarga: JSON.parse(resp.body[0].valorPesoCarga),
      valorPesoPallets: JSON.parse(resp.body[0].valorPesoPallets),
      valorPesoTotalCarga: JSON.parse(resp.body[0].valorPesoTotalCarga),
      valorComentarios: JSON.parse(resp.body[0].valorComentarios),
      valorFechaHoraCarga: JSON.parse(resp.body[0].valorFechaHoraCarga),
      valorDockCarga: JSON.parse(resp.body[0].valorDockCarga),
      valorCodigoViaje: JSON.parse(resp.body[0].valorCodigoViaje),

      valorFechaJson: resp.body[0].valorFecha,
      valorCodigoPlantaJson: resp.body[0].valorCodigoPlanta,
      valorPlantaJson: resp.body[0].valorPlanta,
      valorNroViajeJson: resp.body[0].valorNroViaje,
      valorCodigoFleteroJson: resp.body[0].valorCodigoFletero,
      valorCodigoUnidadJson: resp.body[0].valorCodigoUnidad,
      valorOperadorLogisticoJson: resp.body[0].valorOperadorLogistico,
      valorChoferJson: resp.body[0].valorChofer,
      valorPlacaJson: resp.body[0].valorPlaca,
      valorTipoCamionJson: resp.body[0].valorTipoCamion,
      valorEstadoCamionJson: resp.body[0].valorEstadoCamion,
      valorPrioridadJson: resp.body[0].valorPrioridad,
      valorCodigoOrigenJson: resp.body[0].valorCodigoOrigen,
      valorOrigenJson: resp.body[0].valorOrigen,
      valorCodigoProductoJson: resp.body[0].valorCodigoProducto,
      valorProductoJson: resp.body[0].valorProducto,
      valorTipoProductoJson: resp.body[0].valorTipoProducto,
      valorPalletsJson: resp.body[0].valorPallets,
      valorCodigoDestinoJson: resp.body[0].valorCodigoDestino,
      valorDestinoJson: resp.body[0].valorDestino,
      valorBultosJson: resp.body[0].valorBultos,

      valorHlJson: resp.body[0].valorHl,
      valorPesoCargaJson: resp.body[0].valorPesoCarga,
      valorPesoPalletsJson: resp.body[0].valorPesoPallets,
      valorPesoTotalCargaJson: resp.body[0].valorPesoTotalCarga,
      valorComentariosJson: resp.body[0].valorComentarios,
      valorFechaHoraCargaJson: resp.body[0].valorFechaHoraCarga,
      valorDockCargaJson: resp.body[0].valorDockCarga,
      valorCodigoViajeJson: resp.body[0].valorCodigoViaje,
    };
    setDataConfiguracion(formatData);
  };

  const reset = () => {
    try {
      setDataExcel([]);
    } catch (err) {
      console.log("reset err");
      console.log(err);
    }
  };
  const handleCheckboxChange = (event) => {
    setVerDetallado(event.target.checked);
  };
  const verificarCambios = async (fecha, excel) => {
    setDataModificarNew(null);
    setDataModificar(null);

    const resp = await obtenerCargas({ fecha });

    const listParaActualizar = [];
    for (let i = 0; i < resp.body.length; i++) {
      let campos = {
        ...resp.body[i],
        fechaRegistro: moment
          .utc(fecha)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss"),
        fechaProceso: moment
          .utc(fecha)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      const values = resp.body[i];
      let seModifico = false;

      if (values.fecha !== excel[i].fecha) {
        campos.fecha = excel[i].fecha;
        seModifico = true;
      }
      if (values.codigoPlanta !== excel[i].codigoPlanta) {
        campos.codigoPlanta = excel[i].codigoPlanta;
        seModifico = true;
      }
      if (values.planta !== excel[i].planta) {
        campos.planta = excel[i].planta;
        seModifico = true;
      }
      if (values.nroViaje !== excel[i].nroViaje) {
        campos.nroViaje = excel[i].nroViaje;
        seModifico = true;
      }
      if (values.codigoFletero !== excel[i].codigoFletero) {
        campos.codigoFletero = excel[i].codigoFletero;
        seModifico = true;
      }
      if (values.codigoUnidad !== excel[i].codigoUnidad) {
        campos.codigoUnidad = excel[i].codigoUnidad;
        seModifico = true;
      }
      if (values.operadorLogistico !== excel[i].operadorLogistico) {
        campos.operadorLogistico = excel[i].operadorLogistico;
        seModifico = true;
      }
      if (values.chofer !== excel[i].chofer) {
        campos.chofer = excel[i].chofer;
        seModifico = true;
      }
      if (values.placa !== excel[i].placa) {
        campos.placa = excel[i].placa;
        seModifico = true;
      }
      if (values.tipoCamion !== excel[i].tipoCamion) {
        campos.tipoCamion = excel[i].tipoCamion;
        seModifico = true;
      }
      if (values.estadoCamion !== excel[i].estadoCamion) {
        campos.estadoCamion = excel[i].estadoCamion;
        seModifico = true;
      }
      if (values.prioridad !== excel[i].prioridad) {
        campos.prioridad = excel[i].prioridad;
        seModifico = true;
      }
      if (values.codigoProducto !== excel[i].codigoProducto) {
        campos.codigoProducto = excel[i].codigoProducto;
        seModifico = true;
      }
      if (values.producto !== excel[i].producto) {
        campos.producto = excel[i].producto;
        seModifico = true;
      }
      if (values.tipoProducto !== excel[i].tipoProducto) {
        campos.tipoProducto = excel[i].tipoProducto;
        seModifico = true;
      }
      if (values.pallets !== excel[i].pallets) {
        campos.pallets = excel[i].pallets;
        seModifico = true;
      }
      if (values.codigoDestino !== excel[i].codigoDestino) {
        campos.codigoDestino = excel[i].codigoDestino;
        seModifico = true;
      }
      if (values.destino !== excel[i].destino) {
        campos.destino = excel[i].destino;
        seModifico = true;
      }
      if (values.bultos !== excel[i].bultos) {
        campos.bultos = excel[i].bultos;
        seModifico = true;
      }
      if (values.hl !== excel[i].hl) {
        campos.hl = excel[i].hl;
        seModifico = true;
      }
      if (values.pesoCarga !== excel[i].pesoCarga) {
        campos.pesoCarga = excel[i].pesoCarga;
        seModifico = true;
      }
      if (values.pesoPallets !== excel[i].pesoPallets) {
        campos.pesoPallets = excel[i].pesoPallets;
        seModifico = true;
      }
      if (values.pesoTotalCarga !== excel[i].pesoTotalCarga) {
        campos.pesoTotalCarga = excel[i].pesoTotalCarga;
        seModifico = true;
      }
      if (values.comentarios !== excel[i].comentarios) {
        campos.comentarios = excel[i].comentarios;
        seModifico = true;
      }
      if (values.fechaHoraCarga !== excel[i].fechaHoraCarga) {
        campos.fechaHoraCarga = excel[i].fechaHoraCarga;
        seModifico = true;
      }
      if (values.dockCarga !== excel[i].dockCarga) {
        campos.dockCarga = excel[i].dockCarga;
        seModifico = true;
      }
      if (values.codigoViaje !== excel[i].codigoViaje) {
        campos.codigoViaje = excel[i].codigoViaje;
        seModifico = true;
      }
      if (seModifico) {
        campos.fila = i;
        listParaActualizar.push(campos);
      }
    }
    if (resp.body.length < excel.length) {
      const listRegistrosNuevos = [];
      for (let i = resp.body.length; i < excel.length; i++) {
        listRegistrosNuevos.push({
          ...excel[i],
          fechaRegistro: moment
            .utc(fecha)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
          fechaProceso: moment
            .utc(fecha)
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      if (listRegistrosNuevos.length > 0) {
        setDataModificarNew(listRegistrosNuevos);
      }
    }

    if (listParaActualizar.length > 0) {
      setDataModificar(listParaActualizar);
    }
  };
  const procesarExcel = (rows) => {
    try {
      console.log(rows);
      if (rows.length == 0) {
        return;
      }
      let dataFormat = [];

      for (let i = 1; i < rows.length; i++) {
        let row = {
          fecha: rows[i][dataConfiguracion.indexFecha]
            ? moment
              .utc(rows[i][dataConfiguracion.indexFecha])
              .format("YYYY-MM-DD")
            : "",
          codigoPlanta: rows[i][dataConfiguracion.indexCodigoPlanta]
            ? rows[i][dataConfiguracion.indexCodigoPlanta].toString()
            : "",
          planta: rows[i][dataConfiguracion.indexPlanta]
            ? rows[i][dataConfiguracion.indexPlanta].toString()
            : "",
          nroViaje: rows[i][dataConfiguracion.indexNroViaje]
            ? rows[i][dataConfiguracion.indexNroViaje].toString()
            : "",
          codigoFletero: rows[i][dataConfiguracion.indexCodigoFletero]
            ? rows[i][dataConfiguracion.indexCodigoFletero].toString()
            : "",
          codigoUnidad: rows[i][dataConfiguracion.indexCodigoUnidad]
            ? rows[i][dataConfiguracion.indexCodigoUnidad].toString()
            : "",
          operadorLogistico: rows[i][dataConfiguracion.indexOperadorLogistico]
            ? rows[i][dataConfiguracion.indexOperadorLogistico].toString()
            : "",
          chofer: rows[i][dataConfiguracion.indexChofer]
            ? rows[i][dataConfiguracion.indexChofer].toString()
            : "",
          placa: rows[i][dataConfiguracion.indexPlaca]
            ? rows[i][dataConfiguracion.indexPlaca].toString()
            : "",
          tipoCamion: rows[i][dataConfiguracion.indexTipoCamion]
            ? rows[i][dataConfiguracion.indexTipoCamion].toString()
            : "",
          estadoCamion: rows[i][dataConfiguracion.indexEstadoCamion]
            ? rows[i][dataConfiguracion.indexEstadoCamion].toString()
            : "",
          prioridad: rows[i][dataConfiguracion.indexPrioridad]
            ? rows[i][dataConfiguracion.indexPrioridad].toString()
            : "",
          codigoOrigen: rows[i][dataConfiguracion.indexCodigoOrigen]
            ? rows[i][dataConfiguracion.indexCodigoOrigen].toString()
            : "",
          origen: rows[i][dataConfiguracion.indexOrigen]
            ? rows[i][dataConfiguracion.indexOrigen].toString()
            : "",
          codigoProducto: rows[i][dataConfiguracion.indexCodigoProducto]
            ? rows[i][dataConfiguracion.indexCodigoProducto].toString()
            : "",
          producto: rows[i][dataConfiguracion.indexProducto]
            ? rows[i][dataConfiguracion.indexProducto].toString()
            : "",
          tipoProducto: rows[i][dataConfiguracion.indexTipoProducto]
            ? rows[i][dataConfiguracion.indexTipoProducto].toString()
            : "",
          pallets: rows[i][dataConfiguracion.indexPallets]
            ? rows[i][dataConfiguracion.indexPallets].toString()
            : "",
          codigoDestino: rows[i][dataConfiguracion.indexCodigoDestino]
            ? rows[i][dataConfiguracion.indexCodigoDestino].toString()
            : "",
          destino: rows[i][dataConfiguracion.indexDestino]
            ? rows[i][dataConfiguracion.indexDestino].toString()
            : "",
          bultos: rows[i][dataConfiguracion.indexBultos]
            ? rows[i][dataConfiguracion.indexBultos].toString()
            : "",

          hl: rows[i][dataConfiguracion.indexHl]
            ? rows[i][dataConfiguracion.indexHl].toString()
            : "",
          pesoCarga: rows[i][dataConfiguracion.indexPesoCarga]
            ? rows[i][dataConfiguracion.indexPesoCarga].toString()
            : "",
          pesoPallets: rows[i][dataConfiguracion.indexPesoPallets]
            ? rows[i][dataConfiguracion.indexPesoPallets].toString()
            : "",
          pesoTotalCarga: rows[i][dataConfiguracion.indexPesoTotalCarga]
            ? rows[i][dataConfiguracion.indexPesoTotalCarga].toString()
            : "",
          comentarios: rows[i][dataConfiguracion.indexComentarios]
            ? rows[i][dataConfiguracion.indexComentarios].toString()
            : "",
          fechaHoraCarga: rows[i][dataConfiguracion.indexFechaHoraCarga]
            ? moment
              .utc(rows[i][dataConfiguracion.indexFechaHoraCarga])
              .format("YYYY-MM-DD HH:mm:ss")
            : "",
          dockCarga: rows[i][dataConfiguracion.indexDockCarga]
            ? rows[i][dataConfiguracion.indexDockCarga].toString()
            : "",
          codigoViaje: rows[i][dataConfiguracion.indexCodigoViaje]
            ? rows[i][dataConfiguracion.indexCodigoViaje].toString()
            : "",
        };
        dataFormat.push(row);
      }

      setDataExcel(dataFormat);

      if (dataFormat.length > 0) {
        if (!isListImport) {
          verificarCambios(fechaImport, dataFormat);
        }
        alert("Se cargaron los datos correctamente");
      }
    } catch (err) {
      console.log("procesarExcel err");
      console.log(err);
    }
  };

  const initPermisos = async () => {
    let obj = {};
    obj.idMenuPrimerNivel = INT_ID_MODULO_CARGAS;
    obj.idMenuSegundoNivel = 0;
    obj.idMenuTercerNivel = 0;
    let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);

    let permiso = obtenerPermisos[0];
    console.log(permiso);
    setPermisos(permiso);
    console.log(idTipoUsuario);
    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      setTieneAccesoAlModulo(true);
      setExportar(true);
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      if (permiso.accesoAlModulo === TRUE) {
        setTieneAccesoAlModulo(true);
        setExportar(permiso.exportar == 1);
      }
    }
  };

  useEffect(() => {
    if (excelValue !== null) {
      reset();
      procesarExcel(excelValue);
    }
  }, [excelValue]);

  useEffect(() => {
    initPermisos();
  }, []);

  useEffect(() => {
    if (tieneAccesoAlModulo === true) {
      if (location.state) {
        setLoading(true);
        setFechaImport(location.state.fecha);
        setShowListadoImport(false);
      } else {
        setNombreExcel("");
        const fileUpload = document.getElementById("fileUpload");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);

          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtenerListaImportaciones();
      }
    }
  }, [tieneAccesoAlModulo]);
  useEffect(() => {
    if (tieneAccesoAlModulo === true) {
      if (!showListadoImport) {
        setLoading(true);
        setMessage(null);
        setNombreExcel("");
        const fileUpload = document.getElementById("fileUploadListado");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);
          setIsListImport(true);
          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtener();
      } else {
        setNombreExcel("");
        setMessage(null);
        const fileUpload = document.getElementById("fileUpload");
        fileUpload.addEventListener("change", async () => {
          const resp = await readXlsxFile(fileUpload.files[0]);
          setIsListImport(false);
          setNombreExcel(fileUpload.files[0].name);
          setExcelValue(resp);
        });
        obtenerListaImportaciones();
      }
    }
  }, [showListadoImport]);

  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const handleNextPage = () => {
    if (maxRows >= descargasData.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const headersDescargas = [
    { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
    { key: "fecha", title: "Fecha", filter: false },
    { key: "codigoViaje", title: "Cod. Viaje", filter: true },
    { key: "codigoPlanta", title: "Cod. Planta", filter: false },
    { key: "planta", title: "Planta", filter: true },

    { key: "nroViaje", title: "Nro. Viaje", filter: true },
    { key: "codigoFletero", title: "Cod. Fletero", filter: true },
    { key: "codigoUnidad", title: "Cod. Unidad", filter: true },
    { key: "operadorLogistico", title: "Operador logistico", filter: true },
    { key: "chofer", title: "Chofer", filter: true },

    { key: "placa", title: "Placa" },
    { key: "tipoCamion", title: "Tipo Camion", filter: true },
    { key: "estadoCamion", title: "Estado camion", filter: true },
    { key: "prioridad", title: "Prioridad", filter: true },
    { key: "codigoOrigen", title: "Cod. Origen", filter: true },
    { key: "origen", title: "Origen", filter: true },
    { key: "codigoProducto", title: "Cod. Producto", filter: true },

    { key: "producto", title: "Producto" },
    { key: "tipoProducto", title: "Tipo Producto", filter: true },
    { key: "pallets", title: "Pallets", filter: true },
    { key: "codigoDestino", title: "Cod. Destino", filter: true },
    { key: "destino", title: "Destino", filter: true },
    {
      key: "bultos",
      title: "Bultos",
      filter: true,
    },

    { key: "hl", title: "HL", filter: true },
    { key: "pesoCarga", title: "Peso carga", filter: true },
    { key: "pesoPallets", title: "Peso Pallets", filter: true },
    { key: "pesoTotalCarga", title: "pesoTotalCarga", filter: true },
    { key: "comentarios", title: "Comentarios", filter: true },
    { key: "fechaHoraCarga", title: "Fecha hora carga", filter: true },
    { key: "dockCarga", title: "Dock carga", filter: true },
  ];

  const headersDescargasAgrupado = [
    { key: "acciones", title: "Acciones", onlyIcon: true, id: 1 },
    { key: "fechaModificacion", title: "Ult. Modificacion", filter: true },
    { key: "codigoViaje", title: "Cod. Viaje", filter: true },
    { key: "placa", title: "Placa", filter: true },
    { key: "codigoPlanta", title: "Cod. Planta", filter: true },
    { key: "planta", title: "Planta", filter: true },
    { key: "codigoOrigen", title: "Cod. Origen", filter: true },
    { key: "origen", title: "Origen", filter: true },
    { key: "codigoDestino", title: "Cod. Destino", filter: true },
    { key: "destino", title: "Destino", filter: true },
    { key: "dockCarga", title: "Dock carga", filter: true },
  ];
  const headersListado = [
    { key: "acciones", title: "Acciones", id: 1 },
    {
      key: "fechaFormat",
      title: "Fecha de datos",
      filter: true,
      filaClick: true,
    },
  ];

  const importar = () => {
    try {
      document.getElementById("fileUpload").click();
    } catch (err) {
      console.log("importarExcel err");
      console.log(err);
    }
  };

  const guardar = async () => {
    setLoading(true);

    let resp;

    console.log("entra aca");
    resp = await guardarCargas(dataExcel);

    obtenerListaImportaciones();
    if (resp) {
      if (resp.code === "SUCCESS") {
        setLoading(false);
        alert("Se guardo correctamente la informacion");
      } else {
        setLoading(false);
        alert("Sucedio un problema porfavor vuelva a intentar");
      }
    } else {
      alert("No existe ningun cambio en el excel");
    }
  };
  const enviarSMS = async () => {
    setLoading(true);
    const resp = await enviarSMSCargas(descargasData);
    console.log(resp);
    if (resp.body.sinEnviarSMS) {
      if (resp.body.sinEnviarSMS.length > 0) {
        let createMessage =
          "A las siguientes placas no se les envio sms cuando se guardaron los datos debido a que no tienen un usuario asignado: ";
        for (let i = 0; i < resp.body.sinEnviarSMS.length; i++) {
          createMessage = `${createMessage} ,  ${resp.body.sinEnviarSMS[i]}`;
        }
        setMessage(createMessage);
      }
    }
    setLoading(false);
  };
  const enviarUnSMS = async (value) => {
    setLoading(true);
    const resp = await enviarSMSCarga(value);
    console.log(resp);
    await obtener();
    alert(`Se envio el sms para la placa ${value.placa}`);
    setLoading(false);
    //console.log(value);
  };
  const modificarListado = async () => {
    setLoading(true);
    let resp;
    if (dataModificar) {
      resp = await modificarVariasCargas(dataModificar);
    }
    if (dataModificarNew) {
      resp = await guardarCargasEnRegistroExistente(dataModificarNew);
    }
    obtener();
    if (resp) {
      if (resp.code === "SUCCESS") {
        setLoading(false);

        alert("Se guardo correctamente la informacion");
      } else {
        setLoading(false);
        alert("Sucedio un problema porfavor vuelva a intentar");
      }
      setDataModificar(null);
      setDataModificarNew(null);
    } else {
      alert("No existe ningun cambio en el excel");
    }
  };
  const modificar = async (value) => {
    nextPage("cargas/administracion", value);
  };
  const eliminarDescargaId = async (value) => {
    setLoading(true);
    const resp = await eliminarCargaId({ id: value.id });
    await obtener();
    alert("se elimino el registro");
    setLoading(false);
  };
  const eliminarPlanilla = async (value) => {
    setLoading(true);
    const resp = await eliminarCargaCodigoViaje({
      codigoViaje: value.codigoViaje,
    });
    await obtener();
    alert(`se elimino el registro con el codigo viaje: ${value.codigoViaje}`);
    setLoading(false);
  };
  const agregarAccionesALista = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Eliminar",
            icon: Trash2,
            color: "#00b894",
            onClick: (param) => eliminarDescargaId(param),
          };
          acciones.push(objModificar);
          lista[i].acciones = acciones;
        }
        setDataExcel(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };
  const agregarAccionesAListaAgrupar = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            icon: Edit,
            color: "#00b894",
            onClick: (param) => modificar(param),
          };
          let objEnviarSMS = {
            label: "Enviar SMS",
            icon: Send,
            color: lista[i].enviado ? "#00b894" : "#EEDC82",
            onClick: (param) => enviarUnSMS(param),
          };
          let objEliminar = {
            label: "Eliminar",
            icon: Trash2,
            color: "#00b894",
            onClick: (param) => eliminarPlanilla(param),
          };

          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objEliminar);
            acciones.push(objEnviarSMS);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permisos.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permisos.eliminar === TRUE) {
              acciones.push(objEliminar);
            }
            if (permisos.enviarSms === TRUE) {
              acciones.push(objEnviarSMS);
            }
          }
          lista[i].acciones = acciones;
        }
        setDescargasDataAgrupado(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };
  const agregarAccionesVerALista = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objVer = {
            label: "Ver registros",
            icon: Eye,
            color: "#00b894",
            //onClick: (param) => modificar(param),
          };
          acciones.push(objVer);
          lista[i].acciones = acciones;
        }
        setListadoImport(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const filaClick = (row) => {
    setFechaImport(row.fechaRegistro);
    obtener();
    setShowListadoImport(false);
  };
  const disabledButtom = (value) => {
    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      return false;
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB && value === 1) {
      return false;
    }
    return true;
  };
  if (tieneAccesoAlModulo === false) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <div style={{ marginTop: 10 }}>
          <p>Sin Acceso Al Modulo...</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <input
        id="fileUpload"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />
      <input
        id="fileUploadListado"
        type="file"
        className="my-auto text-white p-[5px] px-[10px] mr-[10px] hidden"
      />
      {showListadoImport ? (
        <>
          <div className="flex px-8">
            <div className="w-2/4 ">
              <h1 className="font-bold text-gray-700 text-[24px]">Cargas</h1>
              <h3>Listado de importaciones</h3>
            </div>
            <div className="flex w-2/4 justify-end">
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  importar();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.cargar
                    : null
                )}
              >
                CARGAR
              </button>
              <a
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px]"
                href="https://gestion.boltrack.net/assets/plantilla-carga.xlsx"
                target="_blank"
              >
                PLANTILLA
              </a>
              <button
                className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px] disabled:bg-gray-300"
                onClick={() => {
                  guardar();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.guardar
                    : null
                )}
              >
                GUARDAR
              </button>
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div className="loader"></div>
              <div style={{ marginTop: 10 }}>
                <p>Cargando...</p>
              </div>
            </div>
          ) : (
            <>
              {nombreExcel !== "" && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`${nombreExcel} Esta listo para guardar`}</p>
                </div>
              )}

              <Grid
                rows={listadoImport}
                headers={headersListado}
                filaClick={filaClick}
                center={true}
              />

              <div>
                <button
                  className={ESTILO_BOTON_GRILLA_PREVIOUS}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <button
                  className={ESTILO_BOTON_GRILLA_NEXT}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="flex px-8">
            <div className="w-2/3 ">
              <h1 className="font-bold text-gray-700 text-[24px]">Cargas</h1>
              <h3>Listado de Cargas</h3>
            </div>
            <div className="flex w-1/3 justify-end">
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  importar();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.cargar
                    : null
                )}
              >
                CARGAR
              </button>
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  modificarListado();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.guardar
                    : null
                )}
              >
                GUARDAR
              </button>
              <button
                className="my-auto bg-[#84B044] rounded text-white p-[5px] px-[10px] mr-[10px] disabled:bg-gray-300"
                onClick={() => {
                  enviarSMS();
                }}
                disabled={disabledButtom(
                  idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB
                    ? permisos.enviarSms
                    : null
                )}
              >
                Enviar SMS
              </button>

              <button
                className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
                onClick={() => {
                  setShowListadoImport(true);
                  setDataModificar(null);
                }}
              >
                ATRAS
              </button>
            </div>
          </div>
          <div className="flex px-8 mt-[25px]">
            <input
              type="checkbox"
              className="relative  inline-flex h-6 w-6 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              checked={verDetallado}
              onChange={handleCheckboxChange}
            />
            <p className="ml-[10px]">Ver detallado</p>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div className="loader"></div>
              <div style={{ marginTop: 10 }}>
                <p>Cargando...</p>
              </div>
            </div>
          ) : (
            <>
              {nombreExcel !== "" && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`${nombreExcel} Esta listo para guardar`}</p>
                </div>
              )}
              {dataModificar && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  <p className="w-auto my-auto mr-[10px]">{`las filas en la posicion ${dataModificar.map(
                    (data) => `${data.fila + 1},`
                  )} tienen modificaciones con respecto al registro actual`}</p>
                  {dataModificarNew && (
                    <p className="w-auto my-auto mr-[10px]">{`Hay ${dataModificarNew.length} filas nuevas con respecto al registro actual`}</p>
                  )}
                </div>
              )}
              {message && (
                <div className="m-8 border-2 border-gray-600 rounded p-2">
                  {message}
                </div>
              )}
              {verDetallado ? (
                <Grid
                  rows={descargasData}
                  headers={headersDescargas}
                  exportar={exportar}
                />
              ) : (
                <Grid
                  rows={descargasDataAgrupado}
                  headers={headersDescargasAgrupado}
                  exportar={exportar}
                />
              )}
              <div>
                <button
                  className={ESTILO_BOTON_GRILLA_PREVIOUS}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <button
                  className={ESTILO_BOTON_GRILLA_NEXT}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
