import { useState, useEffect } from "react";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { MODULOS_CONFIGURACION_DISPONIBILIDAD } from "../../utils/modulos-configuracion-disponibilidad";
import Modulo from "../../components/modulo";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));

export default function ModulosDisponibilidad() {

  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_CONFIGURACION_DISPONIBILIDAD;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      // debugger;
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="flex flex-wrap">
            {MODULOS_CONFIGURACION_DISPONIBILIDAD.map((data) => Modulo(data))}
          </div>
        </div>
      );
    } catch (err) {
      console.log("ModulosDisponibilidad render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
