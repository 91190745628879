import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function Salir() {
  const history = useHistory();
  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  useEffect(() => {
    localStorage.clear("accessTokenDespacho");
    localStorage.clear("idTipoUsuarioDespacho");
    nextPage("login");
  }, []);
  return <div className="p-[2rem]"></div>;
}
