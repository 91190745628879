import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaIncidente(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/obtener-lista-incidente/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaProceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
                if (rows[i].fechaFinalizacion !== "" && rows[i].fechaFinalizacion !== null) {
                    rows[i].fechaFinalizacion = moment.parseZone(element.fechaFinalizacion).format(
                        "DD/MM/YYYY"
                    );
                }
                if (rows[i].fechaAproximadaLlegada !== "" && rows[i].fechaAproximadaLlegada !== null) {
                    rows[i].fechaAproximadaLlegada = moment.parseZone(element.fechaAproximadaLlegada).format(
                        "DD/MM/YYYY HH:mm"
                    );
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaIncidente err");
        console.log(err);
        return [];
    }
}

export async function obtenerListaIncidentePorListaPlaca(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/obtener-lista-incidente-por-lista-placa/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaIncidentePorListaPlaca err");
        console.log(err);
        return [];
    }
}

export async function obtenerGrupoGeocercasCiudadIncidente(obj) {
    try {
        let info = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/obtener-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (body[0] !== null && body[0] !== undefined) {
                info = body[0];
            }
        }
        return info;
    } catch (err) {
        console.log("obtenerGrupoGeocercasCiudadIncidente err");
        console.log(err);
        return null;
    }
}

export async function guardarGrupoGeocercasCiudadIncidente(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/guardar-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarGrupoGeocercasCiudadIncidente err");
        console.log(err);
        return false;
    }
}

export async function modificarGrupoGeocercasCiudadIncidente(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/modificar-grupo-geocercas-ciudad/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarGrupoGeocercasCiudadIncidente err");
        console.log(err);
        return false;
    }
}

export async function actualizarIncidente(obj) {
    try {
        let actualizado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/incidente/actualizar-incidente/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            actualizado = true;
        }
        return actualizado;
    } catch (err) {
        console.log("actualizarIncidente err");
        console.log(err);
        return false;
    }
}