import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import {
  obtenerHorarioCargas,
  obtenerCantidadRegistrosEnHorario,
  modificarCargasPlanilla,
} from "../../api/cargas";
import {
  obtenerListadoGeocercas,
  obtenerGeocercaConCodigoYNombre,
  obtenerGeocercaConCodigoSinGeo
} from "../../api/gestion-dts/gestion";
import moment from "moment";
import "moment-timezone";

export default function Administracion() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [listaGeocerca, setListaGeocerca] = useState([]);
  const [listaHorario, setListaHorario] = useState([]);
  const [idGeocerca, setIdGeocerca] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [indexHorarioSeleccionado, setIndexHorarioSeleccionado] = useState(-1);
  const [oldFechaHoraCarga, setOldFechaHoraCarga] = useState("");

  const cancelButtonRef = useRef(null);
  const fechaActual = new Date();
  const nextPage = (page, params) => {
    try {
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        history.push({
          pathname: `${page}`,
          state: params,
        });
      } else {
        history.push(`${page}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const obtenerHorarioCarga = async () => {
    let id;
    if (!idGeocerca) {
      const respGeo = await obtenerGeocercaConCodigoSinGeo({
        codigo: data.codigoOrigen,
      });
      //setData({ ...data, idGeocerca: respGeo.data[0].id });
      setIdGeocerca(respGeo.data[0].id);
      id = respGeo.data[0].id;
    } else {
      id = idGeocerca;
    }

    const resp = await obtenerHorarioCargas(id);
    if (Array.isArray(resp.body)) {
      const listHorarioFormat = resp.body.sort((a, b) => {
        const horaA = parseInt(a.horario.split(":").join(""));
        const horaB = parseInt(b.horario.split(":").join(""));
        return horaA - horaB;
      });
      const respCantidad = await obtenerCantidadRegistrosEnHorario(
        data.codigoOrigen,
        data.origen
      );
      const fechaActual = new Date();
      if (resp) {
        const resultado = listHorarioFormat.map((horarioData) => {
          const horario = horarioData.horario.split(":");
          const hora = parseInt(horario[0]);
          const minuto = parseInt(horario[1]);
          const objeto2 = respCantidad.body.find((obj2) => {
            //if (data) console.log(obj2.fecha);

            const fecha2 = new Date(conviertefecha(obj2.fecha));
            const fecha1 = new Date(
              conviertefecha(
                data.fechaHoraCarga !== ""
                  ? data.fechaHoraCarga
                  : moment(fechaActual).format("YYYY-MM-DD HH:mm:ss")
              )
            );
            return (
              obj2.hora === hora &&
              obj2.minuto === minuto &&
              fecha2.getDate() === fecha1.getDate()
            );
          });
          if (objeto2) {
            return { ...horarioData, ...objeto2 };
          } else {
            return { ...horarioData, ...objeto2, cantidad: 0, placas: "" };
          }
        });
        setListaHorario(resultado);
      }
    }
  };
  const modificar = async () => {
    setLoading(true);
    const resp = await modificarCargasPlanilla(data);

    if (resp.code === "SUCCESS") {
      setLoading(false);
      alert("Se modifico correctamente la configuracion");
      nextPage("/cargas", {
        showDescargas: true,
        fecha: data.fechaRegistro,
      });
    } else {
      setLoading(false);
      alert("Sucedio un problema porfavor vuelva a intentar");
    }
  };
  const obtenerGeocercas = async () => {
    const resp = await obtenerListadoGeocercas();
    const lista = resp.data.map((data) => {
      return { value: data.id, codigo: data.codigo, label: data.nombre };
    });
    setListaGeocerca(lista);
  };
  function conviertefecha(fecharecibidatexto) {
    let fec = fecharecibidatexto;
    let anio = fec.substring(0, 4);
    let mes = fec.substring(5, 7);
    let dia = fec.substring(8, 10);
    let ensamble = mes + "-" + dia + "-" + anio;
    return ensamble;
  }
  const obtenerHorario = (obj) => {
    let fecha;
    if (obj.cantidad / obj.espacios != 1) {
      if (data) {
        if (!data.fechaHoraCarga) {
          fecha = new Date();
        } else {
          const res = conviertefecha(data.fechaHoraCarga);
          fecha = new Date(res);
        }
      }
      const [hora, minuto] = obj.horario.split(":");
      fecha.setHours(hora);
      fecha.setMinutes(minuto);
      fecha.setSeconds(0);
      const fechaFormat = moment(fecha).format("YYYY-MM-DD HH:mm:ss");

      setData({ ...data, fechaHoraCarga: fechaFormat });
    } else {
      alert("Cantidad maxima alcanzada");
    }
  };
  useEffect(() => {
    setData(location.state);
    setOldFechaHoraCarga(location.state.fechaHoraCarga);
    obtenerGeocercas();
  }, []);
  useEffect(() => {
    if (data) {
      if (data.origen) {
        obtenerHorarioCarga();
      }
    }
  }, [data]);

  return (
    <>
      <div>
        <div className="flex px-8">
          <div className="w-2/3 ">
            <h1 className="font-bold text-gray-700 text-[24px] mt-[10px]">
              Modificar carga
            </h1>
            <h3>Formulario para la modificacion de los valores de carga</h3>
          </div>
          <div className="flex w-1/3 justify-end">
            <button
              className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px] mr-[10px]"
              onClick={() => {
                nextPage("/cargas", {
                  showDescargas: true,
                  fecha: data.fechaRegistro,
                });
              }}
            >
              ATRAS
            </button>
            <button
              className="my-auto bg-[#113A5F] rounded text-white p-[5px] px-[10px]"
              onClick={() => {
                modificar();
              }}
            >
              MODIFICAR
            </button>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        ) : (
          <div className="flex w-full px-8 pt-8 justify-left">
            <div className="w-1/3 mr-10">
              <div>
                <div>Codigo planta</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.codigoPlanta : ""}
                    onChange={(e) => {
                      setData({ ...data, codigoPlanta: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Planta</div>
                <div>
                  <Select
                    options={listaGeocerca}
                    isSearchable="true"
                    isClearable="true"
                    value={data ? data.planta : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        planta: e.label,
                        codigoPlanta: e.codigo,
                      });
                    }}
                    placeholder={data ? data.planta : "Seleccione una planta"}
                  />
                </div>
              </div>
              <div>
                <div>Codigo origen</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.codigoOrigen : ""}
                    onChange={(e) => {
                      setData({ ...data, codigoOrigen: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Deposito Origen</div>
                <div>
                  <Select
                    options={listaGeocerca}
                    isSearchable="true"
                    isClearable="true"
                    value={data ? data.origen : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        origen: e.label,
                        codigoOrigen: e.codigo,
                      });
                    }}
                    placeholder={
                      data ? data.origen : "Seleccione un Deposito origen"
                    }
                  />
                </div>
              </div>
              <div>
                <div>Cod. Viaje</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.codigoViaje : ""}
                    onChange={(e) => {
                      setData({ ...data, codigoViaje: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Chofer</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.chofer : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        chofer: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Codigo Unidad</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.codigoUnidad : ""}
                    onChange={(e) => {
                      setData({ ...data, codigoUnidad: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Placa</div>
                <div>
                  <input
                    type="text"
                    disabled
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.placa : ""}
                    onChange={(e) => {
                      setData({ ...data, placa: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Prioridad</div>
                <div>
                  <input
                    type="text"
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.prioridad : ""}
                    onChange={(e) => {
                      setData({ ...data, prioridad: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Comentarios</div>
                <div>
                  <input
                    type="text"
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.comentarios : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        comentarios: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Dock de carga</div>
                <div>
                  <input
                    type="text"
                    className="block w-full p-2 rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={data ? data.dockCarga : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        dockCarga: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Fecha Hora Carga</div>
                <div className="flex">
                  <input
                    type="datetime-local"
                    className="block w-full p-2 mr-[5px] rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    disabled
                    value={data ? data.fechaHoraCarga : ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        fechaHoraCarga: e.currentTarget.value,
                      });
                    }}
                  />
                  <button
                    className="bg-[#113A5F] w-[50px] text-white "
                    onClick={(e) => {
                      //e.preventDefault();
                      //obtenerHorarioDescarga();

                      setOpenModal(true);
                      //setData({ ...data, view: true });
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-full w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Seleccionar periodo de carga
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          type="date"
                          min={moment(fechaActual).format("YYYY-MM-DD")}
                          className="block w-full p-2 mr-[5px] rounded-md border-2 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={
                            data
                              ? data.fechaHoraCarga
                                ? moment(data.fechaHoraCarga).format(
                                    "YYYY-MM-DD"
                                  )
                                : moment(fechaActual).format("YYYY-MM-DD")
                              : moment(fechaActual).format("YYYY-MM-DD")
                          }
                          onChange={(e) => {
                            setData({
                              ...data,
                              fechaHoraCarga: moment(
                                e.currentTarget.value
                              ).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                        <div className="flex border-b-[1px] border-gray-400">
                          <div style={{ width: "50%" }}>Camiones Asignados</div>
                          <div style={{ width: "25%" }}>Docks</div>
                          <div style={{ width: "25%" }}>Hora</div>
                        </div>
                        <div>
                          {listaHorario.map((data, index) => (
                            <div
                              className={`flex ${
                                data.cantidad / data.espacios == 1
                                  ? "bg-red-300"
                                  : ""
                              } ${data.cantidad == 0 ? "bg-green-300" : ""} ${
                                data.cantidad / data.espacios < 1 &&
                                data.cantidad / data.espacios > 0
                                  ? "bg-yellow-300"
                                  : ""
                              } ${
                                index === indexHorarioSeleccionado
                                  ? "border-[2px] border-blue-300"
                                  : "border-b-[1px] border-gray-400"
                              } text-gray-600`}
                              onClick={() => {
                                obtenerHorario(data);
                                setIndexHorarioSeleccionado(index);
                              }}
                            >
                              <div style={{ width: "50%" }}>{data.placas}</div>
                              <div style={{ width: "25%" }}>
                                {`${data.cantidad}/${data.espacios}`}
                              </div>
                              <div style={{ width: "25%" }}>{data.horario}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModal(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => {
                        setOpenModal(false);
                        setIndexHorarioSeleccionado(-1);
                        setData({
                          ...data,
                          fechaHoraCarga: oldFechaHoraCarga,
                        });
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
