import moment from "moment/moment";
import { SUCCESS } from "../page/const/http-const";
import { getHeaders } from "./utils";
import { TRUE } from "../utils/const-bool";

export async function obtenerOpcionesRespuestaChecklist(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-opciones-respuesta-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    obj.enviarcorreo = element.enviarCorreo;
                    obj.color = element.color;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                    rows[i].enviarcorreo = rows[i].enviarCorreo;
                    rows[i].enviarcorreolabel = rows[i].enviarCorreo === null || rows[i].enviarCorreo === TRUE ? "SI" : "NO";
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerOpcionesRespuestaChecklist err");
        console.log(err);
        return [];
    }
}

export async function obtenerTipoChecklist(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-tipo-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                for (let i = 0; i < body.length; i++) {
                    body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                    body[i].entrada = body[i].entrada === null ? false : body[i].entrada;
                    body[i].salida = body[i].salida === null ? false : body[i].salida;
                    body[i].entradadescripcion = body[i].entrada === TRUE ? "SI" : "NO";
                    body[i].salidadescripcion = body[i].salida === TRUE ? "SI" : "NO";
                }
                rows = body;
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerTipoChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearTipoChekclist(obj) {
    try {
        let creado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/crear-tipo-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            creado = true;
        }
        return creado;
    } catch (err) {
        console.log("crearTipoChekclist err");
        console.log(err);
        return false;
    }
}

export async function modificarTipoChekclist(obj) {
    try {
        let modificado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/modificar-tipo-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            modificado = true;
        }
        return modificado;
    } catch (err) {
        console.log("modificarTipoChekclist err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarTipoChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/deshabilitar-tipo-checklist/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarTipoChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaTipoCarroceria(obj) {
    try {
        /**Nota este metodo carga combo y carga listar */
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerTipoChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function habilitarSubTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/habilitar-sub-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function modificarTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaSubTipoCarroceria(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-sub-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerTipoChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearSubTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-sub-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarSubTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-sub-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function modificarSubTipoCarroceria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-sub-tipo-carroceria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaCorreos() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-correos/",
            {
                headers: getHeaders({ authenticate: true }),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaCorreos err");
        console.log(err);
        return [];
    }
}

export async function crearCorreo(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-correo/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearTipoCarroceria err");
        console.log(err);
        return false;
    }
}

export async function modificarCorreo(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-correo/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarCorreo err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarCorreo(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-correo/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarCorreo err");
        console.log(err);
        return false;
    }
}

export async function obtenerConcepto(grupo) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-concepto/" + grupo,
            {
                headers: getHeaders(),
                method: "GET",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerConcepto err");
        console.log(err);
        return [];
    }
}

export async function crearCheklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearCheklist err");
        console.log(err);
        return false;
    }
}

export async function modificarCheklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearCheklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaCheklist(obj) {
    try {
        // console.log('obtenerListaCheklist');
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-lista-checklist-web/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaproceso).format(
                    "DD/MM/YYYY HH:mm"
                );
                if (element.subtipocarroceria === null) {
                    rows[i].subtipocarroceria = "";
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("crearCheklist err");
        console.log(err);
        return [];
    }
}

export async function deshabilitarCheklist(obj) {
    try {
        let deshabilito = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/deshabilitar-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            deshabilito = true;
        }
        return deshabilito;
    } catch (err) {
        console.log("deshabilitarCheklist err");
        console.log(err);
        return false;
    }
}

export async function habilitarCheklist(obj) {
    try {
        let deshabilito = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/habilitar-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            deshabilito = true;
        }
        return deshabilito;
    } catch (err) {
        console.log("deshabilitarCheklist err");
        console.log(err);
        return false;
    }
}

export async function crearOpcionRespuestaChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-opcion-respuesta/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearOpcionRespuestaChecklist err");
        console.log(err);
        return false;
    }
}

export async function modificarOpcionRespuestaChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-opcion-respuesta/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarOpcionRespuestaChecklist err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarOpcionRespuestaChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-opcion-respuesta/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarCorreo err");
        console.log(err);
        return false;
    }
}

export async function crearMovimientoChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-movimiento/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearMovimientoChecklist err");
        console.log(err);
        return false;
    }
}

export async function modificarMovimientoChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-movimiento/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarMovimientoChecklist err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarMovimientoChecklist(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-movimiento/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarMovimientoChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerOpcionesMovimientoChecklist(combo = true) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-movimiento/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (combo === true) {
                for (let i = 0; i < body.length; i++) {
                    const element = body[i];
                    let obj = {};
                    obj.value = element.id;
                    obj.label = element.descripcion;
                    obj.continuarChecklist = element.continuarChecklist;
                    rows.push(obj);
                }
            }
            if (combo === false) {
                rows = body;
                for (let i = 0; i < rows.length; i++) {
                    const element = rows[i];
                    rows[i].fechaproceso = moment(element.fechaProceso).format(
                        "DD/MM/YYYY HH:mm"
                    );
                    rows[i].continuarchecklist = element.continuarChecklist;
                    rows[i].continuarchecklistlabel = element.continuarChecklist === null || element.continuarChecklist === TRUE ? "SI" : "NO";
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerOpcionesRespuestaChecklist err");
        console.log(err);
        return [];
    }
}

export async function obtenerListaChecklistIngreso(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-lista-checklist-ingreso/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                body[i].fecha = moment.parseZone(body[i].fecha).format('DD/MM/YYYY');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaChecklistIngreso err");
        console.log(err);
        return [];
    }
}

export async function obtenerInfoExtraChecklistIngreso(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-info-extra-checklist-ingreso/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaChecklistIngreso err");
        console.log(err);
        return [];
    }
}

export async function obtenerListaChecklistSalida(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-lista-checklist-salida/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                body[i].fecha = moment.parseZone(body[i].fecha).format('DD/MM/YYYY');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaChecklistSalida err");
        console.log(err);
        return [];
    }
}

export async function obtenerInfoExtraChecklistSalida(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-info-extra-checklist-salida/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerInfoExtraChecklistSalida err");
        console.log(err);
        return [];
    }
}

export async function guardarIconoChekclist(obj) {
    try {
        let creado = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/guardar-icono-checklist/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            creado = true;
        }
        return creado;
    } catch (err) {
        console.log("guardarIconoChekclist err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaIconos() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-lista-iconos/",
            {
                headers: getHeaders({ authenticate: true }),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaproceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaIconos err");
        console.log(err);
        return [];
    }
}

export async function deshabilitarIcono(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/deshabilitar-icono/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarIcono err");
        console.log(err);
        return false;
    }
}

export async function obtenerGrupoGeocercas(obj) {
    try {
        let info = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-grupo-geocercas/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            if (body[0] !== null && body[0] !== undefined) {
                info = body[0];
            }
        }
        return info;
    } catch (err) {
        console.log("obtenerGrupoGeocercas err");
        console.log(err);
        return null;
    }
}

export async function guardarGrupoGeocercas(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/guardar-grupo-geocercas/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarGrupoGeocercas err");
        console.log(err);
        return false;
    }
}

export async function modificarGrupoGeocercas(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/modificar-grupo-geocercas/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarGrupoGeocercas err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaCorreosAsignadosAGeocerca() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-lista-correos-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "POST",
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            for (let i = 0; i < body.length; i++) {
                body[i].fechaproceso = moment(body[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                body[i].fecha = moment(body[i].fecha).format('DD/MM/YYYY');
            }
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerListaCorreosAsignadosAGeocerca err");
        console.log(err);
        return [];
    }
}

export async function guardarListaCorreosAsignadosAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/guardar-lista-correos-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("guardarListaCorreosAsignadosAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function modificarListaCorreosAsignadosAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/modificar-lista-correos-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarListaCorreosAsignadosAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarListaCorreosAsignadosAGeocerca(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/deshabilitar-lista-correos-asignados-a-geocerca/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarListaCorreosAsignadosAGeocerca err");
        console.log(err);
        return false;
    }
}

export async function obtenerListaCategoria() {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/obtener-lista-categoria/",
            {
                headers: getHeaders(),
                method: "POST",
                // body: JSON.stringify({data: 'hello'}),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                rows[i].fechaProceso = moment(element.fechaProceso).format(
                    "DD/MM/YYYY HH:mm"
                );
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerOpcionesRespuestaChecklist err");
        console.log(err);
        return [];
    }
}

export async function crearCategoria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/crear-categoria/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("crearCategoria err");
        console.log(err);
        return false;
    }
}

export async function modificarCategoria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/modificar-categoria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("modificarCategoria err");
        console.log(err);
        return false;
    }
}

export async function deshabilitarCategoria(obj) {
    try {
        let result = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/administracion/deshabilitar-categoria/",
            {
                headers: getHeaders(),
                method: "PUT",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            result = true;
        }
        return result;
    } catch (err) {
        console.log("deshabilitarCategoria err");
        console.log(err);
        return false;
    }
}

export async function obtenerConsolidadoChecklistIngreso(idEmpresa, unidad, mesi, diai, horai, mini, mesf, diaf, horaf, minf, valueGeocerca) {
    try {
        let url = "https://gestiondeflota.boltrack.net/reportes/RP502BodyV01.rep?E=" + idEmpresa + "&T=0&IMEI=" + unidad + "&mesi=" + mesi + "&diai=" + diai + "&horai=" + horai + "&mini=" + mini + "&mesf=" + mesf + "&diaf=" + diaf + "&horaf=" + horaf + "&minf=" + minf + "&vIdGeo=" + valueGeocerca;
        const response = await fetch(url);
        if (!response.ok) {
            return null;
        }
        const data = await response.json();
        // Aquí puedes hacer lo que necesites con los datos recibidos
        return data;
    } catch (err) {
        console.log("obtenerConsolidadoChecklistIngreso err");
        console.log(err);
        return null;
    }
}

export async function obtenerConsolidadoChecklistSalida(idEmpresa, unidad, mesi, diai, horai, mini, mesf, diaf, horaf, minf, valueGeocerca, idSubTipoChecklist) {
    try {
        let url = "https://gestiondeflota.boltrack.net/reportes/RP502BodyV02.rep?E=" + idEmpresa + "&T=0&IMEI=" + unidad + "&mesi=" + mesi + "&diai=" + diai + "&horai=" + horai + "&mini=" + mini + "&mesf=" + mesf + "&diaf=" + diaf + "&horaf=" + horaf + "&minf=" + minf + "&vIdGeo=" + valueGeocerca + "&vIdSubTipo=" + idSubTipoChecklist;
        const response = await fetch(url);
        if (!response.ok) {
            return null;
        }
        const data = await response.json();
        // Aquí puedes hacer lo que necesites con los datos recibidos
        return data;
    } catch (err) {
        console.log("obtenerConsolidadoChecklistSalida err");
        console.log(err);
        return null;
    }
}

export async function obtenerInfoListaProductosChecklistSalida(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-info-lista-productos-checklist-salida/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerInfoListaProductosChecklistSalida err");
        console.log(err);
        return [];
    }
}

export async function obtenerInfoListaPrecintosChecklistSalida(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/despachoapi/api/checklist/obtener-info-lista-precinto-checklist-salida/",
            {
                headers: getHeaders(),
                method: "POST",
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerInfoListaPrecintosChecklistSalida err");
        console.log(err);
        return [];
    }
}

export async function obtenerConsolidadoChecklistSalidaPrecinto(idEmpresa, unidad, mesi, diai, horai, mini, mesf, diaf, horaf, minf, valueGeocerca, idSubTipoChecklist) {
    try {
        let url = "https://gestiondeflota.boltrack.net/reportes/RP502BodyV03.rep?E=" + idEmpresa + "&T=0&IMEI=" + unidad + "&mesi=" + mesi + "&diai=" + diai + "&horai=" + horai + "&mini=" + mini + "&mesf=" + mesf + "&diaf=" + diaf + "&horaf=" + horaf + "&minf=" + minf + "&vIdGeo=" + valueGeocerca + "&vIdSubTipo=" + idSubTipoChecklist;
        const response = await fetch(url);
        if (!response.ok) {
            return null;
        }
        const data = await response.json();
        // Aquí puedes hacer lo que necesites con los datos recibidos
        return data;
    } catch (err) {
        console.log("obtenerConsolidadoChecklistSalida err");
        console.log(err);
        return null;
    }
}