import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT, ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
  ESTILO_BOTON_MODAL_CANCELAR
} from "../../utils/const-estilos";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import { deshabilitarTipoDetencionDestino, guardarTipoDetencionDestino, modificarTipoDetencionDestino, obtenerListaTipoDetencionDestino } from "../../api/configuracion/configuracion";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
let accesoBotonNuevo = 'none';


export default function GestionarTipoDetencionDestino() {
  const history = useHistory();
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "CODIGO", visible: true, },
    { key: "descripcion", title: "DESCRIPCION", visible: true, filter: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true },
  ];
  const infoTipoDetencion = {
    id: -1,
    descripcion: "",
  };
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaTipoDetencionGeoruta, setListaTipoDetencionGeoruta] = useState([]);
  const [modalDeshabilitarVisible, setModalDeshabilitarVisible] = useState(false);
  const [tipoDetencionGeorutaSelected, setTipoDetencionGeorutaSelected] = useState({ ...infoTipoDetencion });
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [modalTipoDetencionGeorutaVisible, setModalTipoDetencionGeorutaVisible] = useState(false);

  useEffect(() => {
    // console.log("entro a GestionarTipoDetencionDestino");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GEORUTA;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CONFIGURACION_GEORUTA_TIPO_DETENCION_GEORUTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      reset(permiso);

      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      console.log("agregarAccionesALista");
      console.log(permiso);
      if (lista.length === 0) {
        setListaTipoDetencionGeoruta(lista);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.nuevo === TRUE) {
          accesoBotonNuevo = '';
        }
      }
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
      }
      if (lista.length > 0) {
        let objModificar = {
          label: "Modificar",
          color: "green",
          onClick: (param) => modificarGeorutaSeleccionada(param),
        };
        let objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objDeshabilitar);
            acciones.push(objModificar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaTipoDetencionGeoruta(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setTipoDetencionGeorutaSelected(data);
      setModalDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setTipoDetencionGeorutaSelected({ ...tipoDetencionGeorutaSelected, [name]: value });
  };

  const modificarGeorutaSeleccionada = async (data) => {
    try {
      // console.log("modificarGeorutaSeleccionada");
      // console.log(data);
      setTipoDetencionGeorutaSelected(data);

      setModalTipoDetencionGeorutaVisible(true);
    } catch (err) {
      console.log("modificarGeorutaSeleccionada err");
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaTipoDetencionGeoruta.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const handleNuevoClick = () => {
    // console.log("handleNuevoClick");
    setModalTipoDetencionGeorutaVisible(true);
    setTipoDetencionGeorutaSelected({ ...infoTipoDetencion });
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleCancelModal = () => {
    setModalDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    console.log('handleConfirmModal');
    setModalDeshabilitarVisible(false);
    // console.log('georutaSelected');
    // console.log(georutaSelected);
    // return;
    if (tipoDetencionGeorutaSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = tipoDetencionGeorutaSelected.id;
    if (tipoDetencionGeorutaSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarTipoDetencionDestino(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset(permisos);
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const reset = async (permiso) => {
    setCargandoPage(true);

    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      let lista = await obtenerListaTipoDetencionDestino();
      agregarAccionesALista(lista, permiso);
      setTieneAccesoAlModulo(true);
    }
    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      if (permiso.accesoAlModulo === TRUE) {
        let lista = await obtenerListaTipoDetencionDestino();
        agregarAccionesALista(lista, permiso);
        setTieneAccesoAlModulo(true);
      }
    }
    setCargandoPage(false);
  }

  /**Modal Area */
  const onAcceptModal = async () => {
    try {
      console.log('onAcceptModal');
      console.log('tipoDetencionGeorutaSelected');
      console.log(tipoDetencionGeorutaSelected);
      let valido = validarCamposEnModal();
      if (valido === true) {
        let objEnviar = {};
        objEnviar.descripcion = tipoDetencionGeorutaSelected.descripcion;
        objEnviar.id = tipoDetencionGeorutaSelected.id;
        // console.log('objEnviar');
        // console.log(objEnviar);
        if (tipoDetencionGeorutaSelected.id === -1) {
          let guardo = await guardarTipoDetencionDestino(objEnviar);
          // console.log('guardo');
          // console.log(guardo);
          if (guardo === true) {
            alert('Se guardo correctamente');
            onCancelModal();
            reset(permisos);
          }
        }
        if (tipoDetencionGeorutaSelected.id > 0) {
          // console.log('modificar');
          let modifico = await modificarTipoDetencionDestino(objEnviar);
          // console.log('guardo');
          // console.log(guardo);
          if (modifico === true) {
            alert('Se modifico correctamente');
            onCancelModal();
            reset(permisos);
          }
        }
      }
    } catch (err) {
      console.log('onAcceptModal err');
      console.log(err);
    }
  }

  const onCancelModal = () => {
    setModalTipoDetencionGeorutaVisible(false);
  }

  const validarCamposEnModal = () => {
    try {
      if (tipoDetencionGeorutaSelected.descripcion === undefined ||
        tipoDetencionGeorutaSelected.descripcion === null ||
        String(tipoDetencionGeorutaSelected.nombre).trim().length === 0) {
        alert('Debe ingresar descripcion');
        return false;
      }
      return true;
    } catch (err) {
      console.log('validarCamposEnModal err');
      console.log(err);
      return false;
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA TIPO DETENCION DESTINO
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_NUEVO}
              onClick={handleNuevoClick}
              style={{
                display: accesoBotonNuevo,
              }}
            >
              NUEVO
            </button>
          </div>
          <Grid rows={listaTipoDetencionGeoruta} headers={headers} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfirmar
            isOpen={modalDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar?"
          />
          {modalTipoDetencionGeorutaVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              {/* background */}
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              {/* end background */}
              <div className="bg-white w-11/12 h-2/6 md:max-w-3xl lg:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col">
                <div className="pt-4 text-left px-6">
                  <div>
                    <p className="text-xl">{'TIPO DETENCION DESTINO '}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pr-2">
                        <label className="block mb-2 font-bold">DESCRIPCION</label>
                        <input
                          type="text"
                          id="descripcion"
                          name="descripcion"
                          value={tipoDetencionGeorutaSelected.descripcion}
                          onChange={handleInputChange}
                          placeholder="DESCRIPCION"
                          className="w-full p-2 border border-gray-300 rounded"
                          maxLength={1023}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between pt-20 pb-4 pr-4">
                  <button
                    className={ESTILO_BOTON_MODAL_CANCELAR}
                    onClick={onCancelModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 cursor-pointer"
                    onClick={onAcceptModal}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } catch (err) {
      console.log('index render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
