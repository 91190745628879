import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_MODULO_REPORTES,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
  INT_TIPO_USUARIO_CHOFER_MOVIL,
} from "../../utils/const-int";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import { obtenerGrupoGeocercas } from "../../api/checklist";
import { obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";
import { obtenerListaUsuariosMovil } from "../../api/usuario/usuario";
import { obtenerTiempoDescarga } from "../../api/reportes";
import { exportarDocumento, getFileName } from "../../share/shared-functions";
new momentLocalizer(moment);

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));

export default function TiempoDescarga() {
  const history = useHistory();
  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [listaGeocercaOrigen, setListaGeocercaOrigen] = useState([]);
  const [listaGeocercaDestino, setListaGeocercaDestino] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [geocercaOrigenSelected, setGeocercaOrigenSelected] = useState(null);
  const [geocercaDestinoSelected, setGeocercaDestinoSelected] = useState(null);
  const [unidadSelected, setUnidadSelected] = useState(null);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      iniciarFechas();
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_REPORTES;
      obj.idMenuSegundoNivel = 0;
      obj.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      // debugger;
      let listaGrupo = await obtenerGrupoGeocercas();
      if (listaGrupo !== null) {
        // console.log('listaGrupo');
        // console.log(listaGrupo);
        let meta = JSON.parse(listaGrupo.meta);
        let listaIdGrupo = "";
        for (let i = 0; i < meta.length; i++) {
          const element = meta[i];
          listaIdGrupo = listaIdGrupo + element + ",";
        }
        listaIdGrupo = listaIdGrupo.substring(0, listaIdGrupo.length - 1);
        // console.log('listaIdGrupo');
        // console.log(listaIdGrupo);
        let obj = {};
        obj.idGrupoGeocerca = listaIdGrupo;
        let listaGeo = await obtenerInfoGeocercaMedianteIdGrupo(obj);
        for (let i = 0; i < listaGeo.length; i++) {
          const element = listaGeo[i];
          listaGeo[i].nombreGeocerca = listaGeo[i].label;
          if (element.codigo !== undefined && element.codigo !== null) {
            listaGeo[i].label = listaGeo[i].codigo + " - " + listaGeo[i].label;
          }
        }
        setListaGeocercaOrigen(listaGeo);
        setListaGeocercaDestino(listaGeo);
      }
      let listaUsuarioMov = await obtenerListaUsuariosMovil();
      for (let i = 0; i < listaUsuarioMov.length; i++) {
        listaUsuarioMov[i].value = listaUsuarioMov[i].id;
        listaUsuarioMov[i].label =
          listaUsuarioMov[i].nombre + " - " + listaUsuarioMov[i].nombreUnidad;
      }
      listaUsuarioMov = listaUsuarioMov.filter(
        (e) => e.idtipousuario === INT_TIPO_USUARIO_CHOFER_MOVIL
      );
      setListaUnidades(listaUsuarioMov);
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }

      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const iniciarFechas = () => {
    try {
      let currentDate = moment();
      let fechaI = new Date(
        currentDate.clone().startOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      let fechaF = new Date(
        currentDate.clone().endOf("week").add(1, "day").format("YYYY-MM-DD")
      );
      setFechaInicio(fechaI);
      setFechaFin(fechaF);
    } catch (err) {
      console.log("iniciarFechas err");
      console.log(err);
      setFechaInicio(new Date());
      setFechaFin(new Date());
    }
  };

  const handleBuscar = async () => {
    try {
      // console.log('handleBuscar');
      setCargandoPage(true);
      // let objEnviar = {};
      // objEnviar.fechaHoraIni = moment(fechaInicio).format('YYYY-MM-DD');
      // objEnviar.fechaHoraFin = moment(fechaFin).format('YYYY-MM-DD');
      // objEnviar.idGeocercaOrigen = geocercaOrigenSelected.value;
      // objEnviar.idGeocercaDestino = geocercaDestinoSelected.value;
      // objEnviar.unidad = unidadSelected.value;
      // console.log(objEnviar);
      const idEmpresa = localStorage.getItem("idEmpresaDespacho");
      const mesi = moment(fechaInicio).format("YYMM");
      const diai = moment(fechaInicio).format("DD");
      const horai = "00";
      const mini = "00";
      const mesf = moment(fechaFin).format("YYMM");
      const diaf = moment(fechaFin).format("DD");
      const horaf = "23";
      const minf = "59";
      let unidad = "";
      if (unidadSelected !== null) {
        unidad = unidadSelected.value;
      }
      let codigoGeoOrigen = "";
      let nombreGeoOrigen = "";

      if (geocercaOrigenSelected !== null) {
        codigoGeoOrigen = geocercaOrigenSelected.codigo;
        nombreGeoOrigen = geocercaOrigenSelected.nombreGeocerca;
      }
      let codigoGeoDestino = "";
      let nombreGeoDestino = "";
      if (geocercaDestinoSelected !== null) {
        codigoGeoDestino = geocercaDestinoSelected.codigo;
        nombreGeoDestino = geocercaDestinoSelected.nombreGeocerca;
      }

      const response = await obtenerTiempoDescarga(
        idEmpresa,
        unidad,
        mesi,
        diai,
        horai,
        mini,
        mesf,
        diaf,
        horaf,
        minf,
        codigoGeoOrigen,
        nombreGeoOrigen,
        codigoGeoDestino,
        nombreGeoDestino
      );
      console.log("response");
      console.log(response);
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data !== ""
      ) {
        //exportar reporte
        const fileName = getFileName(response.data);
        console.log("fileName");
        console.log(fileName);
        exportarDocumento(fileName);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("handleBuscar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const handleGeocercaOrigen = async (event) => {
    try {
      setGeocercaOrigenSelected(event);
    } catch (err) {
      console.log("handleGeocercaOrigen err");
      console.log(err);
    }
  };

  const handleGeocercaDestino = async (event) => {
    try {
      setGeocercaDestinoSelected(event);
    } catch (err) {
      console.log("handleGeocercaDestino err");
      console.log(err);
    }
  };

  const handleUnidad = async (event) => {
    try {
      setUnidadSelected(event);
    } catch (err) {
      console.log("handleUnidad err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div className="m-5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              REPORTE TIEMPO DE ESPERA DESCARGA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-row justify-start">
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha inicio" className="mb-2 font-bold">
                  FECHA INICIO
                </label>
                <DatePicker
                  defaultValue={fechaInicio}
                  value={fechaInicio}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaInicio(date)}
                />
              </div>
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha fin" className="mb-2 font-bold">
                  FECHA FIN
                </label>
                <DatePicker
                  defaultValue={fechaFin}
                  value={fechaFin}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaFin(date)}
                />
              </div>
            </div>
            <div className="w-full sm:w-auto sm:flex-shrink-0">
              <button
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscar}
              >
                BUSCAR
              </button>
            </div>
          </div>
          <div style={{}}>
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                <label htmlFor="geocercaorigen" className="mb-2 font-bold">
                  PLANTA/GEOCERCA ORIGEN
                </label>
                <Select
                  options={listaGeocercaOrigen}
                  isSearchable="true"
                  isClearable="true"
                  value={geocercaOrigenSelected}
                  onChange={(event) => handleGeocercaOrigen(event)}
                  placeholder="SELECCIONE GEOCERCA"
                />
              </div>
              <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                <label htmlFor="geocercadestino" className="mb-2 font-bold">
                  PLANTA/GEOCERCA DESTINO
                </label>
                <Select
                  options={listaGeocercaDestino}
                  isSearchable="true"
                  isClearable="true"
                  value={geocercaDestinoSelected}
                  onChange={(event) => handleGeocercaDestino(event)}
                  placeholder="SELECCIONE GEOCERCA"
                />
              </div>
              <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                <label htmlFor="unidad" className="mb-2 font-bold">
                  UNIDAD
                </label>
                <Select
                  options={listaUnidades}
                  isSearchable="true"
                  isClearable="true"
                  value={unidadSelected}
                  onChange={(event) => handleUnidad(event)}
                  placeholder="SELECCIONE UNIDAD"
                />
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log("TiempoDescarga render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
