import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import {
  obtenerGrupoGeocercas,
} from "../../api/checklist";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CONFIGURACION,
  INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL,
  INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";
import Select from "react-select";
import ModalConfirmar from "../../components/modal-confirmar";
import { obtenerListaUsuariosWeb } from "../../api/usuario/usuario";
import {
  deshabilitarListaUsuariosAsignadosAGeocerca,
  guardarUsuariosAsignadoAGeocerca,
  modificarListaUsuariosAsignadosAGeocerca,
  obtenerListaUsuariosAsignadosAGeocerca,
} from "../../api/configuracion/configuracion";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
let accesoBotonNuevo = '';

export default function AsignarGeocercaUsuario() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "idusuarioweb", title: "CODIGO", visible: false, },
    { key: "nombre", title: "NOMBRE", visible: true, filter: true, },
    { key: "geocercas", title: "GEOCERCAS", visible: true, filter: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", visible: true, filter: true },
  ];
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaUsuariosAGeocerca, setListaUsuariosAGeocerca] = useState([]);
  const [filaSelected, setFilaSelected] = useState(null);
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercasSelected, setGeocercasSelected] = useState(null);
  const [listaUsuariosWeb, setListaUsuariosWeb] = useState([]);
  const [usuariosWebSelected, setUsuariosWebSelected] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalModificarVisible, setModalModificarVisible] = useState(false);
  const [modalDeshabilitarVisible, setModalDeshabilitarVisible] = useState(false);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CONFIGURACION;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_CONFIGURACION_GENERAL;
      objPermiso.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CONFIGURACION_GENERAL_ASIGNAR_PLANTA_A_USUARIO;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);

      reset(permiso);

      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaUsuariosAGeocerca(lista);
      }
      if (lista.length > 0) {
        let objDeshabilitar = {
          label: "DESHABILITAR",
          color: "red",
          onClick: (param) => deshabilitarAsignacion(param),
        };
        let objModificar = {
          label: "MODIFICAR",
          color: "green",
          onClick: (param) => modificarAsignacion(param),
        };
        if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
          if (permiso.nuevo === TRUE) {
            accesoBotonNuevo = '';
          }
        }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.modificar === TRUE) {
              acciones.push(objModificar);
            }
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
          }
          lista[i].acciones = acciones;

          lista[i].geocercas = JSON.parse(lista[i].geocercas);
        }
        setListaUsuariosAGeocerca(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const deshabilitarAsignacion = (data) => {
    setFilaSelected(data);
    setModalDeshabilitarVisible(true);
  }

  const handleNextPage = () => {
    if (maxRows >= listaUsuariosAGeocerca.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const modificarAsignacion = (data) => {
    try {
      console.log("modificarAsignacion");
      console.log(data);
      // setGeocercasSelected({ label: data.nombregeocerca, value: data.idgeocerca });
      // setUsuariosWebSelected(data.usuarios);
      setGeocercasSelected(data.geocercas);
      setUsuariosWebSelected({ label: data.nombre, value: data.id });
      setFilaSelected(data);
      setModalModificarVisible(true);
    } catch (err) {
      console.log("modificarAsignacion err");
      console.log(err);
    }
  };

  const reset = async (permiso) => {
    try {
      setCargandoPage(true);
      let lista = [];
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        accesoBotonNuevo = '';
        lista = await procesarParaPermiso();
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          lista = await procesarParaPermiso();
          setTieneAccesoAlModulo(true);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('reset err');
      console.log(err);
      setListaUsuariosAGeocerca([]);
      setCargandoPage(false);
    }
  }

  const procesarParaPermiso = async () => {
    try {
      let lista = [];
      let usuariosWeb = await obtenerListaUsuariosWeb();
      // console.log('usuariosWeb');
      // console.log(usuariosWeb);
      let usuarios = [];
      for (let i = 0; i < usuariosWeb.length; i++) {
        const element = usuariosWeb[i];
        let obj = {};
        obj.value = element.id;
        obj.label = element.nombre;
        usuarios.push(obj);
      }
      setListaUsuariosWeb(usuarios);
      lista = await obtenerListaUsuariosAsignadosAGeocerca();
      // console.log('lista');
      // console.log(lista);
      let listaGrupo = await obtenerGrupoGeocercas();
      if (listaGrupo !== null) {
        let meta = JSON.parse(listaGrupo.meta);
        let listaIdGrupo = "";
        for (let i = 0; i < meta.length; i++) {
          const element = meta[i];
          listaIdGrupo = listaIdGrupo + element + ",";
        }
        listaIdGrupo = listaIdGrupo.substring(0, listaIdGrupo.length - 1);
        let obj = {};
        obj.idGrupoGeocerca = listaIdGrupo;
        let listaGeo = await obtenerInfoGeocercaMedianteIdGrupo(obj);
        // console.log('listaGeo');
        // console.log(listaGeo);
        setListaGeocercas(listaGeo);
      }
      return lista;
    } catch (err) {
      console.log('procesarParaPermiso err');
      console.log(err);
      return [];
    }
  }

  const handleNuevoClick = () => {
    console.log("handleNuevoClick");
    setGeocercasSelected(null);
    setUsuariosWebSelected(null);
    setModalVisible(true);
  };

  const handleOptionSeleccionarGeocerca = (event) => {
    setGeocercasSelected(event);
  };

  const handleOptionSeleccionarUsuarios = (event) => {
    setUsuariosWebSelected(event);
  };

  const handleModalButtonCancel = () => {
    setModalVisible(false)
  };

  const handleModalButtonAceptar = async () => {
    if (geocercasSelected === null) {
      alert('Debe seleccionar geocerca');
      return;
    }
    if (usuariosWebSelected === null) {
      alert('Debe seleccionar usuarios');
      return;
    }
    let objEnviar = {};
    objEnviar.geocercas = geocercasSelected;
    objEnviar.idUsuarioWeb = usuariosWebSelected.value;
    let guardo = await guardarUsuariosAsignadoAGeocerca(objEnviar);
    // console.log(guardo);
    if (guardo === true) {
      alert('Se creo correctamente');
      reset(permisos);
      setModalVisible(false);
    }
    if (guardo === false) {
      alert('No se pudo guardar, intente nuevamente');
    }
  };

  const handleModalModificarButtonCancel = () => {
    setModalModificarVisible(false)
  };

  const handleModalModificarButtonAceptar = async () => {
    if (geocercasSelected === null) {
      alert('Debe seleccionar correos');
      return;
    }
    let objEnviar = {};
    objEnviar.geocercas = geocercasSelected;
    objEnviar.id = filaSelected.id;
    const modifico = await modificarListaUsuariosAsignadosAGeocerca(objEnviar);
    if (modifico === true) {
      alert('Se modifico correctamente');
      reset(permisos);
      setModalModificarVisible(false);
    }
    if (modifico === false) {
      alert('No se pudo modificar, intente nuevamente');
    }
  }

  const handleCancelDeshabilitarModal = () => {
    setModalDeshabilitarVisible(false);
  }

  const handleConfirmDeshabilitarModal = async () => {
    let objEnviar = {};
    objEnviar.id = filaSelected.id;
    const deshabilitado = await deshabilitarListaUsuariosAsignadosAGeocerca(objEnviar);
    // console.log('deshabilitado');
    // console.log(deshabilitado);
    if (deshabilitado === true) {
      alert('Se deshabilito correctamente');
      reset(permisos);
      setModalDeshabilitarVisible(false);
    }
    if (deshabilitado === false) {
      alert('No se pudo deshabilitar, intente nuevamente');
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >

            </label>
            <button className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA USUARIOS ASIGNADOS A GEOCERCA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_NUEVO}
              onClick={handleNuevoClick}
              style={{
                display: accesoBotonNuevo,
              }}
            >
              NUEVO
            </button>
          </div>
          <Grid rows={listaUsuariosAGeocerca} headers={headers} />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          {modalVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              <div className="bg-white w-11/12 h-2/4 md:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col justify-between">
                <div className="py-4 text-left px-6">
                  <div className="mb-4">
                    <p className="text-xl">{'AGREGAR USUARIOS A GEOCERCAS'}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pl-2 mr-10">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR USUARIOS</label>
                          <Select options={listaUsuariosWeb} isSearchable='true'
                            isClearable='true'
                            value={usuariosWebSelected}
                            onChange={(event) => handleOptionSeleccionarUsuarios(event)}
                            placeholder='Seleccione Usuario'
                          />
                        </div>
                      </div>
                      <div className="w-1/2 pr-2">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR GEOCERCAS</label>
                          <Select options={listaGeocercas} isSearchable='true'
                            isMulti
                            isClearable='true'
                            value={geocercasSelected}
                            onChange={(event) => handleOptionSeleccionarGeocerca(event)}
                            placeholder='Seleccione Geocerca'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 pb-4 pr-4">
                  <button
                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalButtonCancel()}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalButtonAceptar()}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          )}
          {modalModificarVisible && (
            <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
              <div className="bg-white w-11/12 h-2/4 md:max-w-3xl mx-auto rounded shadow-lg z-50 overflow-y-auto flex flex-col justify-between">
                <div className="py-4 text-left px-6">
                  <div className="mb-4">
                    <p className="text-xl">{'MODIFICAR USUARIOS A GEOCERCAS'}</p>
                    <div className="flex mt-4">
                      <div className="w-1/2 pl-2">
                        <div>
                          <label className="block mb-2 font-bold">NOMBRE USUARIO</label>
                          <input
                            type="text"
                            value={usuariosWebSelected.label}
                            className="p-2 border border-gray-300 rounded"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="w-1/2 pr-2">
                        <div>
                          <label className="block mb-2 font-bold">SELECCIONAR GEOCERCAS</label>
                          <Select options={listaUsuariosWeb} isSearchable='true'
                            isMulti
                            isClearable='true'
                            value={geocercasSelected}
                            onChange={(event) => handleOptionSeleccionarGeocerca(event)}
                            placeholder='Seleccione Tipo Carroceria'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 pb-4 pr-4">
                  <button
                    className="mr-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalModificarButtonCancel()}
                  >
                    Cancelar
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => handleModalModificarButtonAceptar()}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          )}
          <ModalConfirmar
            isOpen={modalDeshabilitarVisible}
            onCancel={handleCancelDeshabilitarModal}
            onConfirm={handleConfirmDeshabilitarModal}
            message="¿Confirma deshabilitar?"
          />
        </div>
      );
    } catch (err) {
      console.log('AsignarGeocercaUsuario render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
