export const capitalize = (str) => {
    try {
        return str.toUpperCase();
    } catch (err) {
        console.error(err);
        return str;
    }
};

export function exportarDocumento(nombreArchivo) {
    try {
        // console.log(nombreArchivo);
        const link = document.createElement('a');
        link.href = 'https://gestiondeflota.boltrack.net/assets/reportes/' + nombreArchivo;
        link.target = '_blank';
        link.setAttribute(
            'download',
            nombreArchivo,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    } catch (err) {
        console.log('exportarDocumento err');
        console.log(err);
    }
}

export function getFileName(filePath) {
    const fileNameWithExtension = filePath.split('/').pop();
    return fileNameWithExtension;
}

export function obtenerIcono(vH, isA, eEstado, eVelocidad, idIcon) {
    try {
        let ico = obtenerUrlIcon(
            vH,
            isA,
            eEstado,
            eVelocidad,
            idIcon,
        );
        return ico;
    } catch (err) {
        console.log('obtenerIcono err');
        return null;
    }
}

function obtenerUrlIcon(vH, isA, eEstado, eVelocidad, id_icono) {
    try {
        let esFlecha = isA;
        let motorEncendido = eEstado === 1;
        let tieneVelocidad = eVelocidad > 0.1;
        let angulo = (((vH + 22.5 > 360 ? vH + 22.5 - 360 : vH) / 45) | 0) * 45;
        if (esFlecha) id_icono = 0;
        let id_color_flecha = 1;
        if (!motorEncendido) id_color_flecha = 2;
        if (motorEncendido && !tieneVelocidad) id_color_flecha = 3;
        return `https://gestion.boltrack.net/assets/icons/${id_icono}_${angulo}_${id_color_flecha}.png`;
    } catch (err) {
        console.log('obtenerUrlIcon err');
        console.log(err);
        return null;
    }
}

export function sumTimeStrings(timeStrings) {
    try {
        let totalHours = 0;
        let totalMinutes = 0;

        timeStrings.forEach(time => {
            const [hours, minutes] = time.split(':').map(Number);
            totalHours += hours;
            totalMinutes += minutes;
        });

        // Convert excess minutes to hours
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;

        // Format the result
        const formattedResult = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}`;

        return formattedResult;
    } catch (err) {
        console.log('sumTimeStrings err');
        console.log(err);
        return null;
    }
}

/** Metodo que comprar que todos los objetos existan */
export function compareHeaders(headerOnFile, headerOnDB) {
    try {
        let headerAux = [];
        //Verificando que los headers del archivo
        // debugger;
        for (let i = 0; i < headerOnFile.length; i++) {
            const element = headerOnFile[i];
            const existe = headerOnDB.find(e => e.key === element.key);
            if (existe === undefined) {
                headerAux.push(element);
            }
        }
        let headerAuxDB = [];
        for (let i = 0; i < headerOnDB.length; i++) {
            const element = headerOnDB[i];
            const existe = headerOnFile.find(e => e.key === element.key);
            if (existe !== undefined) {
                headerAuxDB.push(element);
            }
        }
        headerAux.forEach(element => {
            headerAuxDB.push(element);
        });
        // console.log("headerAuxDB");
        // console.log(headerAuxDB);
        // debugger;
        let aux = [];
        headerAuxDB.forEach(element => {
            const objHeader = headerOnFile.find(e => e.key === element.key);
            const objNew = combiarDosObjetos(element, objHeader);
            aux.push(objNew);
        });
        // console.log("aux");
        // console.log(aux);
        return aux;
    } catch (err) {
        console.log("compareHeaders err");
        console.log(err);
        return headerOnFile;
    }
}

function combiarDosObjetos(objetoA, objetoB) {
    // Combinar los atributos de objetoA y objetoB en un nuevo objeto objetoC
    let objetoC = {};

    // Agregar los atributos de objetoA a objetoC
    for (let key in objetoA) {
        if (!objetoC.hasOwnProperty(key)) {
            objetoC[key] = objetoA[key];
        }
    }

    // Agregar los atributos de objetoB a objetoC, evitando duplicados
    for (let key in objetoB) {
        if (!objetoC.hasOwnProperty(key)) {
            objetoC[key] = objetoB[key];
        }
    }

    // console.log(objetoC);
    return objetoC;
}

export function addAttribute(array, attribName, attribValue) {
    return array.map((item) => ({
        ...item,
        [attribName]: attribValue,
    }));
};

export function addAttributeById(array, id, attributeName, attributeValue) {
    return array.map((item) =>
        item.id === id ? { ...item, [attributeName]: attributeValue } : item
    );
}

export function sleepFunction(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function sharedGetIdEmpresa() {
    return localStorage.getItem("idEmpresaDespacho");
}