import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import {
  ESTILO_BOTON_GRILLA_NEXT,
  ESTILO_BOTON_GRILLA_PREVIOUS,
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import {
  obtenerListaChecklistSalida,
} from "../../api/checklist";
import "../../style/style-cargando.css";
import { obtenerInfoGeocerca } from "../../api/gestion-dts/gestion";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import { TRUE } from "../../utils/const-bool";
import { Settings } from "feather-icons-react/build/IconComponents";
import ModalConfiguracionGrilla from "../../components/modal-configuracion-grilla";
import { LISTAR_CHECKLIST_SALIDA } from "../../utils/const-screen-name";
import {
  actualizarAlignGrilla,
  actualizarFontSizeGrilla,
  actualizarHeadersGestionarDisponibilidadScreen, actualizarOrdenGestionarDisponibilidadScreen,
  guardarConfiguracionScreen, obtenerConfiguracionScreen,
} from "../../api/configuracion-screen/configuracion-screen";
import { addAttribute, compareHeaders } from "../../share/shared-functions";
import { STRING_CENTER, } from "../../utils/const-string";
import { INT_FONT_SIZE } from "../../utils/const-int";
import Select from "react-select";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import { EMPRESA_CBN, EMPRESA_PLASTICO_USR } from "../../utils/const-id-empresa";
import { SUBTIPO_CHECKLIST_SALIDA_CBN } from "../../utils/const-subtipo-checklist-salida";
new momentLocalizer(moment);

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
const idEmpresa = parseInt(localStorage.getItem("idEmpresaDespacho"));

export default function ListarChecklistSalida() {
  const headers = [
    { key: "acciones", title: "ACCIONES", visible: true, },
    { key: "id", title: "CODIGO", visible: true, filter: true, },
    { key: "fecha", title: "FECHA", visible: true, filter: true, },
    { key: "idmovimiento", title: "ID MOVIMIENTO", visible: false, filter: true, },
    { key: "movimiento", title: "MOVIMIENTO", visible: true, filter: true, },
    { key: "idplantaingreso", title: "ID PLANTA ORIGEN", visible: false, filter: true, },
    { key: "nombreplantaingreso", title: "PLANTA/CD ORIGEN", visible: true, filter: true, },
    { key: "otrocomplementoorigen", title: "OTRO PLANTA/CD ORIGEN", visible: true, filter: true, },
    { key: "unidad", title: "UNIDAD", visible: true, filter: true, },
    { key: "transportadora", title: "TRANSPORTADORA/SINDICATO", visible: true, filter: true, },
    { key: "numerointerplanta", title: "NUMERO INTERPLANTA", visible: true, filter: true, },
    { key: "idplantadestino", title: "ID PLANTA DESTINO", visible: false, filter: true, },
    { key: "nombreplantadestino", title: "PLANTA/CD DESTINO", visible: true, filter: true, },
    { key: "otrocomplementodestino", title: "OTRO PLANTA/CD DESTINO", visible: true, filter: true, },
    { key: "idchecklist", title: "ID CHECKLIST", visible: false, filter: true, },
    { key: "nombrechecklist", title: "CHECKLIST", visible: true, filter: true, },
    { key: "respuestas", title: "RESPUESTAS", visible: false, filter: true, },
    { key: "nombrechofer", title: "NOMBRE CHOFER", visible: true, filter: true, },
    { key: "numerocelularchofer", title: "NUMERO CELULAR CHOFER", visible: true, filter: true, },
    { key: "nombreresponsable", title: "NOMBRE RESPONSABLE", visible: true, filter: true, },
    { key: "fechaproceso", title: "FECHA PROCESO", filter: true, visible: true, },
    { key: "idSubTipoChecklistSalida", title: "idSubTipoChecklistSalida", filter: true, visible: false, },
  ];
  const history = useHistory();
  const [minRows, setMinRows] = useState(0);
  const [maxRows, setMaxRows] = useState(5);
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaChecklistSalida, setListaChecklistSalida] = useState([]);
  const listaSubTipoSalida = SUBTIPO_CHECKLIST_SALIDA_CBN;
  const [subTipoChecklistSelected, setSubTipoChecklistSelected] = useState(null);
  const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);

  const [fechaInicio, setFechaInicio] = useState(new Date(moment().clone().startOf("week").format("YYYY-MM-DD")));
  const [fechaFin, setFechaFin] = useState(new Date(moment().clone().endOf("week").format("YYYY-MM-DD")));

  const [exportar, setExportar] = useState(true);
  const [modalConfiguracionVisible, setModalConfiguracionVisible] = useState(false);
  const [configuracionOrden, setConfiguracionOrden] = useState(null);
  const [ascendente, setAscendente] = useState(false);
  const [descendente, setDescendente] = useState(false);
  const [gridHeaders, setGridHeaders] = useState(headers);
  const [fontSizeValue, setFontSizeValue] = useState(INT_FONT_SIZE);
  const [alignValue, setAlignValue] = useState(STRING_CENTER);

  useEffect(() => {
    // console.log("entro a listar correo");
    // iniciarFechas();
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      let objPermiso = {};
      objPermiso.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      objPermiso.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_RESPUESTA_SALIDA_CHECKLIST;
      objPermiso.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(objPermiso);
      let permiso = null;
      if (obtenerPermisos.length > 0) {
        permiso = obtenerPermisos[0];
        setPermisos(permiso);
      }

      const configuracionScreenInfo = await obtenerConfiguracionScreen({ nombre: LISTAR_CHECKLIST_SALIDA });
      // console.log('configuracionScreenInfo');
      // console.log(configuracionScreenInfo);
      let objConfiguracionEnviar = {};
      objConfiguracionEnviar.descendente = true;
      objConfiguracionEnviar.ascendente = false;
      if (configuracionScreenInfo !== null) {
        const configuracionOrden = JSON.parse(configuracionScreenInfo.orden);
        // console.log('configuracionOrden');
        // console.log(configuracionOrden);
        objConfiguracionEnviar.descendente = configuracionOrden.descendente;
        objConfiguracionEnviar.ascendente = configuracionOrden.ascendente;
        setAscendente(configuracionOrden.ascendente);
        setDescendente(configuracionOrden.descendente);
        let configuracionHeaders = null;
        if (configuracionScreenInfo.headers !== null) {
          configuracionHeaders = JSON.parse(configuracionScreenInfo.headers);
          // console.log('configuracionHeaders');
          // console.log(configuracionHeaders);
          const headerAux = compareHeaders(headers, configuracionHeaders);
          setGridHeaders(headerAux);
        }
        if (configuracionScreenInfo.fontSize !== null) {
          setFontSizeValue(configuracionScreenInfo.fontSize);
        }
        if (configuracionScreenInfo.align !== null) {
          setAlignValue(configuracionScreenInfo.align);
          const newHeader = addAttribute(gridHeaders, 'align', configuracionScreenInfo.align);
          setGridHeaders(newHeader);
        }
      }
      setConfiguracionOrden(configuracionScreenInfo);
      // console.log("fechaInicio");
      // console.log(fechaInicio);
      // console.log("fechaFin");
      // console.log(fechaFin);
      objConfiguracionEnviar.fechaInicio = moment(fechaInicio).format('YYYY-MM-DD');
      objConfiguracionEnviar.fechaFin = moment(fechaFin).format('YYYY-MM-DD');
      objConfiguracionEnviar.subTipoChecklist = subTipoChecklistSelected !== null ? subTipoChecklistSelected.value : null;
      let lista = await obtenerListaChecklistSalida(objConfiguracionEnviar);
      // console.log("lista");
      // console.log(lista);
      /**PLANTA INGRESO */
      let idsArrIngreso = [];
      let idsIngreso = "";
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        if (idsArrIngreso.indexOf(element.idplantaorigen) === -1) {
          idsIngreso = idsIngreso + element.idplantaorigen + ",";
          idsArrIngreso.push(element.idplantaorigen);
        }
      }
      idsIngreso = idsIngreso.substring(0, idsIngreso.length - 1);
      let obj = {};
      obj.idGeocercas = idsIngreso;
      let infoGeocercas = await obtenerInfoGeocerca(obj);
      for (let j = 0; j < lista.length; j++) {
        let nombrePlanta = infoGeocercas.find(e => e.id === lista[j].idplantadestino);
        if (nombrePlanta !== undefined) {
          lista[j].nombreplantaingreso = nombrePlanta.nombre;
        }
      }
      /**PLANTA SALIDA */
      let idsArrSalida = [];
      let idsSalida = "";
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        if (idsArrSalida.indexOf(element.idplantadestino) === -1) {
          idsSalida = idsSalida + element.idplantadestino + ",";
          idsArrSalida.push(element.idplantadestino);
        }
      }
      idsSalida = idsSalida.substring(0, idsSalida.length - 1);
      let objSalida = {};
      objSalida.idGeocercas = idsSalida;
      let infoGeocercasSalida = await obtenerInfoGeocerca(objSalida);
      for (let j = 0; j < lista.length; j++) {
        let nombrePlanta = infoGeocercasSalida.find(e => e.id === lista[j].idplantadestino);
        if (nombrePlanta !== undefined) {
          lista[j].nombreplantadestino = nombrePlanta.nombre;
        }
      }
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }
      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaChecklistSalida(lista);
      }
      if (lista.length > 0) {
        let objVer = {
          label: "VER",
          color: "green",
          onClick: (param) => verChecklist(param),
        };

        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objVer);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.ver === TRUE) {
              acciones.push(objVer);
            }
          }
          lista[i].acciones = acciones;
        }
        setListaChecklistSalida(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const nextPage = (page, params) => {
    try {
      // history.push(`${page}`);
      // console.log('params');
      // console.log(params);
      if (params !== undefined && params !== null) {
        if (params.acciones !== undefined) {
          delete params.acciones;
        }
        // console.log('params sin acciones');
        // console.log(params);
        history.push({
          pathname: `${page}`,
          state: params
        });
      } else {
        history.push(`${page}`);
      }
    } catch (err) {
      console.log('nextPage err');
      console.log(err);
    }
  };

  const handleNextPage = () => {
    if (maxRows >= listaChecklistSalida.length) return;
    setMinRows(maxRows);
    setMaxRows(maxRows + 5);
  };

  const handlePrevPage = () => {
    if (minRows <= 0) return;
    setMaxRows(minRows);
    setMinRows(Math.max(minRows - 5, 0));
  };

  const verChecklist = (data) => {
    try {
      console.log("verChecklist");
      console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "VER CHECKLIST SALIDA";
      obj.params = data;
      nextPage("ver-checklist-salida", obj);
    } catch (err) {
      console.log("verChecklist err");
      console.log(err);
    }
  };

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  }

  const onHideModalConfiguracion = () => {
    setModalConfiguracionVisible(false);
  }

  const onClickAcceptarOrdenGrillaModalConfiguracion = async () => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_CHECKLIST_SALIDA;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarOrdenGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarOrdenGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarHeadersGrillaModalConfiguracion = async () => {
    try {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion');
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_CHECKLIST_SALIDA;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarHeadersGestionarDisponibilidadScreen(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarHeadersGrillaModalConfiguracion err');
      console.log(err);
    }
  }

  const openModalCondiguracion = () => {
    setModalConfiguracionVisible(true);
  }

  const onClickAcceptarFontSizeModalConfiguracion = async (fontSizeValue) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_CHECKLIST_SALIDA;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      // console.log('configuracionOrden');
      // console.log(configuracionOrden);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarFontSizeGrilla(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarFontSizeModalConfiguracion err');
      console.log(err);
    }
  }

  const onClickAcceptarAlignModalConfiguracion = async (align) => {
    try {
      let objConfiguracionEnviar = {};
      let objOrden = {};
      objOrden.descendente = descendente;
      objOrden.ascendente = ascendente;
      objConfiguracionEnviar.nombre = LISTAR_CHECKLIST_SALIDA;
      objConfiguracionEnviar.orden = objOrden;
      objConfiguracionEnviar.headers = gridHeaders;
      objConfiguracionEnviar.fontSize = fontSizeValue;
      objConfiguracionEnviar.align = align;
      // console.log('objConfiguracionEnviar');
      // console.log(objConfiguracionEnviar);
      // return;
      if (configuracionOrden === null) {
        const guardo = await guardarConfiguracionScreen(objConfiguracionEnviar);
        if (guardo === true) {
          reset();
          alert("Se guardo correctamente");
          onHideModalConfiguracion();
        }
        if (guardo === false) {
          alert("No se pudo realizar operacion");
        }
      }
      if (configuracionOrden !== null) {
        objConfiguracionEnviar.id = configuracionOrden.id;
        const actualizo = await actualizarAlignGrilla(objConfiguracionEnviar);
        if (actualizo === true) {
          reset();
          alert("Se actualizo correctamente");
          onHideModalConfiguracion();
        }
        if (actualizo === false) {
          alert("No se pudo realizar operacion");
        }
      }
    } catch (err) {
      console.log('onClickAcceptarAlignModalConfiguracion err');
      console.log(err);
    }
  }

  const handleBuscar = async () => {
    try {
      console.log("handleBuscar");
      reset();
    } catch (err) {
      console.log("handleBuscar err");
      console.log(err);
    }
  };

  const handleSubTipoChecklistSelected = async (event) => {
    try {
      setSubTipoChecklistSelected(event);
    } catch (err) {
      console.log('handleSubTipoChecklistSelected err');
      console.log(err);
      setSubTipoChecklistSelected(null);
    }
  }

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div
              style={{ marginTop: 10, }}
            >
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 0,
              paddingBottom: 5,
              paddingRight: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA CHECKLIST SALIDA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={openModalCondiguracion}
            >
              <Settings />
            </button>
          </div>
          <div className="flex justify-between items-center mt-0 pl-5 pr-5">
            <div className="flex flex-row justify-start">
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha inicio" className="mb-2 font-bold">
                  FECHA INICIO
                </label>
                <DatePicker
                  defaultValue={fechaInicio}
                  value={fechaInicio}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaInicio(date)}
                />
              </div>
              <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                <label htmlFor="fecha fin" className="mb-2 font-bold">
                  FECHA FIN
                </label>
                <DatePicker
                  defaultValue={fechaFin}
                  value={fechaFin}
                  valueFormat={{ dateStyle: "medium" }}
                  onChange={(date) => setFechaFin(date)}
                />
              </div>
              {(idEmpresa === EMPRESA_CBN || idEmpresa === EMPRESA_PLASTICO_USR) &&
                <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                  <label htmlFor="fecha fin" className="mb-2 font-bold">
                    SELECCIONE SUB TIPO
                  </label>
                  <Select options={listaSubTipoSalida}
                    isSearchable='true'
                    isClearable='true'
                    value={subTipoChecklistSelected}
                    onChange={(event) => handleSubTipoChecklistSelected(event)}
                    placeholder='SELECCIONE SUB TIPO'
                  />
                </div>
              }
            </div>
            <div className="w-full sm:w-auto sm:flex-shrink-0">
              <button
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleBuscar}
              >
                BUSCAR
              </button>
            </div>
          </div>
          <Grid
            rows={listaChecklistSalida}
            headers={gridHeaders}
            exportar={exportar}
            fontSize={fontSizeValue}
            align={alignValue}
          />
          <div>
            <button className={ESTILO_BOTON_GRILLA_PREVIOUS} onClick={handlePrevPage}>Previous</button>
            <button className={ESTILO_BOTON_GRILLA_NEXT} onClick={handleNextPage}>Next</button>
          </div>
          <ModalConfiguracionGrilla
            modalConfiguracionVisible={modalConfiguracionVisible}
            onHideModalConfiguracion={onHideModalConfiguracion}
            ascendente={ascendente}
            descendente={descendente}
            setAscendente={setAscendente}
            setDescendente={setDescendente}
            gridHeaders={gridHeaders}
            setGridHeaders={setGridHeaders}
            onClickAcceptarOrdenGrillaModalConfiguracion={onClickAcceptarOrdenGrillaModalConfiguracion}
            onClickAcceptarHeadersGrillaModalConfiguracion={onClickAcceptarHeadersGrillaModalConfiguracion}
            onClickAcceptarFontSizeModalConfiguracion={onClickAcceptarFontSizeModalConfiguracion}
            fontSize={fontSizeValue}
            align={alignValue}
            onClickAcceptarAlignModalConfiguracion={onClickAcceptarAlignModalConfiguracion}
          />
        </div>
      );
    } catch (err) {
      console.log('ListarChecklistSalida render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
