import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_BOTON_LISTAR_ATRAS } from "../../utils/const-estilos";
import Select from "react-select";
import "../../style/style-cargando.css";
import { obtenerGrupoPuntosControl } from "../../api/georuta";
import { obtenerListaTipoDetencionDestino } from "../../api/configuracion/configuracion";
import { obtenerInfoGeocercaMedianteIdGrupo } from "../../api/gestion-dts/gestion";
import InputMask from 'react-input-mask';
import { actualizarInicioFinGeoruta, deshabilitarItinerario, guardarItinerario, modificarItinerario, obtenerItinerario } from "../../api/itinerario";
import Grid from "../../components/grid";
import { INT_TIPO_USUARIO_ADMINISTRADOR_WEB } from "../../utils/const-int";
import ModalConfirmar from "../../components/modal-confirmar";
import { TRUE } from "../../utils/const-bool";
import moment from "moment";

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));
let accesoBotonNuevo = 'none';


export default function Itinerario() {

  const infoItinerario = {
    id: -1,
    idgeoruta: -1,
    idgeocercaorigen: -1,
    codigogeocercaorigen: "",
    nombregeocercaorigen: "",
    idgeocercadestino: -1,
    codigogeocercadestino: "",
    nombregeocercadestino: "",
    tiempoprogramado: "",
    velocidadpromedio: "",
    distancia: "",
    tiempotolerancia: "",
    idtipodetenciondestino: -1,
    descripciontipodetenciondestino: "",
    tiempoextrapermitido: "",
  };
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "CODIGO", visible: true, },
    { key: "idgeoruta", title: "IDGEORUTA", visible: false, },
    { key: "idgeocercaorigen", title: "ID GEOCERCA ORIGEN", visible: false, filter: true, },
    { key: "codigogeocercaorigen", title: "CODIGO GEOCERCA ORIGEN", visible: true, filter: true, },
    { key: "nombregeocercaorigen", title: "NOMBRE GEOCERCA ORIGEN", visible: true, filter: true, },

    { key: "idgeocercadestino", title: "ID GEOCERCA DESTINO", visible: false, filter: true, },
    { key: "codigogeocercadestino", title: "CODIGO GEOCERCA DESTINO", visible: true, filter: true, },
    { key: "nombregeocercadestino", title: "NOMBRE GEOCERCA DESTINO", visible: true, filter: true, },

    { key: "tiempoprogramado", title: "TIEMPO PROGRAMADO", filter: true },
    { key: "velocidadpromedio", title: "VELOCIDAD PROMEDIO", filter: true },
    { key: "distancia", title: "DISTANCIA", filter: true },
    { key: "tiempotolerancia", title: "TIEMPO TOLERANCIA", filter: true },
    { key: "idtipodetenciondestino", title: "ID TIPO DETENCION ", visible: false, },
    { key: "descripciontipodetenciondestino", title: "TIPO DETENCION DESTINO", visible: true, },
    { key: "tiempoextrapermitido", title: "TIEMPO EXTRA PERMITIDO", filter: true },
    { key: "fechaproceso", title: "FECHA PROCESO", visible: false, filter: true },
  ];
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [listaItinerario, setListaItinerario] = useState([]);
  const [itinerarioSelected, setItinerarioSelected] = useState({ ...infoItinerario });
  const [geocercaOrigenSelected, setGeocercaOrigenSelected] = useState(null);
  const [geocercaDestinoSelected, setGeocercaDestinoSelected] = useState(null);
  const [listaTipoDetencion, setListaTipoDetencion] = useState([]);
  const [tipoDetencionSelected, setTipoDetencionSelected] = useState(null);
  const [modalDeshabilitarVisible, setModalDeshabilitarVisible] = useState(false);
  const [georuta, setGeoruta] = useState(0);

  useEffect(() => {
    // console.log("entro a UsuarioMovil");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      let infoPuntosControl = await obtenerGrupoPuntosControl();
      // console.log('infoPuntosControl');
      // console.log(infoPuntosControl);
      let listaTipoDetencionDestino = await obtenerListaTipoDetencionDestino();
      // console.log('listaTipoDetencionDestino');
      // console.log(listaTipoDetencionDestino);
      for (let i = 0; i < listaTipoDetencionDestino.length; i++) {
        listaTipoDetencionDestino[i].label = listaTipoDetencionDestino[i].descripcion;
        listaTipoDetencionDestino[i].value = listaTipoDetencionDestino[i].id;
      }
      setListaTipoDetencion(listaTipoDetencionDestino);
      if (infoPuntosControl === null || infoPuntosControl.meta === null) {
        alert('Debe seleccionar grupo puntos de control');
        return;
      }
      if (data !== undefined && data !== null) {
        setGeoruta(data.params.id);
        setTitulo(data.titulo);
        reset(data.params.id, false);
        let meta = JSON.parse(infoPuntosControl.meta);
        // console.log('meta');
        // console.log(meta);
        if (meta !== null) {
          let idGrupoGeocerca = meta.join(',');
          // console.log('idGrupoGeocerca');
          // console.log(idGrupoGeocerca);
          let objEnviar = {};
          objEnviar.idGrupoGeocerca = idGrupoGeocerca;
          let geocercas = await obtenerInfoGeocercaMedianteIdGrupo(objEnviar);
          console.log('geocercas');
          console.log(geocercas);
          for (let i = 0; i < geocercas.length; i++) {
            geocercas[i].nombre = geocercas[i].label;
            geocercas[i].label = geocercas[i].codigo + " - " + geocercas[i].label;
          }
          setListaGeocercas(geocercas);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setItinerarioSelected({ ...itinerarioSelected, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAceptarClick = async () => {
    try {
      console.log('handleAceptarClick');
      // lógica para guardar los cambios
      let valido = await validarInputs();
      console.log('valido');
      console.log(valido);
      if (valido === false) {
        return;
      }
      if (itinerarioSelected.velocidadpromedio === "") {
        itinerarioSelected.velocidadpromedio = "00.0";
      }
      if (itinerarioSelected.tiempotolerancia == "") {
        itinerarioSelected.tiempotolerancia = "00:00";
      }
      if (itinerarioSelected.tiempoextrapermitido == "") {
        itinerarioSelected.tiempoextrapermitido = "00:00";
      }
      console.log('itinerarioSelected');
      console.log(itinerarioSelected);
      // return;
      let objEnviar = {};
      objEnviar.idGeoruta = georuta;
      objEnviar.idGeocercaOrigen = itinerarioSelected.idgeocercaorigen;
      objEnviar.codigoGeocercaOrigen = itinerarioSelected.codigogeocercaorigen;
      objEnviar.nombreGeocercaOrigen = itinerarioSelected.nombregeocercaorigen;
      objEnviar.idGeocercaDestino = itinerarioSelected.idgeocercadestino;
      objEnviar.codigoGeocercaDestino = itinerarioSelected.codigogeocercadestino;
      objEnviar.nombreGeocercaDestino = itinerarioSelected.nombregeocercadestino;
      objEnviar.tiempoProgramado = itinerarioSelected.tiempoprogramado;
      objEnviar.velocidadPromedio = itinerarioSelected.velocidadpromedio;
      objEnviar.distancia = itinerarioSelected.distancia;
      objEnviar.tiempoTolerancia = itinerarioSelected.tiempotolerancia;
      objEnviar.idTipoDetencionDestino = itinerarioSelected.idtipodetenciondestino;
      objEnviar.tiempoExtraPermitido = itinerarioSelected.tiempoextrapermitido;
      objEnviar.id = itinerarioSelected.id;
      if (itinerarioSelected.id < 0) {
        console.log('crear');
        let creado = await guardarItinerario(objEnviar);
        // console.log('creado');
        // console.log(creado);
        if (creado === true) {
          alert('Se creo correctamente');
          reset(georuta, true);
        }
        if (creado === false) {
          alert('No se pudo crear');
        }
      }
      if (itinerarioSelected.id > 0) {
        console.log('actualizar');
        let modificado = await modificarItinerario(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (modificado === true) {
          alert('Se modifico correctamente');
          reset(georuta, true);
        }
        if (modificado === false) {
          alert('No se pudo modificar');
        }
      }
    } catch (err) {
      console.log('handleAceptarClick err');
      console.log(err);
      alert('No se pudo continuar, por favor revise los campos');
    }
  };

  const validarInputs = async () => {
    try {
      if (geocercaOrigenSelected === null) {
        alert('Debe seleccionar geocerca origen');
        return false;
      }
      if (geocercaDestinoSelected === null) {
        alert('Debe seleccionar geocerca destino');
        return false;
      }
      if (itinerarioSelected.tiempoprogramado === "00:00" || itinerarioSelected.tiempoprogramado === "") {
        alert('Debe ingrear tiempo programado');
        return false;
      }
      if (tipoDetencionSelected === null) {
        alert('Debe seleccionar tipo detencion destino');
        return false;
      }
      return true;
    } catch (err) {
      console.log('validarInputs err');
      console.log(err);
      return false;
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  }

  const handleGeocercaOrigenSelected = (value) => {
    console.log('handleGeocercaOrigenSelected');
    console.log(value);
    setGeocercaOrigenSelected(value);
    if (value !== null) {
      itinerarioSelected.idgeocercaorigen = value.value;
      itinerarioSelected.codigogeocercaorigen = value.codigo;
      itinerarioSelected.nombregeocercaorigen = value.nombre;
    }
    if (value === null) {
      itinerarioSelected.idgeocercaorigen = -1;
      itinerarioSelected.codigogeocercaorigen = "";
      itinerarioSelected.nombregeocercaorigen = "";
    }
  }

  const handleGeocercaDestinoSelected = (value) => {
    console.log('handleGeocercaDestinoSelected');
    console.log(value);
    setGeocercaDestinoSelected(value);
    if (value !== null) {
      itinerarioSelected.idgeocercadestino = value.value;
      itinerarioSelected.codigogeocercadestino = value.codigo;
      itinerarioSelected.nombregeocercadestino = value.nombre;
    }
    if (value === null) {
      itinerarioSelected.idgeocercadestino = -1;
      itinerarioSelected.codigogeocercadestino = "";
      itinerarioSelected.nombregeocercadestino = "";
    }
  }

  const handleTipoDetencionSelected = (value) => {
    console.log('handleTipoDetencionSelected');
    console.log(value);
    setTipoDetencionSelected(value);
    if (value !== null) {
      itinerarioSelected.idtipodetenciondestino = value.id;
    }
    if (value === null) {
      itinerarioSelected.idtipodetenciondestino = -1;
    }
  }

  const reset = async (idgeoruta, actualizarTablaGeoruta) => {
    // setCargandoPage(true);

    let listaInfoItinerario = await obtenerItinerario({ idGeoruta: idgeoruta });
    // console.log('infoItinerario');
    // console.log(infoItinerario);
    agregarAccionesALista(listaInfoItinerario);
    // setCargandoPage(false);
    setItinerarioSelected({ ...infoItinerario });
    setGeocercaOrigenSelected(null);
    setGeocercaDestinoSelected(null);
    setTipoDetencionSelected(null);
    if (actualizarTablaGeoruta == true && listaInfoItinerario.length > 0) {
      /**actualizar tabla georuta */
      console.log('listaInfoItinerario');
      console.log(listaInfoItinerario);
      console.log('georuta');
      console.log(georuta);
      const first = listaInfoItinerario[0];
      const last = listaInfoItinerario[listaInfoItinerario.length - 1];
      let objEnviar = {};
      objEnviar.idGeocercaInicio = first.idgeocercaorigen;
      objEnviar.codigoGeocercaInicio = first.codigogeocercaorigen;
      objEnviar.nombreGeocercaInicio = first.nombregeocercaorigen;
      objEnviar.idGeocercaFin = last.idgeocercadestino;
      objEnviar.codigoGeocercaFin = last.codigogeocercadestino;
      objEnviar.nombreGeocercaFin = last.nombregeocercadestino;
      objEnviar.tiempoRecorrido = calcularTiempoViaje();
      objEnviar.idGeoruta = georuta;
      actualizarInicioFinGeoruta(objEnviar);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      if (lista.length === 0) {
        setListaItinerario(lista);
      }
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            color: "green",
            onClick: (param) => modificarItinerarioSeleccionado(param),
          };
          let objDeshabilitar = {
            label: "Deshabilitar",
            color: "red",
            onClick: (param) => cambiarEstado(param),
          };
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objDeshabilitar);
            acciones.push(objModificar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            if (permiso.eliminarDeshabilitar === TRUE) {
              acciones.push(objDeshabilitar);
            }
            if (permiso.nuevo === TRUE) {
              accesoBotonNuevo = '';
            }
          }
          lista[i].acciones = acciones;
        }
        setListaItinerario(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const modificarItinerarioSeleccionado = (data) => {
    try {
      console.log('modificarItinerarioSeleccionado');
      console.log(data);
      setItinerarioSelected(data);
      itinerarioSelected.idGeoruta = data.idgeoruta;

      let objGeocercaDestino = {};
      objGeocercaDestino.codigo = data.codigogeocercadestino;
      objGeocercaDestino.label = data.codigogeocercadestino + " - " + data.nombregeocercadestino;
      objGeocercaDestino.nombre = data.nombregeocercadestino;
      objGeocercaDestino.value = data.idgeocercadestino;
      setGeocercaDestinoSelected(objGeocercaDestino);

      let objGeocercaOrigen = {};
      objGeocercaOrigen.codigo = data.codigogeocercaorigen;
      objGeocercaOrigen.label = data.codigogeocercaorigen + " - " + data.nombregeocercaorigen;
      objGeocercaOrigen.nombre = data.nombregeocercaorigen;
      objGeocercaOrigen.value = data.idgeocercaorigen;
      setGeocercaOrigenSelected(objGeocercaOrigen);

      let objTipoDetencionDestino = {};
      objTipoDetencionDestino.descripcion = data.descripciontipodetenciondestino;
      objTipoDetencionDestino.id = data.idtipodetenciondestino;
      objTipoDetencionDestino.label = data.descripciontipodetenciondestino;
      objTipoDetencionDestino.value = data.idtipodetenciondestino;
      setTipoDetencionSelected(objTipoDetencionDestino);

    } catch (err) {
      console.log('modificarItinerarioSeleccionado err');
      console.log(err);
    }
  }

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setItinerarioSelected(data);
      setModalDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleCancelModal = () => {
    setModalDeshabilitarVisible(false);
  };

  const handleConfirmModal = async () => {
    console.log('handleConfirmModal');
    setModalDeshabilitarVisible(false);
    // console.log('georutaSelected');
    // console.log(georutaSelected);
    // return;
    if (itinerarioSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = itinerarioSelected.id;
    if (itinerarioSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarItinerario(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset(georuta, true);
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const calcularTiempoViaje = () => {
    try {
      console.log('calcularTiempoViaje');
      let tiempo = moment("00:00", 'HH:mm');
      const formatoHora = 'HH:mm';
      for (let i = 0; i < listaItinerario.length; i++) {
        const element = listaItinerario[i];
        // Intenta analizar el texto con el formato especificado
        const horaTiempoProgramado = moment(element.tiempoprogramado, formatoHora, true);
        // Verifica si Moment.js pudo analizar el texto con éxito y si es una hora válida
        if (horaTiempoProgramado.isValid()) {
          let hora = moment(element.tiempoprogramado, 'HH:mm');
          tiempo.add(moment(hora).format("HH:mm"));
        }

        const horaTiempoExtraPermitido = moment(element.tiempoextrapermitido, formatoHora, true);
        // Verifica si Moment.js pudo analizar el texto con éxito y si es una hora válida
        if (horaTiempoExtraPermitido.isValid()) {
          let hora = moment(element.tiempoextrapermitido, 'HH:mm');
          tiempo.add(moment(hora).format("HH:mm"));
        }

        const horaTiempoTolerancia = moment(element.tiempotolerancia, formatoHora, true);
        // Verifica si Moment.js pudo analizar el texto con éxito y si es una hora válida
        if (horaTiempoTolerancia.isValid()) {
          let hora = moment(element.tiempotolerancia, 'HH:mm');
          tiempo.add(moment(hora).format("HH:mm"));
        }

      }
      // console.log('tiempo');
      // console.log(moment(tiempo).format("HH:mm"));
      return moment(tiempo).format("HH:mm");
    } catch (err) {
      console.log('calcularTiempoViaje err');
      console.log(err);
      return "N/A";
    }
  }

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div
              style={{ marginTop: 10, }}
            >
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 10,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              style={{
                paddingLeft: 4,
                fontWeight: 'bold',
              }}
            >
              {titulo}
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <hr />
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-2/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  GEOCERCA ORIGEN
                </label>
                <Select options={listaGeocercas} isSearchable='true'
                  isClearable='true'
                  value={geocercaOrigenSelected}
                  onChange={(event) => handleGeocercaOrigenSelected(event)}
                  placeholder='SELECCIONE GEOCERCA ORIGEN'
                />
              </div>
            </div>

            <div className="w-full md:w-2/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  GEOCERCA DESTINO
                </label>
                <Select options={listaGeocercas} isSearchable='true'
                  isClearable='true'
                  value={geocercaDestinoSelected}
                  onChange={(event) => handleGeocercaDestinoSelected(event)}
                  placeholder='SELECCIONE GEOCERCA DESTINO'
                />
              </div>
            </div>

          </div>
          <br />
          <hr />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="tiempoprogramado" className="mb-2 font-bold">
                  TIEMPO PROGRAMADO
                </label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <InputMask
                    type="text"
                    id="tiempoprogramado"
                    name="tiempoprogramado"
                    value={itinerarioSelected.tiempoprogramado}
                    onChange={handleInputChange}
                    mask="99:99" // Máscara para el formato HH:mm
                    maskChar="0" // Carácter de relleno
                    placeholder="HH:mm"
                    className="p-2 border border-gray-300 rounded"
                  />
                  <label> HH:mm</label>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="velocidadpromedio" className="mb-2 font-bold">
                  VELOCIDAD PROMEDIO
                </label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <InputMask
                    type="text"
                    id="velocidadpromedio"
                    name="velocidadpromedio"
                    value={itinerarioSelected.velocidadpromedio}
                    onChange={handleInputChange}
                    mask="99.9" // Máscara para el formato HH:mm
                    maskChar="0" // Carácter de relleno
                    placeholder="00.0"
                    className="p-2 border border-gray-300 rounded"
                  />
                  <label>KM/h</label>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="distancia" className="mb-2 font-bold">
                  DISTANCIA
                </label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type="number"
                    id="distancia"
                    name="distancia"
                    value={itinerarioSelected.distancia}
                    onChange={handleInputChange}
                    placeholder="DISTANCIA"
                    className="p-2 border border-gray-300 rounded"
                    maxLength={3}
                    min={0}
                    max={999}
                  />
                  <label>KM</label>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="tiempotolerancia" className="mb-2 font-bold">
                  TIEMPO TOLERANCIA
                </label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <InputMask
                    type="text"
                    id="tiempotolerancia"
                    name="tiempotolerancia"
                    value={itinerarioSelected.tiempotolerancia}
                    onChange={handleInputChange}
                    mask="99:99" // Máscara para el formato HH:mm
                    maskChar="0" // Carácter de relleno
                    placeholder="HH:mm"
                    className="p-2 border border-gray-300 rounded"
                  />
                  <label>HH:mm</label>
                </div>
              </div>
            </div>

          </div>
          <br />
          <div className="flex flex-col md:flex-row">

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="unidad" className="mb-2 font-bold">
                  TIPO DETENCION DESTINO
                </label>
                <Select options={listaTipoDetencion} isSearchable='true'
                  isClearable='true'
                  value={tipoDetencionSelected}
                  onChange={(event) => handleTipoDetencionSelected(event)}
                  placeholder='SELECCIONE GEOCERCA ORIGEN'
                />
              </div>
            </div>

            <div className="w-full md:w-1/4 px-4 mb-4 md:mb-0">

              <div
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label htmlFor="tiempoextrapermitido" className="mb-2 font-bold">
                  TIEMPO EXTRA PERMITIDO
                </label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <InputMask
                    type="text"
                    id="tiempoextrapermitido"
                    name="tiempoextrapermitido"
                    value={itinerarioSelected.tiempoextrapermitido}
                    onChange={handleInputChange}
                    mask="99:99" // Máscara para el formato HH:mm
                    maskChar="0" // Carácter de relleno
                    placeholder="HH:mm"
                    className="p-2 border border-gray-300 rounded"
                  />
                  <label>HH:mm</label>
                </div>
              </div>
            </div>

          </div>
          <br />
          <div className="flex justify-between p-4">
            <button className={ESTILO_BOTON_GENERAL_CANCELAR}
              onClick={handleCancelClick}
            >
              CANCELAR
            </button>
            <button className={ESTILO_BOTON_GENERAL_ACEPTAR}
              onClick={handleAceptarClick}
            >
              ACEPTAR
            </button>
          </div>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 20,
            }}
          >
            <label>TIEMPO VIAJE:</label>
            <label
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
              }}
            >{calcularTiempoViaje()}</label>
          </div>
          <Grid rows={listaItinerario} headers={headers} />
          <ModalConfirmar
            isOpen={modalDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar?"
          />
        </div>
      );
    } catch (err) {
      console.log('Itinerario render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
