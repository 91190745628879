import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../style/style-cargando.css";
import {
  INT_ID_MODULO_CHECKLIST,
  INT_ID_MODULO_REPORTES,
  INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const-int";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import {
  ESTILO_BOTON_LISTAR_ATRAS,
  ESTILO_BOTON_LISTAR_NUEVO,
} from "../../utils/const-estilos";
import { obtenerHorariosDescarga } from "../../api/reportes";
import { HORARIO } from "./util";
import HorarioCargaDescarga from "../../components/horario-carga-descarga";
new momentLocalizer(moment);
const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));

export default function EstadoAlmacenesDescarga() {
  const history = useHistory();
  const [permisos, setPermisos] = useState(null);
  const [cargandoPage, setCargandoPage] = useState(false);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [listaGeocercas, setListaGeocercas] = useState([]);
  const [geocercaSelected, setGeocercaSelected] = useState(null);
  const [listaAlmacenesDescarga, setListaAlmacenesDescarga] = useState([]);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      iniciarFechas();
      let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_REPORTES;
      obj.idMenuSegundoNivel = 0;
      obj.idMenuTercerNivel = 0;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      // console.log('obtenerPermisos');
      // console.log(obtenerPermisos);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      // console.log('idTipoUsuario');
      // console.log(idTipoUsuario);
      /*let listaGrupo = await obtenerGrupoGeocercas();
      if (listaGrupo !== null) {
        // console.log('listaGrupo');
        // console.log(listaGrupo);
        let meta = JSON.parse(listaGrupo.meta);
        let listaIdGrupo = "";
        for (let i = 0; i < meta.length; i++) {
          const element = meta[i];
          listaIdGrupo = listaIdGrupo + element + ",";
        }
        listaIdGrupo = listaIdGrupo.substring(0, listaIdGrupo.length - 1);
        let obj = {};
        obj.idGrupoGeocerca = listaIdGrupo;
        let listaGeo = await obtenerInfoGeocercaMedianteIdGrupo(obj);
        setListaGeocercas(listaGeo);
      }*/
      if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        setTieneAccesoAlModulo(true);
      }
      if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        if (permiso.accesoAlModulo === TRUE) {
          setTieneAccesoAlModulo(true);
        }
      }
      setCargandoPage(false);
    } catch (err) {
      console.log("iniciar err");
      console.log(err);
      setCargandoPage(false);
    }
  };

  const iniciarFechas = () => {
    try {
      let currentDate = moment();
      let fechaI = new Date(
        currentDate.clone().add(1, "day").format("YYYY-MM-DD")
      );
      setFechaInicio(fechaI);
    } catch (err) {
      console.log("iniciarFechas err");
      console.log(err);
      setFechaInicio(new Date());
    }
  };

  const handleBuscar = async () => {
    try {
      // console.log('handleBuscar');
      setCargandoPage(true);
      let objEnviar = {};
      objEnviar.fechaHoraIni = moment(fechaInicio).format("YYYY-MM-DD");
      const infoDescargas = await obtenerHorariosDescarga(objEnviar);
      // console.log('infoDescargas');
      // console.log(infoDescargas);
      let listaNombreAlmacenesDescarga = [];
      for (let i = 0; i < infoDescargas.length; i++) {
        const element = infoDescargas[i];
        let objAlmacenDescarga = {};
        objAlmacenDescarga.codigoDestino = element.codigoDestino;
        objAlmacenDescarga.depositoDestino = element.depositoDestino;
        const existe = listaNombreAlmacenesDescarga.find(
          (e) =>
            e.codigoDestino === element.codigoDestino &&
            e.depositoDestino === element.depositoDestino
        );
        if (existe === undefined) {
          objAlmacenDescarga.horario = Array.from(HORARIO);
          listaNombreAlmacenesDescarga.push(objAlmacenDescarga);
        }
      }
      // console.log('listaNombreAlmacenesDescarga');
      // console.log(listaNombreAlmacenesDescarga);
      for (let i = 0; i < infoDescargas.length; i++) {
        const element = infoDescargas[i];
        const placa = element.placa;
        const hora = parseInt(moment(element.planDescarga).format("HH"));
        // console.log('hora');
        // console.log(hora);
        const index = listaNombreAlmacenesDescarga.findIndex(
          (e) =>
            e.codigoDestino === element.codigoDestino &&
            e.depositoDestino === element.depositoDestino
        );
        // console.log('index');
        // console.log(index);
        const existePlaca = listaNombreAlmacenesDescarga[index].horario[
          hora
        ].plan.find((e) => e === placa);
        if (existePlaca === undefined) {
          listaNombreAlmacenesDescarga[index].horario[hora].plan.push(placa);
        }
      }
      console.log("listaNombreAlmacenesDescarga");
      console.log(listaNombreAlmacenesDescarga);
      setListaAlmacenesDescarga(listaNombreAlmacenesDescarga);
      setCargandoPage(false);
    } catch (err) {
      console.log("handleBuscar err");
      console.log(err);
      setListaAlmacenesDescarga([]);
      setCargandoPage(false);
    }
  };

  const handleOptionSeleccionarGeocerca = (event) => {
    setGeocercaSelected(event);
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div className="loader"></div>
            <div style={{ marginTop: 10 }}>
              <p>Cargando...</p>
            </div>
          </div>
        );
      }
      if (tieneAccesoAlModulo === false) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 30,
            }}
          >
            <div style={{ marginTop: 10 }}>
              <p>Sin Acceso Al Modulo...</p>
            </div>
          </div>
        );
      }
      return (
        <div className="m-5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              style={{
                fontWeight: "bold",
              }}
            >
              ESTADO ALMACENES DESCARGA
            </label>
            <button
              type="button"
              className={ESTILO_BOTON_LISTAR_ATRAS}
              onClick={handleAtrasClick}
            >
              ATRAS
            </button>
          </div>
          <div className="flex flex-row">
            <div className="w-full sm:w-2/2 md:w-2/4 lg:w-2/6">
              <label htmlFor="fecha inicio" className="mb-2 font-bold">
                FECHA DESCARGA
              </label>
              <DatePicker
                defaultValue={fechaInicio}
                value={fechaInicio}
                valueFormat={{ dateStyle: "medium" }}
                onChange={(date) => setFechaInicio(date)}
              />
            </div>
            <div
              className="w-full"
              style={{
                display: "none",
              }}
            >
              <label className="block font-bold">SELECCIONAR GEOCERCA</label>
              <div className="flex flex-wrap">
                <Select
                  options={listaGeocercas}
                  isSearchable={true}
                  isClearable={true}
                  isMulti
                  value={geocercaSelected}
                  onChange={(event) => handleOptionSeleccionarGeocerca(event)}
                  placeholder="Seleccione Geocerca"
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-2">
            <button
              className={ESTILO_BOTON_LISTAR_NUEVO}
              onClick={handleBuscar}
            >
              BUSCAR
            </button>
          </div>
          <div className="flex flex-wrap">
            {listaAlmacenesDescarga.map((data) => HorarioCargaDescarga(data))}
          </div>
        </div>
      );
    } catch (err) {
      console.log("EstadoAlmacenesDescarga render");
      console.log(err);
      return (
        <div className=" w-1/2 m-auto px-[2rem] ">
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  };

  return render();
}
