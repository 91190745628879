import { useState, useEffect } from "react";
import "../../style/style-cargando.css";
import {
    INT_ID_MODULO_CHECKLIST,
    INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST,
    INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
    INT_TIPO_USUARIO_CHOFER_MOVIL,
} from "../../utils/const-int";
import Select from "react-select";
import { obtenerPermisosPorNivelUsuarioWeb } from "../../api/permisos/permisos";
import { TRUE } from "../../utils/const-bool";
import moment from "moment";
import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import momentLocalizer from "react-widgets-moment";
import { ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const-estilos";
import { ChevronDown, ChevronUp, Search } from "feather-icons-react/build/IconComponents";
import { obtenerGrupoGeocercas } from "../../api/checklist";
import { obtenerCoordenadasGeocercas, obtenerInfoGeocercaMedianteIdGrupo, obtenerPuntoActualVehiculoXGrupoPlaca } from "../../api/gestion-dts/gestion";
import { obtenerListaUsuariosMovil } from "../../api/usuario/usuario";
import { obtenerItinerariosSeguimiento, obtenerListaSeguimiento } from "../../api/seguimiento";
import CardSeguimiento from "../../components/card-seguimiento";
import { obtenerListaIncidentePorListaPlaca } from "../../api/incidente";
import ModalInfoUnidadSeguimiento from "../../components/modal-info-unidad-seguimiento";
new momentLocalizer(moment);

const idTipoUsuario = parseInt(localStorage.getItem("idTipoUsuarioDespacho"));

export default function LineaTiempo() {

    const [permisos, setPermisos] = useState(null);
    const [cargandoPage, setCargandoPage] = useState(false);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
    const [listaGeocercaOrigen, setListaGeocercaOrigen] = useState([]);
    const [listaGeocercaDestino, setListaGeocercaDestino] = useState([]);
    const [listaSeguimientoOriginal, setListaSeguimientoOriginal] = useState([]);
    const [listaSeguimientoFiltrado, setListaSeguimientoFiltrado] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [geocercaOrigenSelected, setGeocercaOrigenSelected] = useState(null);
    const [geocercaDestinoSelected, setGeocercaDestinoSelected] = useState(null);
    const [unidadSelected, setUnidadSelected] = useState(null);
    const [seguimientoSelected, setSeguimientoSelected] = useState(null);
    const [mostrarDivFiltro, setMostrarDivFiltro] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [modalVerDetallesSeguimientoVisible, setModalVerDetallesSeguimientoVisible] = useState(false);

    useEffect(() => {
        iniciar();
    }, []);

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const iniciar = async () => {
        try {
            iniciarFechas();
            let obj = {};
            obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
            obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
            obj.idMenuTercerNivel = 0;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
            // console.log('obtenerPermisos');
            // console.log(obtenerPermisos);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            // console.log('idTipoUsuario');
            // console.log(idTipoUsuario);
            // debugger;
            let listaGrupo = await obtenerGrupoGeocercas();
            if (listaGrupo !== null) {
                // console.log('listaGrupo');
                // console.log(listaGrupo);
                let meta = JSON.parse(listaGrupo.meta);
                let listaIdGrupo = "";
                for (let i = 0; i < meta.length; i++) {
                    const element = meta[i];
                    listaIdGrupo = listaIdGrupo + element + ",";
                }
                listaIdGrupo = listaIdGrupo.substring(0, listaIdGrupo.length - 1);
                // console.log('listaIdGrupo');
                // console.log(listaIdGrupo);
                let obj = {};
                obj.idGrupoGeocerca = listaIdGrupo;
                let listaGeo = await obtenerInfoGeocercaMedianteIdGrupo(obj);
                setListaGeocercaOrigen(listaGeo);
                setListaGeocercaDestino(listaGeo);
            }
            let listaUsuarioMov = await obtenerListaUsuariosMovil();
            // console.log('listaUsuarioMov');
            // console.log(listaUsuarioMov);
            for (let i = 0; i < listaUsuarioMov.length; i++) {
                listaUsuarioMov[i].value = listaUsuarioMov[i].id;
                listaUsuarioMov[i].label = listaUsuarioMov[i].nombre + " - " + listaUsuarioMov[i].nombreUnidad;
            }
            listaUsuarioMov = listaUsuarioMov.filter(e => e.idtipousuario === INT_TIPO_USUARIO_CHOFER_MOVIL);
            setListaUnidades(listaUsuarioMov);
            if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                setTieneAccesoAlModulo(true);
            }
            if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                if (permiso.accesoAlModulo === TRUE) {
                    setTieneAccesoAlModulo(true);
                }
            }
            let currentDate = moment();
            let fechaI = new Date(currentDate.clone().startOf("week").add(1, 'day').format("YYYY-MM-DD"));
            let fechaF = new Date(currentDate.clone().endOf("week").add(1, 'day').format("YYYY-MM-DD"));
            let objEnviar = {};
            objEnviar.fechaHoraIni = moment(fechaI).format('YYYY-MM-DD');
            objEnviar.fechaHoraFin = moment(fechaF).format('YYYY-MM-DD');
            let listaSeg = await obtenerListaSeguimiento(objEnviar);
            // console.log('listaSeg');
            // console.log(listaSeg);
            await procesarListaSeguimiento(listaSeg);
            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const iniciarFechas = () => {
        try {
            let currentDate = moment();
            let fechaI = new Date(currentDate.clone().startOf("week").add(1, 'day').format("YYYY-MM-DD"));
            let fechaF = new Date(currentDate.clone().endOf("week").add(1, 'day').format("YYYY-MM-DD"));
            setFechaInicio(fechaI);
            setFechaFin(fechaF);
        } catch (err) {
            console.log('iniciarFechas err');
            console.log(err);
            setFechaInicio(new Date());
            setFechaFin(new Date());
        }
    }

    const handleBuscar = async () => {
        try {
            // console.log('handleBuscar');
            setCargandoPage(true);
            let objEnviar = {};
            objEnviar.fechaHoraIni = moment(fechaInicio).format('YYYY-MM-DD');
            objEnviar.fechaHoraFin = moment(fechaFin).format('YYYY-MM-DD');
            let listaSeg = await obtenerListaSeguimiento(objEnviar);
            // console.log('listaSeg');
            // console.log(listaSeg);
            await procesarListaSeguimiento(listaSeg);
            setCargandoPage(false);
        } catch (err) {
            console.log('handleBuscar err');
            console.log(err);
            setCargandoPage(false);
        }
    }

    const procesarListaSeguimiento = async (listaSeg) => {
        try {
            let listaIdPlanilla = [];
            let listaSeguimientoTemporal = [];
            let listaUnidades = [];
            for (let i = 0; i < listaSeg.length; i++) {
                const element = listaSeg[i];
                const existe = listaIdPlanilla.find(e => e === element.planilla);
                if (existe === undefined) {
                    listaIdPlanilla.push(element.planilla);
                }
            }
            // console.log('listaIdPlanilla');
            // console.log(listaIdPlanilla);
            for (let i = 0; i < listaSeg.length; i++) {
                const element = listaSeg[i];
                const existe = listaUnidades.find(e => e === element.placa);
                if (existe === undefined) {
                    listaUnidades.push(element.placa);
                }
            }
            // console.log('listaUnidades');
            // console.log(listaUnidades);
            const stringListaUnidades = listaUnidades.map(item => `'${item}'`).join(',');
            // console.log('stringListaUnidades');
            // console.log(stringListaUnidades);
            let objUnidades = {};
            objUnidades.placas = stringListaUnidades;
            const listaPuntoActualUnidades = await obtenerPuntoActualVehiculoXGrupoPlaca(objUnidades);
            // console.log('listaPuntoActualUnidades');
            // console.log(listaPuntoActualUnidades);
            // debugger;
            for (let j = 0; j < listaIdPlanilla.length; j++) {
                const elements = listaSeg.filter(e => e.planilla === listaIdPlanilla[j]);
                if (elements.length > 0) {
                    const firstElement = elements[0];
                    let obj = {};
                    obj.idDescarga = firstElement.id;
                    obj.planilla = firstElement.planilla;
                    obj.codigoOrigen = firstElement.codigoOrigen;
                    obj.depositoOrigen = firstElement.depositoOrigen;
                    obj.codigoDestino = firstElement.codigoDestino;
                    obj.depositoDestino = firstElement.depositoDestino;
                    obj.unidad = firstElement.placa;
                    obj.fechaSalida = firstElement.fechaSalida;
                    obj.fechaDescarga = firstElement.planDescarga;
                    obj.tuplas = elements;
                    obj.idGeoruta = firstElement.idGeoruta;
                    obj.idRuta = firstElement.idRuta;
                    listaSeguimientoTemporal.push(obj);
                }
            }
            setListaSeguimientoOriginal(listaSeguimientoTemporal);
            for (let i = 0; i < listaSeguimientoTemporal.length; i++) {
                const element = listaSeguimientoTemporal[i];
                /**ESTE IF ES PARA OBTENER LOS INCIDENTES */
                if (element.fechaDescarga !== undefined && element.fechaDescarga !== null && String(element.fechaDescarga).length > 0) {
                    let objEnviar = {};
                    objEnviar.unidades = "'" + element.unidad + "'";
                    if (element.fechaSalida === undefined || element.fechaSalida === null || String(element.fechaSalida).length === 0) {
                        objEnviar.fechaHoraIni = moment(fechaInicio).format('YYYY-MM-DD') + " 00:00"
                    } else {
                        objEnviar.fechaHoraIni = element.fechaSalida;
                    }
                    if (element.fechaDescarga === undefined || element.fechaDescarga === null || String(element.fechaDescarga).length === 0) {
                        objEnviar.fechaHoraFin = moment(fechaFin).format('YYYY-MM-DD') + " 23:59"
                    } else {
                        objEnviar.fechaHoraFin = element.fechaDescarga;
                    }
                    // Crear un objeto Moment a partir de la fecha y hora
                    const fechaHoraIniMoment = moment(objEnviar.fechaHoraIni, 'YYYY-MM-DD HH:mm:ss');
                    // Obtener el tiempo Unix
                    listaSeguimientoTemporal[i].fechaSalidaUnix = fechaHoraIniMoment.unix();

                    const fechaHoraFinMoment = moment(objEnviar.fechaHoraFin, 'YYYY-MM-DD HH:mm:ss');
                    listaSeguimientoTemporal[i].fechaDescargaUnix = fechaHoraFinMoment.unix();

                    await sleep(20.);
                    const listaIncidentes = await obtenerListaIncidentePorListaPlaca(objEnviar);
                    for (let k = 0; k < listaIncidentes.length; k++) {
                        const fechaProceso = moment(listaIncidentes[k].fechaProceso, 'YYYY-MM-DD HH:mm:ss');
                        listaIncidentes[k].fechaProcesoUnix = fechaProceso.unix();
                        listaIncidentes[k].fechaProceso = moment(listaIncidentes[k].fechaProceso).format('YYYY-MM-DD HH:mm');
                    }
                    listaSeguimientoTemporal[i].incidentes = listaIncidentes;
                } else {
                    listaSeguimientoTemporal[i].incidentes = [];
                }
                /**ESTA AREA ES PARA OBTENER EL PUNTO ACTUAL */
                let ubicacionActual = listaPuntoActualUnidades.find(e => e.placa === element.unidad);
                // console.log('ubicacionActual');
                // console.log(ubicacionActual);
                if (ubicacionActual !== undefined) {
                    listaSeguimientoTemporal[i].fechaEventoUnix = moment(ubicacionActual.FECHAHORA_EVENTO).unix();
                    listaSeguimientoTemporal[i].fechaEvento = moment(ubicacionActual.FECHAHORA_EVENTO).format('YYYY-MM-DD HH:mm');
                    listaSeguimientoTemporal[i].ubicacionActual = ubicacionActual;
                }
            }
            //OBTENIENDO EL ITINERARIO USANDO EL IDGEORUTA 
            const listIdGeoruta = listaSeguimientoTemporal.filter(e => e.idGeoruta !== undefined && e.idGeoruta !== null);
            // console.log('listIdGeoruta');
            // console.log(listIdGeoruta);
            if (listIdGeoruta.length > 0) {
                const stringListaIdGeoruta = listIdGeoruta.map(item => `'${item.idGeoruta}'`).join(',');
                console.log('stringListaIdGeoruta');
                console.log(stringListaIdGeoruta);
                let objEnviarIdGeoruta = {};
                objEnviarIdGeoruta.idGeorutas = stringListaIdGeoruta;
                const listaItinerarios = await obtenerItinerariosSeguimiento(objEnviarIdGeoruta);
                // console.log('listaItinerarios');
                // console.log(listaItinerarios);
                if (listaItinerarios.length > 0) {
                    for (let i = 0; i < listaSeguimientoTemporal.length; i++) {
                        const element = listaSeguimientoTemporal[i];
                        if (element.idGeoruta !== undefined && element.idGeoruta !== null) {
                            const itinerario = listaItinerarios.filter(e => e.idGeoruta == element.idGeoruta);
                            listaSeguimientoTemporal[i].itinerario = itinerario;
                        } else {
                            listaSeguimientoTemporal[i].itinerario = [];
                        }
                        if (listaSeguimientoTemporal[i].itinerario.length > 0) {
                            //Obteniendo informacion de las geocercas del itinerario
                            const stringListaItinerario = listaSeguimientoTemporal[i].itinerario.map(item => `'${item.idGeocercaOrigen}'`).join(',');
                            // console.log('stringListaItinerario');
                            // console.log(stringListaItinerario);
                            let objEnviarItinerario = {};
                            objEnviarItinerario.idGeocercas = stringListaItinerario;
                            const listaInfoItinerario = await obtenerCoordenadasGeocercas(objEnviarItinerario);
                            // console.log('listaInfoItinerario ');
                            // console.log(listaInfoItinerario);
                            for (let j = 0; j < listaSeguimientoTemporal[i].itinerario.length; j++) {
                                const element = listaSeguimientoTemporal[i].itinerario[j];
                                const indexItinerario = listaInfoItinerario.findIndex(e => e.idGeoCerca === element.idGeocercaOrigen);
                                // console.log('indexItinerario ');
                                // console.log(indexItinerario);
                                if (indexItinerario > -1) {
                                    listaSeguimientoTemporal[i].itinerario[j].infoGeocerca = listaInfoItinerario[indexItinerario];
                                } else {
                                    listaSeguimientoTemporal[i].itinerario[j].infoGeocerca = null;
                                }
                            }
                        }
                        await sleep(20);
                    }
                }
            }
            /*
            //OBTENIENDO LA GEOCERCA QUE ES TODO EL RECORRIDO 
            const listIdRuta = listaSeguimientoTemporal.filter(e => e.idRuta !== undefined && e.idRuta !== null);
            // console.log('listIdRuta');
            // console.log(listIdRuta);
            const stringListaIdRuta = listIdRuta.map(item => `'${item.idRuta}'`).join(',');
            // console.log('stringListaIdRuta');
            // console.log(stringListaIdRuta);
            let objEnviarIdRuta = {};
            objEnviarIdRuta.idGeocercas = stringListaIdRuta;
            const listaInfoGeocerca = await obtenerCoordenadasGeocercas(objEnviarIdRuta);
            // console.log('listaInfoGeocerca');
            // console.log(listaInfoGeocerca);
            if (listaInfoGeocerca.length > 0) {
                for (let i = 0; i < listaSeguimientoTemporal.length; i++) {
                    const element = listaSeguimientoTemporal[i];
                    if (element.idRuta !== undefined && element.idRuta !== null) {
                        const infoGeocerca = listaInfoGeocerca.filter(e => e.idGeoCerca == element.idRuta);
                        console.log('infoGeocerca');
                        console.log(infoGeocerca);
                        listaSeguimientoTemporal[i].infoGeocercaRuta = infoGeocerca;
                    } else {
                        listaSeguimientoTemporal[i].infoGeocercaRuta = null;
                    }
                }
            }*/
            if (filterText !== undefined && filterText !== null && String(filterText).length > 0) {
                /**FILTRAMOS */
                procesarFiltros();
            } else {
                setListaSeguimientoFiltrado(listaSeguimientoTemporal);
            }
            // console.log('listaSeguimientoTemporal');
            // console.log(listaSeguimientoTemporal);
        } catch (err) {
            console.log('procesarListaSeguimiento err');
            console.log(err);
            setListaSeguimientoFiltrado([]);
        }
    }

    // Verificar si estamos en el último nivel del array
    const esUltimoNivel = (elemento) => {
        return !Array.isArray(elemento) || !Array.isArray(elemento[0]);
    }

    const handleGeocercaOrigen = async (event) => {
        try {
            setGeocercaOrigenSelected(event);
        } catch (err) {
            console.log('handleGeocercaOrigen err');
            console.log(err);
        }
    }

    const handleGeocercaDestino = async (event) => {
        try {
            setGeocercaDestinoSelected(event);
        } catch (err) {
            console.log('handleGeocercaDestino err');
            console.log(err);
        }
    }

    const handleUnidad = async (event) => {
        try {
            setUnidadSelected(event);
        } catch (err) {
            console.log('handleUnidad err');
            console.log(err);
        }
    }

    const toggleMostrarDivFiltro = () => {
        setMostrarDivFiltro(!mostrarDivFiltro);
    };

    const onButtonDetalles = (data) => {
        try {
            console.log('onButtonDetalles');
            console.log(data);
            setSeguimientoSelected(data);
            setModalVerDetallesSeguimientoVisible(true);
        } catch (err) {
            console.log('onButtonDetalles err');
            console.log(err);
        }
    }

    /**search area */
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleFilterDebounced = debounce((filterValue) => {
        // Filtrar los datos y actualizar el estado del componente padre (setData)
        if (filterValue !== undefined && filterValue !== null && String(filterValue).length > 0) {
            procesarFiltros();
        }
    }, 500); // Ajusta el valor de debounce para cambiar el tiempo de espera

    /** end search area */

    const procesarFiltros = () => {
        try {
            const filteredData = listaSeguimientoOriginal.filter((item) =>
                item.codigoDestino.toLowerCase().includes(filterText.toLowerCase()) ||
                item.codigoOrigen.toLowerCase().includes(filterText.toLowerCase()) ||
                item.depositoDestino.toLowerCase().includes(filterText.toLowerCase()) ||
                item.depositoOrigen.toLowerCase().includes(filterText.toLowerCase()) ||
                item.fechaDescarga.toLowerCase().includes(filterText.toLowerCase()) ||
                item.fechaSalida.toLowerCase().includes(filterText.toLowerCase()) ||
                item.planilla.toLowerCase().includes(filterText.toLowerCase()) ||
                item.unidad.toLowerCase().includes(filterText.toLowerCase())
            );
            setListaSeguimientoFiltrado(filteredData);
        } catch (err) {
            console.log('procesarFiltros err');
            console.log(err);
            setListaSeguimientoFiltrado([]);
        }
    }

    const hideModalVerDetallesSeguimientoVisible = () => {
        setModalVerDetallesSeguimientoVisible(false);
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 30,
                        }}
                    >
                        <div className="loader"></div>
                        <div
                            style={{ marginTop: 10, }}
                        >
                            <p>Cargando...</p>
                        </div>
                    </div>
                );
            }
            if (tieneAccesoAlModulo === false) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 30,
                        }}
                    >
                        <div
                            style={{ marginTop: 10, }}
                        >
                            <p>Sin Acceso Al Modulo...</p>
                        </div>
                    </div>
                );
            }
            return (
                <div className="m-5">
                    <div className="flex justify-between items-center" >
                        <div className="flex flex-row justify-start">
                            <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                                <label htmlFor="fecha inicio" className="mb-2 font-bold">
                                    FECHA INICIO
                                </label>
                                <DatePicker
                                    defaultValue={fechaInicio}
                                    value={fechaInicio}
                                    valueFormat={{ dateStyle: "medium" }}
                                    onChange={(date) => setFechaInicio(date)}
                                />
                            </div>
                            <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                                <label htmlFor="fecha fin" className="mb-2 font-bold">
                                    FECHA FIN
                                </label>
                                <DatePicker
                                    defaultValue={fechaFin}
                                    value={fechaFin}
                                    valueFormat={{ dateStyle: "medium" }}
                                    onChange={(date) => setFechaFin(date)}
                                />
                            </div>
                            <div className="w-full sm:w-auto sm:flex-shrink-0 sm:mr-4 mb-4 sm:mb-0">
                                <div className="relative mt-5">
                                    <input
                                        type="text"
                                        placeholder="Filtrar por valor..."
                                        value={filterText}
                                        onChange={handleFilterChange}
                                        onKeyUp={() => handleFilterDebounced(filterText)}
                                        className="px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                                    />
                                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600">
                                        <Search />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-auto sm:flex-shrink-0">
                            <button className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleBuscar}
                            >
                                BUSCAR
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between">
                        <div>
                            <label htmlFor="filtros" className="mb-1 mt-2 font-bold">
                            </label>
                        </div>
                        <div onClick={toggleMostrarDivFiltro}>
                            {mostrarDivFiltro ? <ChevronDown /> : <ChevronUp />}
                        </div>
                    </div>
                    <div style={{ display: mostrarDivFiltro ? 'block' : 'none' }}>
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                                <label htmlFor="geocercaorigen" className="mb-2 font-bold">
                                    PLANTA/GEOCERCA ORIGEN
                                </label>
                                <Select options={listaGeocercaOrigen} isSearchable='true'
                                    isClearable='true'
                                    value={geocercaOrigenSelected}
                                    onChange={(event) => handleGeocercaOrigen(event)}
                                    placeholder='SELECCIONE GEOCERCA'
                                />
                            </div>
                            <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                                <label htmlFor="geocercadestino" className="mb-2 font-bold">
                                    PLANTA/GEOCERCA DESTINO
                                </label>
                                <Select options={listaGeocercaDestino} isSearchable='true'
                                    isClearable='true'
                                    value={geocercaDestinoSelected}
                                    onChange={(event) => handleGeocercaDestino(event)}
                                    placeholder='SELECCIONE GEOCERCA'
                                />
                            </div>
                            <div className="w-full md:w-1/4 mt-2 md:mb-0 mr-4">
                                <label htmlFor="unidad" className="mb-2 font-bold">
                                    UNIDAD
                                </label>
                                <Select options={listaUnidades} isSearchable='true'
                                    isClearable='true'
                                    value={unidadSelected}
                                    onChange={(event) => handleUnidad(event)}
                                    placeholder='SELECCIONE UNIDAD'
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {listaSeguimientoFiltrado.map((data, index) => <CardSeguimiento
                            key={index}
                            onButtonDetalles={() => onButtonDetalles(data)}
                            seguimiento={data}
                        />)}
                    </div>
                    <ModalInfoUnidadSeguimiento
                        isOpen={modalVerDetallesSeguimientoVisible}
                        onAccept={hideModalVerDetallesSeguimientoVisible}
                        data={seguimientoSelected}
                    />
                </div>
            );
        } catch (err) {
            console.log("LineaTiempo render");
            console.log(err);
            return (
                <div className=" w-1/2 m-auto px-[2rem] ">
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    };

    return render();
}